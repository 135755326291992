import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useHelpCenter } from '../../shared/HelpCenterContext';

const DialogModal = ({ title, helpTextArray }) => {
  useEffect(() => {});
  const { updateHelpDefaultActive } = useHelpCenter();
  return (
    <>
      <NavDropdown
        title=""
        id="nav-dropdown"
        className="d-flex align-items-center icon-info dialog-modal"
      >
        <NavDropdown.Item className="dropdown-item information">
          <h4 className="mb-2 mt-1">{title}</h4>
          <a href="#" className="close">
            <FontAwesomeIcon icon={faTimes} />
          </a>
          {helpTextArray &&
            helpTextArray?.length > 0 &&
            helpTextArray?.map((item) => (
              <p
                key={item.key}
                onClick={() => updateHelpDefaultActive([`${item.key - 1}`])}
              >{`${item.key}. ${item.name}`}</p>
            ))}
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

export default DialogModal;
