import { Modal, Select, Table, Tabs } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import SidebarNav from '../../components/Header/SidebarNav';
import { API, defaultPaginationLimit, deleteCall, get } from '../../config';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import ImportLogDetail from './ImportLogDetail';
import { getImportLogsTableColumns } from './ImportLogsHelper';
import { toast } from 'react-toastify';

const { TabPane } = Tabs;

const tabsKey = {
  imported: 1,
  'not-imported': 2
};

const ImportLogs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const defaultSearch = query.get('search') ? query.get('search') : '';

  const [search, setSearch] = useState(defaultSearch);

  const selectedTab = query.get('tab');
  const defaultActiveKey = tabsKey[selectedTab];

  const [tabName, setTabName] = useState(selectedTab);

  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const [importLogsData, setImportLogsData] = useState([]);
  const [salesForceCount, setsalesForceCount] = useState(0);
  const [xeroCount, setxeroCount] = useState(0);
  const [importLogId, setImportLogId] = useState();
  const [importLogModal, setImportLogModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
  );
  const [notLimit, setNotLimit] = useState(
    query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
  );

  const getImportLogsData = useCallback(
    async (currentPageValue, limit) => {
      try {
        setIsLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          `${API.IMPORT_LOGS}?page=${
            currentPageValue ? currentPageValue : pagingData.current
          }&search=${search ? search : ''}&limit=${tempLimit}&status=1`
        );
        setIsLoading(false);
        const { total, current_page, per_page, to, from } = data.data.logs;
        setxeroCount(data.data.xeroCount);
        setsalesForceCount(data.data.salesForceCount);
        setImportLogsData(data.data.logs.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          to: to,
          from: from
        });
      } catch (e) {
        setImportLogsData([]);
        setIsLoading(false);
        setPagingData({ current: currentPage || 1 });
      }
    },
    [pagingData.current, search]
  );

  useEffect(() => {
    selectedTab === 'imported' && getImportLogsData();
  }, [getImportLogsData, selectedTab]);

  const onPageChange = (page, limit) => {
    setPagingData({ ...pagingData, current: page });
    navigate(`/import-logs?tab=imported&page=${page}&limit=${limit}`, {
      replace: true
    });
  };
  const handleDetails = (id) => {
    setImportLogId(id);
    setImportLogModal(true);
  };

  const toggleShowModal = () => {
    setImportLogModal(!importLogModal);
  };

  // Not Imported Logs

  const [isLoadingNotImported, setIsLoadingNotImported] = useState(false);
  const [pagingDataNotImported, setPagingDataNotImported] = useState({ current: currentPage || 1 });
  const [notImportLogsData, setNotImportLogsData] = useState([]);
  const [salesForceCountNotImported, setsalesForceCountNotImported] = useState(0);
  const [xeroCountNotImported, setxeroCountNotImported] = useState(0);

  const [notImportLogId, setNotImportLogId] = useState();
  const [notImportLogModal, setNotImportLogModal] = useState(false);

  const getNotImportLogsData = useCallback(
    async (currentPageValue, limit) => {
      try {
        setIsLoadingNotImported(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          `${API.IMPORT_LOGS}?page=${
            currentPageValue ? currentPageValue : pagingDataNotImported.current
          }&search=${search ? search : ''}&limit=${tempLimit}&status=0`
        );
        setIsLoadingNotImported(false);
        const { total, current_page, per_page, from, to } = data.data.logs;
        setxeroCountNotImported(data.data.xeroCount);
        setsalesForceCountNotImported(data.data.salesForceCount);
        setNotImportLogsData(data.data.logs.data);
        setPagingDataNotImported({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setNotImportLogsData([]);
        setIsLoadingNotImported(false);
        setPagingDataNotImported({ current: currentPage || 1 });
      }
    },
    [pagingDataNotImported.current, search]
  );

  useEffect(() => {
    selectedTab === 'not-imported' && getNotImportLogsData();
  }, [getNotImportLogsData, selectedTab]);

  const onPageChangeNotImported = (page, limit) => {
    setPagingDataNotImported({ ...pagingDataNotImported, current: page });
    navigate(`/import-logs?tab=not-imported&page=${page}&limit=${limit}`, {
      replace: true
    });
  };
  const handleDetailsNotImported = (id) => {
    setNotImportLogId(id);
    setNotImportLogModal(true);
  };

  const toggleShowModalNotImported = () => {
    setNotImportLogModal(!notImportLogModal);
  };

  // Handle Tab

  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    setTabName(tabName);
    if (tabName) {
      const queryParams = `tab=${tabName}`;
      navigate(`/import-logs?${queryParams}&search=${search}`, {
        replace: true
      });
      getImportLogsData(1, defaultPaginationLimit);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    tabName === 'imported' && setPagingData({ ...pagingData, current: 1 });
    tabName === 'not-imported' &&
      setPagingDataNotImported({ ...pagingDataNotImported, current: 1 });
    navigate(`/import-logs?tab=${tabName}&search=${value}`, {
      replace: true
    });
  };

  const handleDelete = () => {
    getNotImportLogsData();
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const handleNotImportedDelete = async () => {
    try {
      const { data } = await deleteCall(`${API.IMPORT_LOGS}/delete-all`);
      toast.success(data.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      const { data } = e;
      toggleDeleteModal();
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  };

  const handleImportChange = async (value) => {
    setLimit(value);
    navigate(`/import-logs?tab=imported&page=1&limit=${value}`, {
      replace: true
    });
    getImportLogsData(1, value);
  };

  const handleNotImportChange = async (value) => {
    setNotLimit(value);
    navigate(`/import-logs?tab=not-imported&page=1&limit=${value}`, {
      replace: true
    });
    getNotImportLogsData(1, value);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Import Logs{' '}
          </h1>
          <div className="content-details">
            <div className="search-section">
              <Form>
                <FormControl
                  onChange={(e) => debounce(handleSearchChange(e), 300)}
                  type="text"
                  value={search}
                  placeholder="Search Import Logs"
                />
                <Button className="fa fa-search">Search</Button>
              </Form>
            </div>

            <Tabs
              defaultActiveKey={defaultActiveKey.toString()}
              size="large"
              style={{ marginBottom: 32 }}
              onTabClick={onTabClick}
            >
              <TabPane tab="Imported" key="1">
                <h5 className="right-alignment">
                  <p>Salesforce: {salesForceCount}</p>
                  <p>Xero: {xeroCount} </p>
                </h5>
                <div className="customer-table">
                  <Table
                    rowKey={(record) => record.id}
                    columns={getImportLogsTableColumns(handleDetails, selectedTab)}
                    dataSource={importLogsData || []}
                    loading={isLoading}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      onChange: (page) => onPageChange(page, limit),
                      pageSize: limit,
                      itemRender: PaginationItemRenderer,
                      ...pagingData
                    }}
                  />
                  {importLogsData?.length > 0 && (
                    <div className="col-md-12">
                      Showing&nbsp;
                      <Select
                        defaultValue={`${defaultPaginationLimit}`}
                        value={
                          query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
                        }
                        onChange={handleImportChange}
                        options={[
                          { value: '10', label: '10' },
                          { value: '25', label: '25' },
                          { value: '50', label: '50' },
                          { value: '100', label: '100' }
                        ]}
                      />
                      &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                      {pagingData?.to} from {pagingData?.total} Records
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane tab="Not Imported" key="2">
                <div className="header-not-imported">
                  <Button onClick={toggleDeleteModal}>Delete Permanently</Button>
                  <h5 className="right-alignment">
                    <p>Salesforce: {salesForceCountNotImported}</p>
                    <p>Xero: {xeroCountNotImported} </p>
                  </h5>
                </div>
                <div className="customer-table">
                  <Table
                    rowKey={(record) => record.id}
                    columns={getImportLogsTableColumns(
                      handleDetailsNotImported,
                      selectedTab,
                      handleDelete,
                      true
                    )}
                    dataSource={notImportLogsData || []}
                    loading={isLoadingNotImported}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      onChange: (page) => {
                        onPageChangeNotImported(page, notLimit);
                      },
                      pageSize: notLimit,
                      itemRender: PaginationItemRenderer,
                      ...pagingDataNotImported
                    }}
                  />
                  {notImportLogsData?.length > 0 && (
                    <div className="col-md-12">
                      Showing&nbsp;
                      <Select
                        defaultValue={`${defaultPaginationLimit}`}
                        value={
                          query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`
                        }
                        onChange={handleNotImportChange}
                        options={[
                          { value: '10', label: '10' },
                          { value: '25', label: '25' },
                          { value: '50', label: '50' },
                          { value: '100', label: '100' }
                        ]}
                      />
                      &nbsp;Records per page I Showing {pagingDataNotImported?.from} to&nbsp;
                      {pagingDataNotImported?.to} from {pagingDataNotImported?.total} Records
                    </div>
                  )}
                </div>
              </TabPane>
            </Tabs>
          </div>
          {importLogModal && (
            <ImportLogDetail toggleShowModal={toggleShowModal} importLogId={importLogId} />
          )}
          {notImportLogModal && (
            <ImportLogDetail
              toggleShowModal={toggleShowModalNotImported}
              importLogId={notImportLogId}
            />
          )}
          {showDelete && (
            <>
              <Modal
                className="remove-popup"
                width={500}
                closable
                centered
                open
                footer={null}
                onCancel={toggleDeleteModal}
              >
                <div className="remove-content">
                  <p>Are your sure you want to delete all not imported logs permanently?</p>
                  <div className="modal-action">
                    <Button className="cancel" onClick={toggleDeleteModal}>
                      Cancel
                    </Button>
                    <Button onClick={handleNotImportedDelete}>Confirm</Button>
                  </div>
                </div>
              </Modal>
            </>
          )}

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ImportLogs;
