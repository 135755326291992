/* eslint-disable no-nested-ternary */
import React, { useLayoutEffect, useState } from 'react';
import { Modal } from 'antd';
import { Button } from 'antd';
import { API, get } from '@src/config.js';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { imageDefaultPrefixPath } from '../../config';
import { printComponent } from '../../helper/print';
import { faPrint, faDownload } from '@fortawesome/free-solid-svg-icons';
import { download } from '../../helper/download';
import { formatNumber } from '../../APIConfig/Interceptor';
import { maskPostalCode } from '../../helper/fullNameGetter';

const AbaTransactionPopup = ({ preview, showPreview, setShowPreview }) => {
  const [previewDetail, setPreviewDetail] = useState(null);

  useLayoutEffect(() => {
    async function fetchData() {
      let previewData = await get(`${API.RECEIPT_PREVIEW}/${preview.transaction_id}`);
      setPreviewDetail(previewData.data.data);
    }
    fetchData();
  }, []);

  function printLetter() {
    return printComponent('print-authority', 'receipt');
  }

  const downloadLetter = async () => {
    try {
      if (preview?.receipt_path) {
        download(`${preview.receipt_path}`, 'receipt.pdf');
      } else {
        toast.error('File is not exist');
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  function getSubject() {
    return {
      __html: previewDetail?.firmDetails?.receipt_subject
        ? previewDetail?.firmDetails?.receipt_subject
        : ``
    };
  }

  function createMarkup() {
    return { __html: previewDetail?.firmDetails?.receipt_template };
  }

  return (
    <div>
      <Modal
        title=""
        centered
        open={showPreview}
        footer={null}
        width={885}
        closable
        className="modal-body"
        onCancel={closePreview}
      >
        <div className="authority-letter" id="print-authority">
          {previewDetail ? (
            <table width="850" cellSpacing="0" cellPadding="0" className="firm_details">
              <tr>
                <td style={{ padding: '15px' }}>
                  <table
                    cellSpacing="0"
                    cellPadding="0"
                    style={{
                      width: '100%'
                    }}
                  >
                    <tr>
                      <td>
                        {previewDetail?.firmDetails?.firm_logo && (
                          <img
                            src={`${imageDefaultPrefixPath}${previewDetail.firmDetails.firm_logo}`}
                            alt="logo"
                            style={{ height: '70px', marginRight: '15px', float: 'left' }}
                          />
                        )}
                        {previewDetail?.firmDetails?.firm_logo ? (
                          <></>
                        ) : (
                          <h2
                            style={{
                              color: 'rgb(0, 91, 152)',
                              fontSize: '21px',
                              textTransform: 'uppercase',
                              position: 'relative',
                              fontWeight: '600',
                              margin: '13px 0 0px',
                              fontFamily: 'Poppins'
                            }}
                          >
                            {previewDetail?.firmDetails?.firm_logo
                              ? ''
                              : previewDetail?.firmDetails?.business_name
                              ? previewDetail.firmDetails.business_name
                              : ''}
                            {previewDetail?.firmDetails?.firm_logo ? <></> : <br />}
                            <span
                              style={{
                                margin: '0px 0px 30px',
                                width: '100%',
                                fontFamily: 'Poppins',
                                textTransform: 'none',
                                fontSize: '14px',
                                color: 'rgb(51, 51, 51)',
                                display: 'block'
                              }}
                            >
                              {previewDetail?.firmDetails?.firm_email ? (
                                <a
                                  className="mailto-tel"
                                  href={`mailto:${previewDetail?.firmDetails?.firm_email}`}
                                  style={{ fontFamily: 'Poppins' }}
                                >
                                  {previewDetail?.firmDetails?.firm_email}
                                </a>
                              ) : (
                                ''
                              )}
                              <br />
                              {previewDetail?.firmDetails?.phone ? (
                                <a
                                  className="mailto-tel"
                                  href={`tel:${previewDetail?.firmDetails?.phone}`}
                                  style={{ fontFamily: 'Poppins' }}
                                >
                                  {previewDetail?.firmDetails?.phone}
                                </a>
                              ) : (
                                ''
                              )}
                              <br />
                              {previewDetail?.firmDetails?.street
                                ? previewDetail.firmDetails.street
                                : ''}
                              {previewDetail?.firmDetails?.suburb
                                ? ' ' + previewDetail.firmDetails.suburb
                                : ''}
                              {previewDetail?.firmDetails?.state
                                ? ' ' + previewDetail.firmDetails.state
                                : ''}
                              {previewDetail?.firmDetails?.postal_code &&
                              previewDetail?.firmDetails?.postal_code != '0000' &&
                              previewDetail?.firmDetails?.postal_code != 0
                                ? ' ' +
                                  maskPostalCode(Number(previewDetail.firmDetails.postal_code))
                                : ''}
                            </span>
                          </h2>
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: 'right',
                          color: 'rgb(0, 0, 0)',
                          lineHeight: '1.25',
                          fontFamily: 'Poppins',
                          float: 'right',
                          width: '200px'
                        }}
                      >
                        {previewDetail?.firmDetails?.accounting_body != 'Other' ? (
                          <>
                            <img
                              src={`${imageDefaultPrefixPath}${previewDetail?.firmDetails?.accounting_body_logo}`}
                              alt="logo"
                              style={{ width: 'auto', height: '40px', marginBottom: '0px' }}
                            />
                            <p
                              style={{ fontSize: '12px', margin: '5px 0 0', fontFamily: 'Poppins' }}
                            >
                              {`${
                                previewDetail?.firmDetails?.business_name
                                  ? previewDetail.firmDetails.business_name
                                  : ''
                              } is ` +
                                (/^[aeiouAEIOU]/.test(previewDetail?.firmDetails?.accounting_body)
                                  ? 'an '
                                  : 'a ') +
                                previewDetail?.firmDetails?.accounting_body +
                                ` practice.`}
                            </p>
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  </table>
                  <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0">
                    <tr>
                      <td>
                        {previewDetail?.firmDetails?.firm_logo && (
                          <h2
                            style={{
                              color: 'rgb(0, 91, 152)',
                              fontSize: '21px',
                              textTransform: 'uppercase',
                              position: 'relative',
                              fontWeight: '600',
                              margin: '13px 0 0px',
                              fontFamily: 'Poppins'
                            }}
                          >
                            {previewDetail?.firmDetails?.firm_logo
                              ? ''
                              : previewDetail?.firmDetails?.business_name
                              ? previewDetail.firmDetails.business_name
                              : ''}
                            {previewDetail?.firmDetails?.firm_logo ? <></> : <br />}
                            <span
                              style={{
                                margin: '0px 0px 15px',
                                width: '100%',
                                fontFamily: 'Poppins',
                                textTransform: 'none',
                                fontSize: '14px',
                                color: 'rgb(51, 51, 51)',
                                display: 'block'
                              }}
                            >
                              {previewDetail?.firmDetails?.firm_email ? (
                                <a
                                  className="mailto-tel"
                                  href={`mailto:${previewDetail?.firmDetails?.firm_email}`}
                                  style={{ fontFamily: 'Poppins' }}
                                >
                                  {previewDetail?.firmDetails?.firm_email}
                                </a>
                              ) : (
                                ''
                              )}
                              <br />
                              {previewDetail?.firmDetails?.phone ? (
                                <a
                                  className="mailto-tel"
                                  href={`tel:${previewDetail?.firmDetails?.phone}`}
                                  style={{ fontFamily: 'Poppins' }}
                                >
                                  {previewDetail?.firmDetails?.phone}
                                </a>
                              ) : (
                                ''
                              )}
                              <br />
                              {previewDetail?.firmDetails?.street
                                ? previewDetail.firmDetails.street + ', '
                                : ''}
                              <br />
                              {previewDetail?.firmDetails?.suburb
                                ? previewDetail.firmDetails.suburb
                                : ''}
                              {previewDetail?.firmDetails?.state
                                ? ', ' + previewDetail.firmDetails.state
                                : ''}
                              {previewDetail?.firmDetails?.postal_code &&
                              previewDetail?.firmDetails?.postal_code != '0000' &&
                              previewDetail?.firmDetails?.postal_code != 0
                                ? ', ' +
                                  maskPostalCode(Number(previewDetail.firmDetails.postal_code))
                                : ''}
                            </span>
                          </h2>
                        )}
                      </td>
                    </tr>
                    {previewDetail?.customerDetails?.crn && (
                      <tr>
                        <td>
                          <p style={{ margin: '15px 0 0', fontFamily: 'Poppins' }}>
                            CRN:{' '}
                            {previewDetail?.customerDetails?.crn
                              ? previewDetail?.customerDetails?.crn
                              : ''}
                          </p>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td style={{ padding: '0px 0 0', fontFamily: 'Poppins' }}>
                        {' '}
                        {previewDetail?.customerDetails?.currentDate
                          ? previewDetail.customerDetails.currentDate
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: '10px 0 15px',
                          fontStyle: 'italic',
                          fontFamily: 'Poppins'
                        }}
                      >
                        {' '}
                        Receipt Number:{' '}
                        <span style={{ fontFamily: 'Poppins' }} id="print-receipt-number">
                          {previewDetail?.customerDetails?.receipt_number
                            ? previewDetail?.customerDetails?.receipt_number
                            : preview.transaction_id}
                        </span>
                      </td>
                    </tr>
                  </table>
                  <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0">
                    <tr>
                      <td style={{ fontFamily: 'Poppins' }}>
                        <span style={{ fontFamily: 'Poppins' }} id="print-receipt-file-name">
                          {previewDetail?.customerDetails?.fullName
                            ? previewDetail.customerDetails.fullName
                            : ''}
                        </span>
                        <br />
                        {previewDetail?.customerDetails?.street
                          ? previewDetail.customerDetails.street + ', '
                          : ''}
                        <br />
                        {previewDetail?.customerDetails?.suburb
                          ? previewDetail.customerDetails.suburb + ''
                          : ''}
                        {previewDetail?.customerDetails?.state_short_name
                          ? ', ' + previewDetail.customerDetails.state_short_name + ''
                          : ''}
                        {previewDetail?.customerDetails?.postal_code &&
                        previewDetail?.customerDetails?.postal_code != '0000' &&
                        previewDetail?.customerDetails?.postal_code != 0
                          ? ', ' + maskPostalCode(Number(previewDetail.customerDetails.postal_code))
                          : ''}
                      </td>
                    </tr>
                    {previewDetail?.customerDetails?.firstName && (
                      <tr>
                        <td style={{ padding: '15px 0 0', fontFamily: 'Poppins' }}>
                          {' '}
                          Dear{' '}
                          {previewDetail?.customerDetails?.firstName
                            ? previewDetail.customerDetails.firstName
                            : ''}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <p style={{ fontFamily: 'Poppins', margin: '20px 0' }}>
                          <b style={{ fontFamily: 'Poppins' }}>
                            Re:{' '}
                            {previewDetail?.firmDetails?.receipt_subject ? (
                              <span
                                dangerouslySetInnerHTML={getSubject()}
                                style={{ fontFamily: 'Poppins' }}
                              />
                            ) : (
                              <span style={{ fontFamily: 'Poppins' }}>
                                Income Tax Refund for the year{' '}
                                {previewDetail?.customerDetails?.year
                                  ? previewDetail?.customerDetails?.year
                                  : ''}
                              </span>
                            )}
                          </b>
                        </p>
                        <p
                          dangerouslySetInnerHTML={createMarkup()}
                          style={{ fontFamily: 'Poppins', margin: '0 0 15px' }}
                        />
                        <p style={{ fontFamily: 'Poppins', margin: '0 0 10px' }}>
                          Details of the EFT transaction are as follows:
                        </p>
                      </td>
                    </tr>
                  </table>{' '}
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ width: '100%', margin: '0 auto' }}
                  >
                    <tr>
                      <td style={{ fontFamily: 'Poppins' }} colSpan="2">
                        {previewDetail?.customerDetails?.accountName
                          ? 'Bank Account Name deposited into: ' +
                            previewDetail.customerDetails.accountName
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontFamily: 'Poppins' }} colSpan="2">
                        BSB:{' '}
                        {previewDetail?.customerDetails?.bsb
                          ? previewDetail.customerDetails.bsb?.toString()
                          : ''}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {previewDetail?.customerDetails?.bankNumber
                          ? 'Account Number: ' +
                            previewDetail?.customerDetails?.bankNumber?.toString()
                          : ''}
                      </td>
                    </tr>
                  </table>
                  <table
                    style={{ width: '100%', margin: '0 auto' }}
                    cellPadding="0"
                    cellSpacing="0"
                  >
                    <tr>
                      <td style={{ fontFamily: 'Poppins' }}>&nbsp;</td>
                      <td style={{ fontFamily: 'Poppins', textAlign: 'right' }}>&nbsp;</td>
                      <td style={{ fontFamily: 'Poppins' }} width="55%">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontFamily: 'Poppins' }}>ATO Refund</td>
                      <td style={{ fontFamily: 'Poppins', textAlign: 'right' }}>
                        $
                        {previewDetail?.customerDetails?.atoRefund
                          ? formatNumber(previewDetail.customerDetails.atoRefund)
                          : '0.00'}
                      </td>
                      <td style={{ fontFamily: 'Poppins' }}>&nbsp;</td>
                    </tr>
                    {previewDetail?.customerDetails?.serviceFee ? (
                      <tr>
                        <td style={{ fontFamily: 'Poppins' }}>Professional Fee</td>
                        <td style={{ fontFamily: 'Poppins', textAlign: 'right' }}>
                          $
                          {previewDetail?.customerDetails?.serviceFee
                            ? formatNumber(previewDetail.customerDetails.serviceFee)
                            : '0.00'}
                        </td>
                        <td style={{ fontFamily: 'Poppins' }}>&nbsp;</td>
                      </tr>
                    ) : (
                      <></>
                    )}

                    {previewDetail?.customerDetails?.retainTrustAmount ? (
                      <tr>
                        <td style={{ fontFamily: 'Poppins' }}>Disbursement</td>
                        <td style={{ fontFamily: 'Poppins', textAlign: 'right' }}>
                          $
                          {previewDetail?.customerDetails?.retainTrustAmount
                            ? formatNumber(previewDetail.customerDetails.retainTrustAmount)
                            : '0.00'}
                        </td>
                        <td style={{ fontFamily: 'Poppins' }}>&nbsp;</td>
                      </tr>
                    ) : (
                      <></>
                    )}

                    <tr>
                      <td
                        style={{
                          fontFamily: 'Poppins',
                          borderTop: 'transparent 1px solid',
                          borderBottom: 'transparent 1px solid'
                        }}
                      >
                        Balance of this Payment
                      </td>
                      <td
                        style={{
                          fontFamily: 'Poppins',
                          textAlign: 'right',
                          borderTop: '#333 1px solid',
                          borderBottom: '#333 1px solid'
                        }}
                      >
                        $
                        {previewDetail?.customerDetails?.balance
                          ? formatNumber(previewDetail.customerDetails.balance)
                          : '0.00'}
                      </td>
                      <td style={{ fontFamily: 'Poppins' }}>&nbsp;</td>
                    </tr>
                  </table>{' '}
                  <br />
                  <br />
                  <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0">
                    <tr>
                      <td>
                        <p style={{ fontFamily: 'Poppins', margin: '0 0 45px' }}>
                          The transaction was processed from our Trust Account{' '}
                          {previewDetail?.firmDetails?.account_name
                            ? previewDetail.firmDetails.account_name
                            : ''}
                          , BSB:{' '}
                          {previewDetail?.firmDetails?.bsb
                            ? previewDetail.firmDetails.bsb?.toString()
                            : ''}{' '}
                          Account Number:{' '}
                          {previewDetail?.firmDetails?.account_number
                            ? previewDetail?.firmDetails?.account_number?.toString()
                            : ''}
                          . Should you have any queries, please do not hesitate to contact our
                          office.
                        </p>
                        <p style={{ fontFamily: 'Poppins', margin: '0 0 5px' }}>Yours sincerely,</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ fontFamily: 'Poppins', margin: '0px' }}>
                          {/* eslint-disable */}
                          {previewDetail?.created_by
                            ? previewDetail?.created_by
                            : localStorage.getItem('FullName')
                            ? localStorage.getItem('FullName')
                            : ''}
                        </p>
                        <p style={{ fontFamily: 'Poppins' }}>
                          {previewDetail?.firmDetails?.business_name
                            ? previewDetail.firmDetails.business_name
                            : ''}
                        </p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          ) : (
            <></>
          )}
        </div>
        <div
          className="print-btn-div"
          style={{ padding: '0 0 20px', display: 'block', textAlign: 'center' }}
        >
          <Button onClick={() => printLetter()} type="primary" className="btn btn-primary printbtn">
            <FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
            Print
          </Button>
          <Button onClick={downloadLetter} type="primary" className="btn btn-primary printbtn">
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />
            Download
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AbaTransactionPopup;
