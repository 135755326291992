import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Spin, Select } from 'antd';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, defaultPaginationLimit, fileUpload, get } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import { clientRequiredUpdateColumns } from './reportHelper';
import { toast } from 'react-toastify';
// import faqicon from '../../assets/images/bulb-icon.jpg';
import { Button, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import UploadFile from '../../components/UploadFile';
import { useHelpCenter } from '../../shared/HelpCenterContext';
import DialogModal from '../../components/Header/DialogModal';

const ClientRequiredUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getHelpCenterObjectByType } = useHelpCenter();
  const query = new URLSearchParams(location.search);
  const [clientRequiredData, setClientRequiredUpdateData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [showTFNImport, setShowTFNImport] = useState(false);
  const [tfnFiles, setTFNFiles] = useState([]);
  const [isTFNImportLoading, setIsTFNImportLoading] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const getClientRequiredUpdateData = useCallback(
    async (limit) => {
      try {
        setIsLoading(true);
        let tempLimit = defaultPaginationLimit;
        if (limit) {
          tempLimit = limit;
        } else {
          tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
        }
        const { data } = await get(
          `${API.CLIENT_REQUIRED_UPDATE}?page=${pagingData.current}&limit=${tempLimit}`
        );
        setIsLoading(false);
        const { total, current_page, per_page, from, to } = data.data;
        setClientRequiredUpdateData(data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        const { data } = e.response;
        if (data) {
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        } else {
          toast.error('Something went wrong');
        }
        setClientRequiredUpdateData({});
        setIsLoading(false);
        setPagingData({ current: currentPage || 1 });
      }
    },
    [pagingData.current]
  );

  useEffect(() => {
    getClientRequiredUpdateData();
  }, [getClientRequiredUpdateData]);

  const onPageChange = (page) => {
    setPagingData({ ...pagingData, current: page });
    navigate(`/client-required-update?page=${page}&limit=${limit}`, {
      replace: true
    });
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/client-required-update?page=1&limit=${value}`, {
      replace: true
    });
    getClientRequiredUpdateData(value);
  };

  const handleTFNUpload = async () => {
    if (tfnFiles && tfnFiles.length) {
      setIsTFNImportLoading(true);
      const formData = new FormData();
      tfnFiles.forEach((file) => {
        if (file !== undefined) {
          formData.append('tfn_file', file.originFileObj);
        }
      });
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      fileUpload(API.IMPORT_TFN_CUSTOMER, formData, config)
        .then((response) => {
          if (response.status === 200) {
            setIsTFNImportLoading(false);
            toast.success(response.data.message);
            if (response.data?.data?.file) {
              const export_report_path = response.data?.data?.file;
              if (export_report_path) {
                var a = document.createElement('a');
                a.href = export_report_path;
                var file = export_report_path.split('/');
                a.setAttribute('download', file[file.length - 1] || 'error-tfn-update.xls');
                a.setAttribute('target', '_blank');
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setIsLoadingDownload(false);
              }
            }
            getClientRequiredUpdateData();
            handleTFNImportClose();
          }
        })
        .catch((err) => {
          const { data } = err.response;
          setIsTFNImportLoading(false);
          toast.error(data?.message);
          if (err?.response?.data?.errors?.data?.file) {
            const export_report_path = err?.response?.data?.errors?.data?.file;
            if (export_report_path) {
              var a = document.createElement('a');
              a.href = export_report_path;
              var file = export_report_path.split('/');
              a.setAttribute('download', file[file.length - 1] || 'error-tfn-update.xls');
              a.setAttribute('target', '_blank');
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();
              setIsLoadingDownload(false);
            }
          }
          const errors = err?.response?.data?.errors;
          Object.keys(errors).forEach((key) => {
            toast.error(errors[key][0]);
          });
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          var error = {};
          error['myna_error'] = 'There is some issue with request, please try after some time.';
        });
    }
  };

  const handleTFNImportClose = () => {
    setShowTFNImport(false);
    setIsTFNImportLoading(false);
    setTFNFiles([]);
  };

  const handleTFNFileChange = (info) => {
    setTFNFiles(info.fileList);
  };

  const handleTFNImportModal = async () => {
    setShowTFNImport(!showTFNImport);
  };

  const handleTFNDownload = () => {
    setIsLoadingDownload(true);
    get(API.DOWNLOAD_TFN_CUSTOMER)
      .then((result) => {
        if (result.status === 200) {
          const { data } = result.data;
          var a = document.createElement('a');
          a.href = data.file;
          a.download = 'customer.xls';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
        }
        setIsLoadingDownload(false);
      })
      .catch((error) => {
        const { data } = error.response;
        setIsLoadingDownload(false);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };

  let helpTextArray = getHelpCenterObjectByType('Update Clients TFN');

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Update Client&apos;s TFN
            <DialogModal title="Update Clients TFN" helpTextArray={helpTextArray} />
          </h1>
          {isLoading ? (
            <Spin />
          ) : (
            <div className="content-details">
              <div className="table-top-btn">
                <div className="table-btn">
                  <div className="border-btn">
                    <DropdownButton className="info-icon">
                      <Dropdown.Item>
                        <p>CRN - Required</p>
                        <p>TFN - Required</p>
                      </Dropdown.Item>
                    </DropdownButton>
                    <Button
                      variant="link"
                      disabled={isLoadingDownload}
                      onClick={() => handleTFNDownload()}
                      className="download-template"
                    >
                      Download Template
                    </Button>
                    <Button variant="primary" onClick={handleTFNImportModal}>
                      TFN Update
                    </Button>
                  </div>
                </div>
              </div>
              <Table
                columns={clientRequiredUpdateColumns(navigate, location)}
                dataSource={clientRequiredData?.data || []}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: pagingData.pageSize,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page),
                  itemRender: PaginationItemRenderer,
                  ...pagingData,
                  pageSize: limit
                }}
              />
              {clientRequiredData?.data?.length > 0 && (
                <div className="mt-2">
                  <div className="col-md-12">
                    Showing&nbsp;
                    <Select
                      defaultValue={`${defaultPaginationLimit}`}
                      value={limit}
                      onChange={handleChange}
                      options={[
                        { value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }
                      ]}
                    />
                    &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                    {pagingData?.to} from {pagingData?.total} Records
                  </div>
                </div>
              )}
              <Modal
                show={showTFNImport}
                onHide={handleTFNImportClose}
                dialogClassName="modal-50w small-popup"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>Import TFN</Modal.Header>
                <Modal.Body>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'column'
                    }}
                  >
                    <UploadFile
                      isMultiple={true}
                      isLoading={isTFNImportLoading}
                      handleFileChange={handleTFNFileChange}
                      handleUpload={handleTFNUpload}
                      handleCancel={handleTFNImportClose}
                    />
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default ClientRequiredUpdate;
