import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Input } from 'antd';

import Form from '@component/Form';
import Sitelogo from '@component/Header/Logo.js';
import Footer from '@component/Footer/Index.js';
import './auth.module.css';
import { API, post, get } from '../../config';
import { SetCookie } from '../../APIConfig/Interceptor';
import { checkRole } from '../../helper/utils';
import abstractImage from "../../assets/images/abstract_image.jpeg";

const TwoFactor = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [twoFAMode, setTwoFAMode] = useState('google');
  const [message, setMessage] = useState({ success: '', error: '' });

  const text = useMemo(
    () =>
      twoFAMode === 'google'
        ? 'Enter the 6-digit code found in your authenticator app'
        : 'We have send a token email Enter the 6-digit code found in your email',
    [twoFAMode]
  );

  const handleFinish = async (values) => {
    try {
      setMessage({});
      const email = localStorage.getItem('myna-auth-email');
      const apiURL =
        twoFAMode === 'google' ? API.GOOGLE_2FA_VERIFY : API.VERIFY_EMAIL_AUTHENTICATION;
      const { data } = await post(apiURL, {
        email: email,
        ...values
      });
      if (data.status === 200) {
        setTwoFAMode(null);
        get(API.GET_USER_PROFILE)
          .then(async (response) => {
            const { first_name, profile_pic } = response.data;
            localStorage.setItem('Name', first_name);
            localStorage.setItem('ProfilePhoto', profile_pic);
            localStorage.setItem('myna-auth-tfa', 2);
            SetCookie('_subscription', 1);

              if (checkRole(localStorage.getItem('role_type'))) {
              window.location.href = '/admin/dashboard';
            } else {
              try {
                const { data } = await get(API.GET_SUBSCRIPTION);
                if (data) {
                  await SetCookie('_subscription', 1);
                }
              } catch (error) {
                SetCookie('_subscription', 0);
                window.location.href = '/settings?tab=subscription&childTab=more-packages';
                return;
              }
              window.location.href = '/dashboard?field=name&type=asc&filter=weekly';
            }
          })
          .catch(() => {
            var error = {};
            error['myna_error'] = 'There is some issue with request, please try after some time.';
          });
      } else {
        setMessage({ error: data.errors?.myna_error[0] });
      }
    } catch (e) {
      const { data } = e.response;
      if (data && data.errors) {
        setMessage({ error: data && data.errors['code'][0] });
      } else {
        setMessage({
          error: 'There is some issue with request, please try after some time.'
        });
      }
    }
  };

  const handle2FAModeChange = async () => {
    try {
      const email = localStorage.getItem('myna-auth-email');
      setMessage({});
      setTwoFAMode((prevState) => (prevState === 'google' ? 'email' : 'google'));
      if (twoFAMode === 'google') {
        const { data } = await post(API.EMAIL_AUTHENTICATION, {
          email: email
        });
        if (data.status === 200) {
          setMessage({ success: data.message });
        } else {
          setMessage({ error: data.errors });
        }
      }
    } catch (e) {
      setMessage({
        error: 'There is some issue with request, please try after some time.'
      });
    }
  };

  const handleBackToLogin = () => {
    localStorage.clear();
    navigate('/', { replace: true });
  };

  return (
    <div className="login-pages">
      <Row>
        <Col>
          <div className="login-img white-site">
            <Sitelogo />
          </div>
          <div
            className="footer-link"
            style={{ maxWidth: '100%', color: '#CCC', padding: '0 30px' }}
          >
            <p style={{ fontSize: '12px' }}>
              TrustEasy acknowledges the Traditional Custodians of country throughout Australia and
              their connections to land, sea and community. We pay our respect to their Elders past
              and present and extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
              <br />
            </p>
            <div className="abstract-image-body">
              <img src={abstractImage} alt="test" />
            </div>
          </div>
        </Col>
        <Col className="login-form-block trans-login" style={{ flex: '1 0' }}>
          <div className="login-form">
            {message.error && <p className="text-danger">{message.error}</p>}
            {message.success && <p className="text-success">{message.success}</p>}
            <Form form={form} name="2faForm" onFinish={handleFinish}>
              <div className="auth-text auth-image">{text}</div>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Code'
                  }
                ]}
              >
                <Input
                  autoFocus
                  placeholder="Authentication Code"
                  bordered={false}
                  type="password"
                />
              </Form.Item>
              <div className="form-group ant-form-item confirmButton">
                <Button type="primary" htmlType="submit" shape="round" size="large">
                  Confirm
                </Button>
              </div>
            </Form>
            <p>
              <Button type="button" onClick={handle2FAModeChange} className="linkButton">
                Use another authentication method
              </Button>
            </p>
            <p>
              <Button type="button" onClick={handleBackToLogin} className="linkButton">
                Back to Login
              </Button>
            </p>
            <Footer />
          </div>
          <div className="footer-link">
            <p style={{ fontSize: '12px', marginBottom: '2px' }}>
              Trouble Logging In?{' '}
              <a
                href="https://trusteasy.com.au/#contact"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Contact Us
              </a>
            </p>
            <p style={{ fontSize: '12px' }}>
              By selecting Login, you agree to our{' '}
              <a
                href="https://trusteasy.com.au/terms-conditions/"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Terms of Use
              </a>{' '}
              and have read and acknowledge our{' '}
              <a
                href="https://trusteasy.com.au/privacy-policy/"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TwoFactor;
