import React, { useEffect } from 'react';
import '../App.css';
import '../assets/css/style.css';
import Header from '../components/Header/Index';
import SidebarNav from '../components/Header/SidebarNav';
import { Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useHelpCenter } from '../shared/HelpCenterContext';
// import BankList from './BankList';
import { checkRole } from '../helper/utils';
import AdminHeader from '../components/AdminHeader/Index';
import BackButton from '../components/Form/BackButton';

const HelpCenter = () => {
  // render() {
  const { helpDefaultActive, updateHelpDefaultActive, helpCenterArray } = useHelpCenter();

  const roleType = localStorage.getItem('role_type');

  function callback(key) {
    updateHelpDefaultActive(key);
  }

  useEffect(() => {
    document.getElementById(helpDefaultActive?.[0])?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className="cms-page faq-page">
      <div className="page-content-block">
        {checkRole(roleType) ? (
          <AdminHeader />
        ) : (
          <>
            <Header />
            <SidebarNav />
          </>
        )}
      </div>
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Help Center
          </h1>
          <div className="content-details">
            <div className="table-top-btn" style={{ margin: '0 0 15px' }}>
              <h2>
                FAQ<span>s</span>
              </h2>
              <div className="pdf-btn">
                <a
                  className="btn"
                  href="https://trusteasy.com.au/wp-content/uploads/2022/05/tpb_i_15_2012_code_of_professional_conduct_holding_money_or_other_property_on_trust.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFilePdf} /> TPB Information Sheet
                </a>
                <a
                  className="btn"
                  href="https://trusteasy.com.au/wp-content/uploads/2022/05/APESB_Info_Sheet_Trust_Accounts_May_2018.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFilePdf} /> APESB Information Sheet
                </a>
              </div>
            </div>
            <Collapse defaultActiveKey={helpDefaultActive} onChange={callback}>
              {helpCenterArray.map((panel, index) => (
                <Collapse.Panel
                  style={{
                    'white-space': 'pre-wrap',
                    background: '#EEE',
                    fontSize: '16px'
                  }}
                  // showArrow={false}
                  // defaultActiveKey={0}
                  header={`${panel?.key}. ${panel?.name}`}
                  key={index}
                  id={panel?.key}
                >
                  <div className="help-text-inner-body">{panel.text}</div>
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
  // }
};

export default HelpCenter;
