/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { imageDefaultPrefixPath } from '../../config';
import moment from 'moment';
import { maskPostalCode } from '../../helper/fullNameGetter';

const ManualReceiptModal = ({ showPreview, setShowPreview, recieptData }) => {
  const [previewDetail, setPreviewDetail] = useState(null);

  const date = new Date();
  const day = moment(date).format('LL');

  function createMarkup() {
    return {
      __html: previewDetail?.manual_receipt_template
        ? previewDetail?.manual_receipt_template
        : `Please be advised that in accordance with your earlier instructions, the following money has been received by the firm and has been deposited into our Trust Accounts where it will be held in trust for you. Details of the transaction are as follows:`
    };
  }

  function getSubject() {
    return {
      __html: previewDetail?.manual_receipt_subject
        ? previewDetail?.manual_receipt_subject
        : `Receipt of Monies into Trust Account`
    };
  }

  const closePreview = () => {
    setShowPreview(false);
  };
  useEffect(() => {
    setPreviewDetail(recieptData);
  });

  return (
    <div>
      <Modal
        title=""
        centered
        open={showPreview}
        footer={null}
        width={885}
        closable
        className="modal-body"
        onCancel={closePreview}
      >
        <div className="authority-letter" id="print-authority">
          {previewDetail ? (
            <table width="850" cellSpacing="0" cellPadding="0" className="firm_details">
              <tr>
                <td style={{ padding: '15px' }}>
                  <table
                    cellSpacing="0"
                    cellPadding="0"
                    style={{
                      width: '100%'
                    }}
                  >
                    <tr>
                      <td>
                        {previewDetail?.logo && (
                          <img
                            src={`${imageDefaultPrefixPath}${previewDetail.logo}`}
                            alt="logo"
                            style={{ height: '80px', marginRight: '15px', float: 'left' }}
                          />
                        )}
                        {previewDetail?.logo ? (
                          <></>
                        ) : (
                          <h2
                            style={{
                              color: 'rgb(0, 91, 152)',
                              fontSize: '21px',
                              textTransform: 'uppercase',
                              position: 'relative',
                              fontWeight: '600',
                              margin: '13px 0 0px',
                              fontFamily: 'Poppins'
                            }}
                          >
                            {previewDetail?.logo
                              ? ''
                              : previewDetail?.business_name
                              ? previewDetail.business_name
                              : ''}
                            {previewDetail?.logo ? <></> : <br />}
                            <span
                              style={{
                                margin: '0px 0px 30px',
                                width: '100%',
                                fontFamily: 'Poppins',
                                textTransform: 'none',
                                fontSize: '14px',
                                color: 'rgb(51, 51, 51)',
                                display: 'block'
                              }}
                            >
                              {previewDetail?.firm_email ? (
                                <a
                                  className="mailto-tel"
                                  href={`mailto:${previewDetail?.firm_email}`}
                                >
                                  {previewDetail?.firm_email}
                                </a>
                              ) : (
                                ''
                              )}
                              <br />
                              {previewDetail?.phone ? (
                                <a className="mailto-tel" href={`tel:${previewDetail?.phone}`}>
                                  {previewDetail?.phone}
                                </a>
                              ) : (
                                ''
                              )}
                              <br />
                              {previewDetail?.street ? previewDetail.street + ', ' : ''}
                              <br/>
                              {previewDetail?.firm_suburb ? previewDetail.firm_suburb : ''}
                              {previewDetail?.firm_state_short_name
                                ? ', ' + previewDetail.firm_state_short_name
                                : ''}
                              {previewDetail?.firm_postal_code
                                ? ', ' + maskPostalCode(Number(previewDetail.firm_postal_code))
                                : ''}
                            </span>
                          </h2>
                        )}
                      </td>
                      <td
                        style={{
                          textAlign: 'right',
                          color: 'rgb(0, 0, 0)',
                          lineHeight: '1.25',
                          fontFamily: 'Poppins',
                          float: 'right',
                          width: '200px'
                        }}
                      >
                        <img
                          src={`${imageDefaultPrefixPath}${previewDetail?.accounting_body_logo}`}
                          alt="logo"
                          style={{ width: 'auto', height: '40px', marginBottom: '0px' }}
                        />
                        <p style={{ fontSize: '12px', margin: '5px 0 0' }}>
                          {`${
                            previewDetail?.business_name ? previewDetail.business_name : ''
                          } is ` +
                            (/^[aeiouAEIOU]/.test(previewDetail?.accounting_body) ? 'an ' : 'a ') +
                            previewDetail?.accounting_body +
                            ` practice.`}
                        </p>
                      </td>
                    </tr>
                  </table>
                  <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0">
                    {previewDetail?.logo && (
                      <tr>
                        <td>
                          <h2
                            style={{
                              color: 'rgb(0, 91, 152)',
                              fontSize: '21px',
                              textTransform: 'uppercase',
                              position: 'relative',
                              fontWeight: '600',
                              margin: '13px 0 0px',
                              fontFamily: 'Poppins'
                            }}
                          >
                            {previewDetail?.logo
                              ? ''
                              : previewDetail?.business_name
                              ? previewDetail.business_name
                              : ''}
                            {previewDetail?.logo ? <></> : <br />}
                            <span
                              style={{
                                margin: '0px 0px 15px',
                                width: '100%',
                                fontFamily: 'Poppins',
                                textTransform: 'none',
                                fontSize: '14px',
                                color: 'rgb(51, 51, 51)',
                                display: 'block'
                              }}
                            >
                              {previewDetail?.firm_email ? (
                                <a
                                  className="mailto-tel"
                                  href={`mailto:${previewDetail?.firm_email}`}
                                >
                                  {previewDetail?.firm_email}
                                </a>
                              ) : (
                                ''
                              )}
                              <br />
                              {previewDetail?.phone ? (
                                <a className="mailto-tel" href={`tel:${previewDetail?.phone}`}>
                                  {previewDetail?.phone}
                                </a>
                              ) : (
                                ''
                              )}
                              <br />
                              {previewDetail?.street ? previewDetail.street + ', ' : ''}
                              <br />
                              {previewDetail?.firm_suburb ? previewDetail.firm_suburb : ''}
                              {previewDetail?.firm_state_short_name
                                ? ', ' + previewDetail.firm_state_short_name
                                : ''}
                              {previewDetail?.firm_postal_code
                                ? ', ' + maskPostalCode(Number(previewDetail.firm_postal_code))
                                : ''}
                            </span>
                          </h2>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <p style={{ margin: '15px 0 0' }}>&#123;&#123;CRN&#125;&#125;</p>
                      </td>
                    </tr>

                    <tr>
                      <td style={{ padding: '15px 0 0', fontFamily: 'Poppins' }}>{day}</td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ padding: '15px 0 15px', margin: ' 0 0' }}>
                          &#123;&#123;Receipt Number&#125;&#125;
                        </p>
                      </td>
                    </tr>
                  </table>
                  <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0">
                    <tr>
                      <td style={{ fontFamily: 'Poppins' }}>
                        <div>&#123;&#123;Client Name&#125;&#125;</div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '15px 0 0', fontFamily: 'Poppins' }}>
                        <div>&#123;&#123;Client Address&#125;&#125;</div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '15px 0 0', fontFamily: 'Poppins' }}>
                        {' '}
                        Dear &#123;&#123;Client First Name&#125;&#125;
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <p style={{ fontFamily: 'Poppins', margin: '20px 0' }}>
                            RE:{' '}
                            <span
                              dangerouslySetInnerHTML={getSubject()}
                              style={{ fontFamily: 'Poppins' }}
                            />
                          </p>
                        </b>
                        <div
                          dangerouslySetInnerHTML={createMarkup()}
                          style={{ fontFamily: 'Poppins' }}
                        />
                      </td>
                    </tr>
                  </table>{' '}
                  <br />
                  <br />
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ width: '100%', margin: '0 auto' }}
                  >
                    <tr>
                      <td>
                        <span style={{ fontFamily: 'Poppins', marginRight: '40px' }}>
                          Electronic Fund Transfer
                        </span>
                        <span style={{ fontFamily: 'Poppins' }}>$0.00</span>
                      </td>
                    </tr>
                    <tr>
                      {/* <td style={{ padding: '15px 0 0 0', fontFamily: 'Poppins' }} colSpan="2">
                        Please note that a Handling Fee of $0.00 has been deducted from the amount
                        received.
                      </td> */}
                    </tr>
                  </table>
                  <br />
                  <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0">
                    <tr>
                      <td>
                        <p>
                          The transaction was conducted in our Trust Account{' '}
                          {previewDetail?.firm_bank?.account_number
                            ? 'xxxx xxxx ' +
                              previewDetail?.firm_bank?.account_number?.toString().slice(-4)
                            : ''}
                          {', '}
                          account name{' '}
                          {previewDetail?.firm_bank?.account_name
                            ? previewDetail?.firm_bank?.account_name
                            : ''}
                          . Should you have any queries, please do not hesitate to contact our
                          office.
                        </p>
                        <br />
                        <p style={{ fontFamily: 'Poppins', margin: '0 0 15px' }}>
                          Yours sincerely,
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ fontFamily: 'Poppins', margin: '0px' }}>
                          {localStorage.getItem('FullName') ? localStorage.getItem('FullName') : ''}
                        </p>
                        <p>{previewDetail?.business_name ? previewDetail.business_name : ''}</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          ) : (
            <></>
          )}
        </div>
        <div
          className="print-btn-div"
          style={{ padding: '0 0 20px', display: 'block', textAlign: 'center' }}
        ></div>
      </Modal>
    </div>
  );
};

export default ManualReceiptModal;
