/* eslint-disable react/no-unescaped-entities */
/* eslint-disable eqeqeq */
import React, { createContext, useContext, useState } from 'react';
import BankList from '../pages/BankList';
import { useNavigate } from 'react-router-dom';
import KanBanViewImg from '../assets/images/FAQ_Images/Q2 KanBan view_1.jpg';
import FirmSettingsImg from '../assets/images/FAQ_Images/Q3 FirmSettings_1.jpg';
import WithdrawalImg from '../assets/images/FAQ_Images/Q4 WithdrawalSettings_1.jpg';
import IntegrationImg_1 from '../assets/images/FAQ_Images/Q5 Integration-Import_1.jpg';
import IntegrationImg_2 from '../assets/images/FAQ_Images/Q5 Integration-Import_2.jpg';
import OfficeImg from '../assets/images/FAQ_Images/Q6 Office_1.jpg';
import ChartsOfAccountImg from '../assets/images/FAQ_Images/Q7 Charts_Of_account_1.jpg';
import SupplierImg from '../assets/images/FAQ_Images/Q8 Suppliers_1.jpg';
import TrustPaymentImg from '../assets/images/FAQ_Images/Q9 TrustPayment_1.jpg';
import TrustReceiptImg from '../assets/images/FAQ_Images/Q9 TrustReceipt_2.jpg';
import SubscriptionImg from '../assets/images/FAQ_Images/Q10 Subscription_1.jpg';
import AuthoriytLetterImg_1 from '../assets/images/FAQ_Images/Q11 AuthorityLetter_1.jpg';
import AuthoriytLetterImg_2 from '../assets/images/FAQ_Images/Q11 AuthorityLetter_2.jpg';
import UserConsoleImg from '../assets/images/FAQ_Images/Q12 User Console_1.jpg';
import ReassignTaskImg_1 from '../assets/images/FAQ_Images/Q13 ReassignTask_1.jpg';
import ReassignTaskImg_2 from '../assets/images/FAQ_Images/Q13 ReassignTask_2.jpg';
import UserActivityLogImg from '../assets/images/FAQ_Images/Q14 UserActivityLog_1.jpg';
import ClientImg from '../assets/images/FAQ_Images/Q15 Clients.csv_1.jpg';
import ClientInfoImg from '../assets/images/FAQ_Images/Q16 ClientInfo_1.jpg';
import ClientsHistoryImg from '../assets/images/FAQ_Images/Q17 ClientsHistory_1.jpg';
import ClientsInfoImg_1 from '../assets/images/FAQ_Images/Q18 ClientsInfo_1.jpg';
import KYCImg from '../assets/images/FAQ_Images/Q19 KYC_1.jpg';
import ArchivedClientsImg from '../assets/images/FAQ_Images/Q20 ArchivedClients_1.jpg';
import UpdateTFNImg from '../assets/images/FAQ_Images/Q21 Update TFNs_1.jpg';
import DuplicateEntriesImg from '../assets/images/FAQ_Images/Q22 DuplicateEntries_1_new.jpg';
import ATORefundImg_1 from '../assets/images/FAQ_Images/Q23 ATO_Refund_1.jpg';
import ProcessRefundInBulkImg from '../assets/images/FAQ_Images/Q25 ProcessRefundinBulk_1.jpg';
import ProcessRefundImg from '../assets/images/FAQ_Images/Q26 ProcessRefund_new.png';
import ABAFilesImg from '../assets/images/FAQ_Images/Q27 ABA Files_2.jpg';
import GenerateABAImg from '../assets/images/FAQ_Images/Q28 Generate.aba_1.jpg';
import SendAutomaticABAImg from '../assets/images/FAQ_Images/Q28 Send_Automatic.aba_2.jpg';
import ViewStatementImg_1 from '../assets/images/FAQ_Images/Q29 View_Statement_1.jpg';
import ViewStatementImg_2 from '../assets/images/FAQ_Images/Q29 View_Statement_2.jpg';
import ClientTransactionImg_2 from '../assets/images/FAQ_Images/Q30 Client_Transaction_1.jpg';
import TrialBalanceImg from '../assets/images/FAQ_Images/Q31 Trial_Balance_new.jpg';
import MonthlyReconciliationImg from '../assets/images/FAQ_Images/Q32 MonthlyReconciliation_new.jpg';
import WrongEntryImg_1 from '../assets/images/FAQ_Images/Q34 DeletetheWrongEntry_1.jpg';
import WrongEntryImg_2 from '../assets/images/FAQ_Images/Q34 DeleteWrongEntry_2.jpg';
import ReconcileItemImg from '../assets/images/FAQ_Images/Q35 Reconcil_Items_new.jpg';
import SendforReviewImg from '../assets/images/FAQ_Images/Q36 SendforReview_1.jpg';
import SendforReviewImg_1 from '../assets/images/FAQ_Images/Q37 SendforReview_1.jpg';
import SendforReviewImg_2 from '../assets/images/FAQ_Images/Q37 SendforReview_2.jpg';
import BankTransactionImg_1 from '../assets/images/FAQ_Images/Q38 Bank_Transaction_1.jpg';
import BankTransactionImg_2 from '../assets/images/FAQ_Images/Q38 Bank_Transaction_2.jpg';
import BankStatementCsvImg from '../assets/images/FAQ_Images/Q39 BankStatementviaCsv_1.jpg';
import ClientTrustAccountImg from '../assets/images/FAQ_Images/Q43 ClientTrustAccount_1.jpg';
import FirmOpeningBalanceImg from '../assets/images/FAQ_Images/Q43 FirmOpeningBalance_2.jpg';
import MFAImg from '../assets/images/FAQ_Images/Q45 MFA_1.jpg';
import GlobalSearchImg from '../assets/images/FAQ_Images/Q46 GlobalSearch_1.jpg';
import OtherwaysClientInfoImg from '../assets/images/FAQ_Images/Q47 OtherwaysClientinfo_1.jpg';
import EditRefundAmountImg from '../assets/images/FAQ_Images/Q48 EditRefundAmount_1.jpg';
import UpdateRefundValueImg from '../assets/images/FAQ_Images/Q48 UpdateRefundValue_1.jpg';
import ReportsImg from '../assets/images/FAQ_Images/Q50 Reports_1.jpg';
import SyndataImg from '../assets/images/FAQ_Images/Q51 Syndata_1.jpg';

// Create the HelpCenterContext
const HelpCenterContext = createContext();

// Create the HelpCenterProvider component
export function HelpCenterProvider({ children }) {
  const navigate = useNavigate();
  const [helpDefaultActive, setHelpDefaultActive] = useState(['0']);
  // const previousHelpCenterIdRef = useRef(null);

  const helpCenterArray = [
    {
      key: 1,
      type: 'Homepage',
      name: `How can TrustEasy benefit an accounting firm in managing its trust accounts?`,
      text: (
        <p>
          TrustEasy is a trust account management software designed for Tax Practitioners to
          efficiently streamline FFR(Fee From Refund) processing by automating fee calculations and
          generating separate payment files for clients and practitioners. This, along with features
          like automated bank feeds and reconciliation, enhances compliance and reduces the risk of
          errors. By automating tasks and improving efficiency, TrustEasy allows accounting firms to
          focus on higher-value client services.
        </p>
      )
    },
    {
      key: 2,
      type: 'Homepage',
      name: `What information is displayed on the TrustEasy dashboard? How can I view this data through Kanban and list views?`,
      text: (
        <>
          {' '}
          <p>
            TrustEasy’s Home Page provides a user-friendly dashboard with KanBan and list views for
            efficient FFR(Fee From Refund) tracking:
          </p>
          <div className="highlighted-text">
            <span>KanBan View:</span>This visual representation categorizes FFRs into four stages:
          </div>
          <ol className="order-list-label">
            <li>
              <span>Unallocated Refunds:</span>
              Displays refunds processed by the ATO but not yet allocated to clients.
            </li>
            <li>
              <span>Unprocessed Refunds:</span>
              Shows refunds processed by the ATO, ready for client disbursement(processing and
              payment to clients).
            </li>
            <li>
              <span>Under Review:</span>
              Highlights refunds requiring further attention or verification.
            </li>
            <li>
              <span>Processed/Completed:</span>
              Displays successfully processed and disbursed refunds.
            </li>
          </ol>
          <div className="highlighted-text">
            <span>List View:</span>Presents the same information as the Kanban view in a tabular
            format, ideal for users handling a large volume of FFRs.
          </div>
          <div className="highlighted-text">Both Views allow for:</div>
          <ol className="order-list-label">
            <li>
              <span>Sorting:</span>
              Organize data by name or date for easy navigation.
            </li>
            <li>
              <span>Filtering:</span>
              Focus on specific date ranges for targeted analysis.
            </li>
          </ol>
          <div className="highlighted-text">Key Features:</div>
          <ol className="order-list-label">
            <li>
              <span>Clear Visual Representation:</span>
              The Kanban view provides an intuitive overview of the FFR processing pipeline.
            </li>
            <li>
              <span>Efficient Data Management:</span>
              Sorting and filtering options enhance data organization and retrieval.
            </li>
            <li>
              <span>Summary Information:</span>
              The dashboard displays the total sum of processed refunds within a selected period.
            </li>
          </ol>
          <img src={KanBanViewImg} />
          <p>
            By utilizing these features, TrustEasy empowers tax practitioners to efficiently manage
            their FFR workflow, track progress, and ensure timely and accurate client disbursements.
          </p>
        </>
      )
    },
    {
      key: 3,
      type: 'Firm Settings',
      name: `How can I enter the Firm’s details viz. Accounting body, logo, tax software, bank details etc?`,
      text: (
        <>
          <div className="highlighted-text">
            To enter your firm’s details in TrustEasy, follow these steps:
          </div>
          <ol className="order-list-label">
            <li>
              <span>Access General Settings:</span>
              Go to “Settings” in the top menu and select “General Settings”.
            </li>
            <li>
              <span>Navigate to Firm Settings:</span>
              Click on the “Firm Settings” tab within the General Settings area.
            </li>
            <li>
              <span>Enter Firm Information:</span>
              <ul>
                <li>Provide your firm’s name, address, and tax agent number.</li>
                <li>Select your accounting body affiliation.</li>
                <li>Upload your firm’s logo.</li>
                <li>Specify the tax software you use.</li>
                <li>Enter your bank account details.</li>
              </ul>
            </li>
            <li>
              <span>Set Opening Balance:</span>
              If applicable, enter the starting balance of your trust account.
            </li>
            <li>
              <span>Set Standard Disbursement Fee:</span>
              If you charge a standard fee for disbursements, enter it here for automatic inclusion
              during refund processing (also known as the handling fees).
            </li>
          </ol>
          <img src={FirmSettingsImg} />
          <p>
            By completing these steps, you ensure accurate firm information within TrustEasy, which
            is crucial for smooth and efficient trust account management.
          </p>
        </>
      )
    },
    {
      key: 4,
      type: 'Withdrawal',
      name: `Where can I enter the withdrawal account’s details?`,
      text: (
        <>
          <p>
            To enter the banking details in the withdrawal account navigate to Settings then General
            Settings and the second tab will be Withdrawal Settings. Here the fees that you will be
            charging from the clients will be transferred into this account.
          </p>
          <img src={WithdrawalImg} />
        </>
      )
    },
    {
      key: 5,
      type: 'Integrations',
      name: `How can I import client data from Xero Practice Manager or Access Accountant Cloud(Salesforce)?`,
      text: (
        <>
          <p>
            TrustEasy offers a one-way API integration with XPM and AAC (built on Salesforce) to
            seamlessly import your client list.
          </p>
          <div className="highlighted-text">
            <span>Steps:</span>
          </div>
          <ol className="order-list-label">
            <li>
              <span>Establish Connection:</span>
              <ul>
                <li>Go to “Settings” &gt; “General Settings” &gt; “Integrations” in TrustEasy</li>
                <li>Click “Connect” below the Xero or Salesforce logo.</li>
                <li>Enter your credentials and accept their terms and conditions.</li>
              </ul>
              <img src={IntegrationImg_1} />
            </li>
            <li>
              <span>Import Clients:</span>
              <ul>
                <li>Go to the “Clients” area in TrustEasy.</li>
                <li>Click “Import” and select the Xero or Salesforce logo.</li>
              </ul>
              <img src={IntegrationImg_2} />
            </li>
            <li>
              <span>Summary Information:</span>
              The dashboard displays the total sum of processed refunds within a selected period.
            </li>
          </ol>
          <div className="highlighted-text">
            <span>Important Notice:</span>
            <ul>
              <li>
                <span>Import Logs:</span>View a list of imported and non-imported clients in the
                Import Logs.
              </li>
              <li>
                <span>TFN Masking:</span>TFNs imported from XPM are masked (e.g. xxxx1234). Enter
                the full TFN manually in TrustEasy.
              </li>
              <li>
                <span>Data Security:</span>TrustEasy adheres to data security standards when
                exchanging information with XPM and AAC.
              </li>
            </ul>
            <p>
              This integration streamlines client data entry and enhances efficiency within the
              TrustEasy platform.
            </p>
          </div>
        </>
      )
    },
    {
      key: 6,
      type: 'Office',
      name: `Can I enter multiple office details?`,
      text: (
        <>
          <p>
            Yes, in TrustEasy you have the option to enter multiple office details. You can do this
            by following these steps :
          </p>
          <ul>
            <li>Go to Settings.</li>
            <li>Click on “General Settings”.</li>
            <li>
              In the General Settings menu, you will find a tab called “offices”. Click on that tab.
            </li>
            <li>In the Offices tab, you can enter the details of multiple offices,</li>
            such as their names, addresses, contact information, and any other relevant details.
            <li>Click on “Save” or “Update” to save the office details.</li>
          </ul>
          <img src={OfficeImg} />
          <p>
            By entering multiple office details in TrustEasy, you can manage and keep track of
            different office locations within your organization. In the client’s history, you will
            be able to see which office processed the refund in the past.
          </p>
        </>
      )
    },
    {
      key: 7,
      type: 'Chart Of Accounts',
      name: `Where can I find a chart of Accounts and make changes to the standard chart of accounts?`,
      text: (
        <>
          <p>
            In order to find the chart of accounts and make changes in standard chart of account
            please follow these steps:
          </p>
          <ol>
            <li>
              <span>Access Settings:</span>Navigate to the “Settings” area then “General Settings”
              within the TrustEasy application.
            </li>
            <li>
              <span>Locate Chart of Accounts:</span>Find and click on the “Chart of Accounts”
              section within the General settings.
            </li>
            <li>
              <span>View Standard Chart of Accounts:</span>Review the standard Chart of Accounts
              provided by TrustEasy.
            </li>
            <li>
              <span>Make Changes:</span>
              <ul>
                <li>Add new accounts.</li>
                <li>Delete existing accounts.</li>
                <li>Edit account details.</li>
                <li>Specify GST applicability for each account.</li>
              </ul>
            </li>
          </ol>
          <img src={ChartsOfAccountImg} />
          <div className="highlighted-text">
            <span>Important Considerations:</span>
          </div>
          <ol>
            <li>
              <span>Statutory Trust Account:</span>TrustEasy is designed for managing a statutory
              trust account. Ensure that account entries reflect this specific account type. “Funds
              Held in Trust” is typically a pre-filled account for reconciliation purposes.
            </li>
            <li>
              <span>Bank Charges :</span>Verify with your bank that charges for a trust account are
              not applied directly to the trust account itself.
            </li>
            <li>
              <span>APSEB Guidance:</span>Refer to the APSEB sample letter for opening a trust bank
              account to ensure the account is correctly established.
            </li>
          </ol>
          <p>
            By following these steps, you can effectively manage and customize the Chart of Accounts
            within TrustEasy to meet your specific accounting needs.
          </p>
        </>
      )
    },
    {
      key: 8,
      type: 'Supplier',
      name: `Where do I enter the supplier’s details?`,
      text: (
        <>
          <p>
            By entering supplier information into TrustEasy, you can enhance the efficiency and
            accuracy of your financial management processes. To enter the supplier’s information
            please follow these steps:
          </p>
          <ol>
            <li>
              <span>Access Settings:</span>
              Go to “Settings” and then the “General Settings” within the TrustEasy application.
            </li>
            <li>
              <span>Navigate to Supplier Information:</span>
              Within “General Settings”, find and select the “Suppliers” tab or section.
            </li>
            <li>
              <span>Enter Supplier Details:</span>
              Enter the following information for each supplier:
              <ul>
                <li>Supplier Name</li>
                <li>ABN / ACN (if applicable)</li>
                <li>Business Name</li>
                <li>Contact Person</li>
                <li>Phone Number</li>
                <li>Email Address</li>
                <li>Other relevant Details (as needed)</li>
              </ul>
            </li>
          </ol>
          <img src={SupplierImg} />
          <div className="highlighted-text">
            <span>Note:</span>
          </div>
          <ul>
            <li>
              Entering supplier details is optional but recommended for improved record-keeping and
              streamlined financial management within TrustEasy.
            </li>
            <li>
              I while BPAY payments to the ATO can be made as usual, It’s crucial to record these
              transactions within TrustEasy for accurate financial records.
            </li>
          </ul>
        </>
      )
    },
    {
      key: 9,
      type: 'Beneficiaries',
      name: `Is there any easy way to enter the trust payments and trust receipts and can I enter the beneficiary payments?`,
      text: (
        <>
          <p>TrustEasy provides a streamlined process for entering trust payments and receipts:</p>
          <ol>
            <li>
              <span>Trust Payments:</span>
              <ul>
                <li>Navigate to “I want to” &gt; “Enter Trust Payments”.</li>
                <li>Enter payment details: amount, payee, date, and notes.</li>
                <li>Add beneficiary details if applicable.</li>
                <li>An.ABA file is generated for electronic funds transfer.</li>
              </ul>
            </li>
            <img src={TrustPaymentImg} />
            <li>
              <span>Trust Receipts:</span>
              <ul>
                <li>Go to “I want to” &gt; ”Enter Trust Receipts”.</li>
                <li>Enter receipt details: amount, payer, date, and notes.</li>
              </ul>
            </li>
            <img src={TrustReceiptImg} />
          </ol>
          <p>
            By utilizing these dedicated features, you can efficiently record trust transactions
            within TrustEasy, ensuring accurate accounting and reconciliation. This is particularly
            helpful when dealing with refunds or payments to entities like the ATO.
          </p>
        </>
      )
    },
    {
      key: 10,
      type: 'Subscription',
      name: `Where can I find my subscription details?`,
      text: (
        <>
          <ul>
            <li>Log in to your TrustEasy account.</li>
            <li>Go to “Settings” from the top menu.</li>
            <li>Select “General Settings”.</li>
            <li>Click on the “Subscription” tab.</li>
            <li>
              Review your subscription details: This includes your plan, renewal date, and billing
              information.
            </li>
            <li>
              Explore plan options: If you wish to change your plan, navigate to the relevant
              section within the Subscription settings.
            </li>
          </ul>
          <img src={SubscriptionImg} />
          <p>
            For further assistance: Contact TrustEasy support for any questions or issues regarding
            your subscription.
          </p>
        </>
      )
    },
    {
      key: 11,
      type: 'Authority Letter',
      name: `How do I produce authority letters and make changes to the contents of the letter?`,
      text: (
        <>
          <p>
            For generating the authority letters as well as making a change in that then please
            follow these steps :
          </p>
          <ol>
            <li>
              <span>Generating Authority Letters:</span>
              To generate an authority letter for a client, navigate to the client list and select
              the desired client by clicking on the view/ eye icon on the right side.
            </li>
            <img src={AuthoriytLetterImg_1} />
            <li>
              <span>Customizing Letter Content:</span>
              Access Template Settings: Go to “Settings” -&gt; “Template Settings” to customize the
              authority letter template.
            </li>
            <img src={AuthoriytLetterImg_2} />
          </ol>
        </>
      )
    },
    {
      key: 12,
      type: 'User Console',
      name: `User Management, Where do I add or archive users?`,
      text: (
        <>
          <ul>
            <li>
              <span>Access Settings:</span>
              Go to “Settings” within the TrustEasy application.
            </li>
            <li>
              <span>Select User Console:</span>
              Navigate to the “User Console” option within the Settings menu.
            </li>
          </ul>
          <img src={UserConsoleImg} />
          <div className="highlighted-text">
            <span>User Management:</span>
          </div>
          <ul>
            <li>
              <span>Add New User:</span>
              Click “New” to add a new user and configure their access permissions.
            </li>
            <li>
              <span>Edit User:</span>
              Modify user details, permissions, and MFA settings.
            </li>
            <li>
              <span>Archive User:</span>
              Deactivate a user’s account(can be reactivated later.)
            </li>
            <li>
              <span>Resend Activation Email:</span>
              Resend the activation email to a user if they haven’t received it.
            </li>
          </ul>
        </>
      )
    },
    {
      key: 13,
      type: 'User Console',
      name: `How do I reassign a task to a particular user/team member?`,
      text: (
        <>
          <p>
            To reassign a task to a specific user or team member in TrustEasy, you can follow these
            steps:
          </p>
          <ol>
            <li>
              <span>Go to the dashboard:</span>
              Navigate to the dashboard where you can view all the clients and their refund stages
              represented by labels.
            </li>
            <li>
              <span>Locate the client or task:</span>
              Identify the client or task that you want to reassign to a particular team member.
            </li>
            <li>
              <span>Access the assignment options:</span>
              On the label representing the task, you will find an area typically labeled “Assigned
              to N/A” by default. Click on the three-dot menu located within this area.
            </li>
            <img src={ReassignTaskImg_1} />
            <li>
              <span>Select the assignee:</span>
              From the menu options that appear, choose the option to assign the label to a team
              member/user. A list of users that you have previously entered in the TrustEasy
              settings will be displayed. Select the appropriate team member from the list.
            </li>
            <li>
              <span>Confirm the reassignment:</span>
              Once you select the team member, the task will be reassigned to the team. Their name
              will then replace “Assigned to N/A” on the label.
            </li>
            <img src={ReassignTaskImg_2} />
          </ol>
          <p>
            By reassigning tasks, you can effectively distribute the workload among your team
            members and efficiently track the progress of each assigned task.
          </p>
        </>
      )
    },
    {
      key: 14,
      type: 'User Activity',
      name: `How can I view the activity history data for my team members/ users?`,
      text: (
        <>
          <p>
            In TrustEasy, you can view your team member&apos;s activity history using the following
            steps:
          </p>
          <ol>
            <li>Navigate to the &rdquo;Settings&rdquo; option in the top menu.</li>
            <li>Click on &rdquo;User Activity Log&rdquo;.</li>
            <li>
              This page displays all member&apos;s activity history, organized by time and date.
            </li>
            <li>
              To view a specific person&apos;s activity, use the search option to find their name.
              The results will then show the activity history for that individual.
            </li>
          </ol>
          <img src={UserActivityLogImg} />
        </>
      )
    },
    {
      key: 15,
      type: 'Client',
      name: `How do I import a list of clients into TrustEasy using a .csv file?`,
      text: (
        <>
          <p>
            Under the client’s section, there will be an Import button, where you can upload the
            client’s data either from the cloud base CRM software or there will be one ready-made
            .csv/excel template available in the Client&apos;s page. you will need to download it
            and manually edit it and upload it back in the TrustEasy for uploading the client’s data
            from the desktop base software.
          </p>
          <img src={ClientImg} />
          <div className="highlighted-text">To upload the .csv file please follow these steps:</div>
          <ul>
            <li>Navigate to "Clients" from the first option in the left sidebar.</li>
            <li>Look for the "Import" button.</li>
            <li>
              Once click on Import there will be drag and drop option available for you where you
              will need to upload the file.
            </li>
          </ul>
        </>
      )
    },
    {
      key: 16,
      type: 'Client',
      name: `What are some other ways to enter Client info?`,
      text: (
        <>
          <p>
            Aside from retrieving client data directly from your CRM, there are other ways to input
            client information into the system. One such method is encountered during the process
            refund procedure. If a client is not properly registered, you can navigate to the
            process refund page and click on the “New” tab to add the client manually within the
            TrustEasy system.
          </p>
          <img src={ClientInfoImg} />
        </>
      )
    },
    {
      key: 17,
      type: 'Client',
      name: `How do I find the client’s history of refunds?`,
      text: (
        <>
          <p>To get the Client history of refunds you can follow these steps:</p>
          <ol>
            <li>
              <span>Navigate to Clients:</span>Go to the “Clients” section within TrustEasy.
            </li>
            <li>
              <span>Find the Clients:</span>
              <ul>
                <li>Search for the specific client using the search function.</li>
                <li>
                  Click on the “&gt;” symbol next to the client’s name to expand their details.
                </li>
              </ul>
            </li>
            <li>
              <span>View Client History:</span>The expanded view will display the client’s history,
              including a list of processed tax returns.
            </li>
            <img src={ClientsHistoryImg} />
            <li>
              <span>Review Refund Details:</span>This list will provide information on each refund,
              such as :
              <ul>
                <li>Refund amount</li>
                <li>Date of processing</li>
                <li>Status (processed or pending)</li>
              </ul>
            </li>
          </ol>
          <p>
            By following these steps, you can easily access and review the refund history for any
            specific client within the TrustEasy system.
          </p>
        </>
      )
    },
    {
      key: 18,
      type: 'New Client',
      name: `How can I enter client info manually?`,
      text: (
        <>
          <ol>
            <li>
              <span>Navigate to Clients:</span>Go to the “Clients” tab within the TrustEasy
              software.
            </li>
            <li>
              <span>Click New:</span>Click the “New” button to create a new client entry.
            </li>
            <li>
              <span>Enter Essential Information:</span>
              <ul>
                <li>
                  <span>Mandatory Fields:</span>Enter the client’s First Name, Last Name, Tax File
                  Number(TFN), and Email Address.
                </li>
                <li>
                  <span>Recommended:</span>Enter the client’s bank details for seamless .aba file
                  generation for net refunds.
                </li>
              </ul>
              <img src={ClientsInfoImg_1} />
            </li>
          </ol>
          <p>
            This guide outlines the basic steps for manually entering client information within the
            TrustEasy platform.
          </p>
        </>
      )
    },
    {
      key: 19,
      type: 'New Client',
      name: `What is KYC and how do I manage it?`,
      text: (
        <>
          <p>
            KYC (Know Your Client) is a critical process that involves verifying and collecting
            essential information about clients to comply with legal and regulatory requirements.
          </p>
          <p>
            In TrustEasy, KYC involves verifying client identity and relevant details before
            providing tax agent services and Business Activity Statement(BAS) services. This process
            adheres to the strict guidelines set forth by the Tax Practitioners Board (TPB).
          </p>
          <p>To manage KYC in TrustEasy:</p>
          <ol>
            <li>
              <span>Access Client Profile:</span>Go to the client’s profile within the TrustEasy
              software.
            </li>
            <li>
              <span>Enter Client Information:</span>Enter the required client details, such as name,
              address, contact information, and identification numbers.
            </li>
            <li>
              <span>Select Appropriate Options:</span>Choose the correct options for identification
              documents(e.g, driver’s license, passport) based on the TPB’s guidelines.
            </li>
            <li>
              <span>Upload Documents:</span>Upload copies of identification documents if necessary.
            </li>
          </ol>
          <img src={KYCImg} />
          <p>
            By effectively managing KYC within TrustEasy, you ensure compliance with TPB
            regulations, maintain accurate client records, and uphold the integrity of your tax
            agent and BAS services.
          </p>
        </>
      )
    },
    {
      key: 20,
      type: 'Archived Clients',
      name: `Where can I find the list of client’s that I have archived?`,
      text: (
        <>
          <p>In order to find the list of archived clients you will need to follow these steps:</p>
          <div className="highlighted-text">
            <span>Archived Clients:</span>
          </div>
          <ul className="order-list-label">
            <li>Navigate to the “Clients” section in the top menu of TrustEasy.</li>
            <li>Select the “Archived Clients” option from menu.</li>
            <img src={ArchivedClientsImg} />
            <li>
              This option will display a list of all the clients that have been archived within the
              system.
            </li>
          </ul>
        </>
      )
    },
    {
      key: 21,
      type: 'Update Clients TFN',
      name: `The Tax File Numbers(TFNs) were not imported from Xero as it imported only the last three digits. What is the procedure for manually updating it?`,
      text: (
        <>
          <p>
            Due to security restrictions, Xero and Salesforce do not display full TFNs within
            TrustEasy. Therefore, you must manually update them.
          </p>
          <div className="highlighted-text">Follow these steps:</div>
          <ol className="order-list-label">
            <li>Navigate to "Clients" from the top menu.</li>
            <li>Select "Update the TFNs".</li>
            <li>Download the provided template.</li>
            <li>
              Enter all the required information, including the complete TFNs, into the template.
            </li>
            <li>
              Upload the completed template back into TrustEasy by clicking "Import" and then using
              the drag-and-drop option.
            </li>
          </ol>
          <img src={UpdateTFNImg} />
          <p>These steps will update all client TFNs within the system.</p>
        </>
      )
    },
    {
      key: 22,
      type: 'Duplicate Client',
      name: `What is the process for identifying duplicate client entries in the 	system?`,
      text: (
        <>
          <p>
            In TrustEasy when you import the client data from the Xero and Salesforce then TurstEasy
            will automatically identify the duplicate entries of clients and those list of clients
            will be placed in the Duplicate client’s entries. For getting those duplicate client’s
            list please follow these steps:
          </p>
          <ol>
            <li>Navigate to “Clients” from the top menu.</li>
            <li>Then select the “Duplicate Clients”.</li>
            <li>
              Once you get the list of clients then you can delete those duplicate entries from
              selecting “Delete Duplicate”.
            </li>
          </ol>
          <img src={DuplicateEntriesImg} />
        </>
      )
    },
    {
      key: 23,
      type: 'ATO Refund',
      name: `What is the process for entering ATO refund data on an individual basis?`,
      text: (
        <>
          <p>
            While the “Process Refund” button on the homepage is the most efficient method, you can
            also manually enter ATO refund data individually.
          </p>
          <ol>
            <li>
              <span>Access Refund Entry:</span>
              <ul>
                <li>
                  <span>Option 1:</span>
                  Click on the third icon on the left-side menu labeled “Refund from the ATO”.
                </li>
                <li>
                  <span>Option 2:</span>
                  Navigate to the top menu, select “I want to”, and choose “Import EFT
                  reconciliation report”. Both options lead to the same location.
                </li>
              </ul>
            </li>
            <li>
              <span>Enter Refund Data:</span>
              <ul>
                <li>Click the “Add Manually” button.</li>
                <li>The system will pre-fill tax return details for your convenience.</li>
                <li>Enter the necessary refund information manually.</li>
              </ul>
            </li>
          </ol>
          <img src={ATORefundImg_1} />
          <p>
            This manual method allows for individual entry of ATO refund data while still benefiting
            from pre-filled tax return details.
          </p>
        </>
      )
    },
    {
      key: 24,
      type: 'ATO Refund',
      name: `How can I import the ATO EFT reconciliation report in TrustEasy?`,
      text: (
        <>
          <p>
            To import the ATO EFT reconciliation report in TrustEasy please follow the process :
          </p>
          <ol>
            <li>
              <span>Obtain the Report:</span>
              Request and obtain the EFT Reconciliation Report from your tax lodgement software.
            </li>
            <li>
              <span>Prepare the Data:</span>
              <ul>
                <li>
                  <span>Copy the Report:</span>
                  Copy the contents of the EFT Reconciliation Report.
                </li>
                <li>
                  <span>Paste into Excel/CSV Template:</span>
                  Paste the copied data into a new Excel or CSV file which is ready-made available
                  in TrustEasy under the Refund from ATO.
                </li>
                <li>
                  <span>Format:</span>
                  Format the data in the TrustEasy-specific format. This format is available on the
                  “ATO Refund” section of the TrustEasy website.
                </li>
              </ul>
            </li>
            <li>
              <span>Import into TrustEasy:</span>
              <ul>
                <li>Import the formatted Excel/CSV file into the TrustEasy system.</li>
                <li>
                  Follow the on-screen instructions within TrustEasy to complete the import process.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            This method allows you to efficiently import ATO EFT Reconciliation Reports into
            TrustEasy for further processing and reconciliation within the system.
          </p>
          <p>
            <span>NOTE:</span>Always refer to the specific instructions and guidelines provided by
            TrustEasy for the most accurate and up-to-date import procedures.
          </p>
        </>
      )
    },
    {
      key: 25,
      type: 'Process Refund Bulk',
      name: `How can I process refund in bulk?`,
      text: (
        <>
          <p>To process refunds in bulk, you’ll need to import data in two steps:</p>
          <ul>
            <li>
              <span>EFT Reconciliation Report:</span>
              Download the EFT Reconciliation Report from your tax lodgement software. This report
              is essential for reconciling refund amount.
            </li>
            <li>
              <span>Import EFT Reconciliation Report:</span>
              Once you download the EFT report from the Tax lodgement software then please download
              our ready made template from the TrustEasy by going to the “Refund from ATO” tab. Then
              complete all the information which has been asked according to the EFT Reconciliation
              report. And then import it to the TrustEasy.
            </li>
          </ul>
          <img src={ProcessRefundInBulkImg} />
          <p>
            Once the EFT Reconciliation Report template is successfully imported, you can proceed by
            clicking on the “Process Refund in Bulk” button. You can access this “Process Refunds in
            Bulk” function from various locations within the software, such as:
          </p>
          <ul>
            <li>Third icon on the left-sidebar.</li>
            <li>Under the “I want to” option second option in the main menu.</li>
          </ul>
        </>
      )
    },
    {
      key: 26,
      type: 'Process Refund',
      name: ` I need to process a refund for one customer at a time. What is the best way to manage it? `,
      text: (
        <>
          <p>
            TrustEasy offers two refund options: One-on-One Refunds and Bulk Refunds. For processing
            individual customer refunds, use the One-on-One Refund process:
          </p>
          <ol>
            <li>From the "Homepage," select "Process Refund."</li>
            <li>Use the search option to select the customer and view their account details.</li>
            <li>In the "ATO REFUND" box, click the "Click HERE" link.</li>
            <li>Enter the refund details.</li>
            <li>
              Clicking "Save" will automatically populate information into the next box, "TAX
              Software/Invoice Entry."
            </li>
            <li>Enter the "Professional fees."</li>
            <li>
              Click "Process Refund." This will generate an .aba file, which you must download and
              upload to your online banking system to complete the refund.
            </li>
          </ol>
          <img src={ProcessRefundImg} />
        </>
      )
    },
    {
      key: 27,
      type: 'Generate .ABA',
      name: `Describe the .aba file format and the procedure within TrustEasy for generating an .aba file for the electronic withdrawal of professional fees.`,
      text: (
        <>
          <p>
            The .aba file format is a standard file format used for bulk electronic fund transfers
            in Australia, as defined by the Australian Banking Association.
          </p>
          <div>Within TrustEasy:</div>
          <ol>
            <li>
              <span>.ABA File Generation:</span>
              After processing client refunds, TrustEasy generates raw .aba files for each refund
              transaction. These files are stored within the “Generate .aba” section under “.ABA
              Files”.
            </li>
            <li>
              <span>Selection and Processing:</span>
              You can select specific refunds, “undo” certain transactions, or create a bulk .aba
              file for all processed refunds. Upon clicking “Create .aba file”, TrustEasy generates
              a consolidated .aba file for the selected refunds.
            </li>
            <li>
              <span>Download and Import:</span>
              Download the generated .aba file to your computer and import it into your online
              banking system. This initiates the transfer of net refund amounts to clients.
            </li>
            <li>
              <span>Fee Withdrawal:</span>
              TrustEasy automatically generates separate .aba files for your professional fees
              associated with each refund.
            </li>
            <img src={ABAFilesImg} />
            <li>
              <span>Fee Transfer:</span>
              Import these fee-related .aba files into your online banking to transfer fees to your
              designated withdrawal account.
            </li>
          </ol>
          <div>
            <span>Prerequisites:</span>
          </div>
          <p>
            <span>Correct Bank Details:</span>Ensure accurate bank details are entered in the
            withdrawal accounts section of TrustEasy’s settings. This process streamlines fee
            collection and client refund disbursements, improving efficiency and accuracy within
            TrustEasy.{' '}
          </p>
        </>
      )
    },
    {
      key: 28,
      type: 'Generate .ABA',
      name: `How can I send receipts for the ATO refund?`,
      text: (
        <>
          <p>To send the receipt for the ATO refunds please follow these steps :</p>
          <ol>
            <li>
              <span>Generate Bulk .ABA File:</span>
              After processing refunds, generate a .aba file by clicking on the “Generate .ABA”
              button.
            </li>
            <img src={GenerateABAImg} />
            <li>
              <span>Receipt Sending Prompt:</span>
              TrustEasy will prompt you to send an automatic receipt to your clients.
            </li>
            <li>
              <span>Send Automatic Receipt:</span>
              Click “Yes” to send the receipt immediately. TrustEasy will automatically generate and
              send a receipt to the client’s registered email address.
            </li>
            <img src={SendAutomaticABAImg} />
            <li>
              <span>Postpone Receipt Sending:</span>
              Click “No” to postpone sending the receipt. You can access and send the receipt later
              from the .aba file history area.
            </li>
          </ol>
          <p>
            TrustEasy provides the flexibility to send receipts for ATO refunds immediately or at a
            later time according to your preference.
          </p>
        </>
      )
    },
    {
      key: 29,
      type: 'Client Statement',
      name: `How do I produce Client Statement(s)?`,
      text: (
        <>
          <p>To produce client statements in TrustEasy, you can follow these steps :</p>
          <ol>
            <li>
              <span>Individual Client Statement:</span>
              <ul>
                <li>
                  <span>Locate Client:</span>Search for and select the desired client from the
                  client list.
                </li>
                <li>
                  <span>View Statement:</span>Click on the “View Statement” option within the
                  client’s details.
                </li>
              </ul>
              <img src={ViewStatementImg_1} />
            </li>
            <li>
              <span>Multiple Client Statements:</span>
              <ul>
                <li>
                  <span>Access Reports:</span>Go to the “Reports” section.
                </li>
                <li>
                  <span>Select Client Statement:</span>Choose the “Client Statement” option.
                </li>
                <li>
                  <span>Select Clients:</span>Choose the desired clients.
                </li>
                <li>
                  <span>Specify Period:</span>If necessary, select the desired date range for the
                  statements.
                </li>
                <li>
                  <span>Export/Generate:</span>Click on the “Export” tab or the relevant button to
                  generate the selected client statements.
                </li>
              </ul>
              <img src={ViewStatementImg_2} />
            </li>
          </ol>
          <p>
            These steps allow you to efficiently produce both individual and multiple client
            statements within TrustEasy.
          </p>
        </>
      )
    },
    {
      key: 30,
      type: 'Client Transaction',
      name: `Where can I view all the client transactions and see whether they are reconciled or not?`,
      text: (
        <>
          <p>
            To view all client transactions and their reconciliation status in TrustEasy, follow
            these steps:
          </p>
          <ol>
            <li>
              <span>Access the Reports section:</span>Navigate to the “Reports” from the top menu.
            </li>
            <li>
              <span>Select Client’s Transactions :</span>Select the “Client’s Transactions” in the
              Reports menu.
            </li>
            <li>
              <span>View Client Transactions:</span>Once you are on the Client’s Transaction page,
              you will see a list of transactions from the trust account for all clients. This will
              include details such as transaction dates, amounts, and descriptions.
            </li>
            <li>
              <span>Check reconciliation status:</span>To determine whether a transaction is
              reconciled or not, look for the status indicators. If a transaction is reconciled with
              the bank statement, it will display a green tick mark. On the other hand, if the
              transaction is not reconciled, it will show a red cross symbol next to it.
            </li>
          </ol>
          <img src={ClientTransactionImg_2} />
          <p>
            By following these steps, you can easily view all client transactions in TrustEasy and
            identify their reconciliation status, helping you conduct a three-way verification of a
            trust transaction. This is crucial for Trust Account Audits.
          </p>
        </>
      )
    },
    {
      key: 31,
      type: 'Trial Balance',
      name: `How can I find the closing balance of my client in TrustEasy?`,
      text: (
        <>
          <p>
            TrustEasy provides a "Trial Balance" feature to track client balances. To find the
            closing balance, follow these steps:
          </p>
          <ol>
            <li>Navigate to "Reports" from the top menu.</li>
            <li>Select the "Trial Balance" option.</li>
            <li>This will display the closing balance for all your clients.</li>
            <li>You can download a summary by clicking the "Export" option.</li>
            <li>You can also select a specific time frame for the report.</li>
          </ol>
          <img src={TrialBalanceImg} />
        </>
      )
    },
    {
      key: 32,
      type: 'Monthly Bank Reconciliation',
      name: `Do TrustEasy have a monthly bank reconciliation report? If so, how can I access/download the monthly bank reconciliation report?`,
      text: (
        <>
          <p>
            Yes, TrustEasy offers a monthly bank reconciliation report. To access and download it,
            follow these steps:
          </p>
          <ol>
            <li>Navigate to "Report."</li>
            <li>Select the "Monthly Bank Reconciliation" report.</li>
            <li>
              If your Trust Account is correctly integrated and you regularly perform integrations,
              you should be able to generate an accurate report.
            </li>
            <li>
              Click the "Export" option to download the report. You can choose between a "Summary"
              or "Detailed" report format.
            </li>
          </ol>
          <img src={MonthlyReconciliationImg} />
        </>
      )
    },
    {
      key: 33,
      type: 'Reconcile Transaction',
      name: `How can I reconcile bank statements?`,
      text: (
        <>
          <p>
            Once the bank account has been integrated into the TrustEasy then for reconcile bank
            statements and follow the steps:
          </p>
          <ol>
            <li>
              <span>Access Firm Bank Account:</span> Navigate to the “Firm Bank Account” section.
            </li>
            <li>
              <span>Go to Reconcile Transactions:</span> Click on the “Reconcile Transactions” tab.
            </li>
            <li>
              <span>View Bank Transactions:</span> Review the list of bank transactions on the left
              side.
            </li>
            <li>
              <span>Allocate Transactions:</span>
              <ul>
                <li>
                  Allocate each transaction to the appropriate Chart of Account, and client name,
                  and add a description as needed.
                </li>
                <li>
                  TrustEasy utilize AI and smart algorithms to assist in splitting and automatically
                  allocating transactions.
                </li>
              </ul>
            </li>
            <li>
              <span>Search for Specific Items:</span>
              <ul>
                <li>
                  Click on the three-dot menu next to a transaction and select the search option to
                  locate specific items.
                </li>
                <li>
                  If a transaction cannot be found, manually enter a trust receipt or payment from
                  the search results area.
                </li>
              </ul>
            </li>
            <li>
              <span>Send for Review:</span> Use the three-dot menu to send transactions requiring
              further review for proper handling.
            </li>
          </ol>
          <p>
            By following these steps, you can effectively reconcile bank statements within
            TrustEasy, ensuring accurate categorization, allocation and review of all transactions.
          </p>
        </>
      )
    },
    {
      key: 34,
      type: 'Reconcile Transaction',
      name: `How do I delete a wrong bank entry?`,
      text: (
        <>
          <p>To delete a wrong bank entry in TrustEasy, follow these steps:</p>
          <ol>
            <li>Navigate to “Firm Bank Account” and select the “Reconcile Transactions” tab.</li>
            <li>Locate and identify the incorrect bank transaction.</li>
            <li>Click on the three-dot menu next to the transaction.</li>
            <li>Select “Delete” from the options.</li>
            <img src={WrongEntryImg_1} />
            <li>
              Review the deleted transactions in the “Deleted Transactions” are of the Audit Trail.
            </li>
            <img src={WrongEntryImg_2} />
          </ol>
          <div>
            <span>Important Note:</span>While these steps generally outline the process, the exact
            steps might vary slightly depending on your specific TrustEasy version. Always exercise
            caution when deleting financial entries, as they cannot be easily recovered.
          </div>
        </>
      )
    },
    {
      key: 35,
      type: 'Reconciled Items',
      name: `Where can I see a list of all reconcile bank transactions?`,
      text: (
        <>
          <p>To view all reconciled bank transactions, follow these steps:</p>
          <ol>
            <li>Navigate to the Firm Bank Account.</li>
            <li>
              Entries that are not properly reconciled will appear in the "Reconciled Transaction"
              tab. You will need to manually reconcile these entries.
            </li>
            <li>
              Once a transaction (like a refund entry) is reconciled, it will move to the
              "Reconciled Items" tab, which is the second tab under the "Firm Bank Account" page.
              This tab displays all your reconciled transactions.
            </li>
          </ol>
          <img src={ReconcileItemImg} />
        </>
      )
    },
    {
      key: 36,
      type: 'Under Review',
      name: `How do I send doubtful bank entries for review?`,
      text: (
        <>
          <p>To review the doubtful bank entries in TrustEasy, follow these steps:</p>
          <ol>
            <li>Navigate to “Firm Bank Account” and select the “Reconcile Transactions”tab.</li>
            <li>Locate and identify the doubtful bank transaction.</li>
            <li>Click on the three-dot menu next to the transaction.</li>
            <li>Select “Send for the Review” from the options.</li>
            <li>The transaction will be moved to the “Under Review” section for further review.</li>
          </ol>
          <img src={SendforReviewImg} />
          <p>
            By using this feature, you can easily flag potentially problematic bank entries for
            review by other authorized users within TrustEasy, ensuring accurate and reliable
            financial records.
          </p>
          <div>
            <span>Note:</span>The exact steps and terminology may vary slightly depending on your
            specific TrustEasy version.
          </div>
        </>
      )
    },
    {
      key: 37,
      type: 'Under Review',
      name: `How do I send the inaccurate refund entry for review?`,
      text: (
        <>
          <p>To send an inaccurate refund entry for review in TrustEasy, follow these steps:</p>
          <ol>
            <li>
              <span>Locate the entry:</span>Navigate to the dashboard where you can view all clients
              and their refund stages. Identify the specific client or task containing the
              inaccurate refund entry.
            </li>
            <li>
              <span>Access the review option:</span>On the label representing the task, you will
              find an area typically labeled “Assigned to N/A”. Click on the three-dot menu within
              this area.
            </li>
            <img src={SendforReviewImg_1} />
            <li>
              <span>Initiate the review process:</span>From the menu options, select the option
              “Send for Review”.
            </li>
            <li>
              <span>Provide context(optional):</span>A dialog box will appear, allowing you to enter
              a comment or description regarding the inaccuracy. Provide any relevant details that
              will assist the reviewer in understanding and addressing the issue.
            </li>
            <img src={SendforReviewImg_2} />
            <li>
              <span>Submit for review:</span>Click on the “Send for Review” or similar button to
              submit the entry for review.
            </li>
          </ol>
          <p>
            The inaccurate refund entry will then be flagged for review and assigned to the
            designated team member or reviewer for evaluation and correction. This process helps
            ensure the accuracy and integrity of your refund processing workflow by allowing for the
            timely identification and resolution of any errors or discrepancies.
          </p>
        </>
      )
    },
    {
      key: 38,
      type: 'Bank Transaction',
      name: `How can I get automatic bank feeds into TrustEasy for the trust bank account?`,
      text: (
        <>
          <ol>
            <li>
              Access General Settings: Locate and navigate to the “General Settings” area within
              TrustEasy.
            </li>
            <li>Integrations: Go to the “Integrations” section within General Settings.</li>
            <li>
              Connect Bank Feeds: Click the “Connect” button within the “Automatic Bank Feeds” area.
            </li>
            <li>Bank Selection : Choose your bank from the provided list.</li>
            <li>
              Enter Credentials: Follow the prompts and enter your Internet banking credentials to
              establish a secure connection.
            </li>
            <li>
              Select Trust Account: Select the specific trust bank account associated with your
              firm.
            </li>
            <p>
              <span>Important:</span>Unselect any unnecessary accounts to avoid confusion.
            </p>
            <img src={BankTransactionImg_1} />
            <li>
              Verify Connection: Confirm the successful connection status in the integration
              settings.
            </li>
            <li>
              Import Transactions: Go to “Firm Bank Account” -&gt; “Bank Transactions” and click
              “Fetch” to import transactions automatically.
            </li>
            <img src={BankTransactionImg_2} />
          </ol>
          <p>
            By following these steps, you can successfully set up automatic bank feeds for your
            trust bank account in TrustEasy, enabling seamless data synchronization between
            TrustEasy and your bank.
          </p>
        </>
      )
    },
    {
      key: 39,
      type: 'Bank Transaction',
      name: `How can I import the Bank Statement via .csv in TrustEasy?`,
      text: (
        <>
          <ol>
            <li>
              <span>Access Firm Bank Account:</span>Navigate to the “Firm Bank Account” section.
            </li>
            <li>
              <span>Go to Bank Transactions:</span>Click on the “Bank Transactions” tab.
            </li>
            <li>
              <span>Initiate Import:</span>Click the “Import” button.
            </li>
            <li>
              <span>Upload .csv File:</span>Select and upload the .csv file containing your bank
              transactions. Ensure the file adheres to TrustEasy’s formatting requirements.
            </li>
            <img src={BankStatementCsvImg} />
            <li>
              <span>Duplicate Transaction Prevention:</span>TrustEasy employs algorithms to prevent
              duplicate imports.
            </li>
            <li>
              <span>Review Imported Transactions:</span>Carefully review the imported transactions
              for accuracy.
            </li>
            <li>
              <span>Delete Mistaken Transactions:</span>Delete any incorrect or unwanted
              transactions.
            </li>
            <li>
              <span>Audit Trail:</span>TrustEasy maintains an audit trail of all imported and
              deleted transactions, including date, time, and user.
            </li>
          </ol>
          <p>
            By following these steps, you can effectively import bank statements via .csv into
            TrustEasy, ensuring data accuracy and maintaining a clear audit trail of all
            transactions.
          </p>
        </>
      )
    },
    {
      key: 40,
      type: 'Audit Trail',
      name: `Is there any audit trail on bank entries I.e, if any bank transaction was deleted or when was it imported and how?`,
      text: (
        <>
          <p>
            Yes, TrustEasy maintains an “Audit Trail” tab within the “Firm Bank Account” section.
            This tab provides a detailed history of bank entries, including :
          </p>
          <ol>
            <li>
              <span>Import Times:</span>When each bank transaction was initially imported into the
              system.
            </li>
            <li>
              <span>Deletion Records:</span>Information about any deleted transactions, including
              the date and time of deletion.
            </li>
          </ol>
          <p>
            The “Audit Trail” tab allows you to review the history of your bank transactions,
            ensuring transparency and accountability for all changes made to bank entries within
            TrustEasy.
          </p>
        </>
      )
    },
    {
      key: 41,
      type: '',
      name: `Is TrustEasy a cloud-based software or does it requires a local installation on my computer?`,
      text: (
        <>
          <p>
            TrustEasy is a cloud-based software. You can access it from any browser with an internet
            connection and an active subscription.
          </p>
        </>
      )
    },
    {
      key: 42,
      type: '',
      name: `Which Australian Banks does TrustEasy integrate with for automated bank feeds?`,
      text: (
        <>
          <BankList />
        </>
      )
    },
    {
      key: 43,
      type: '',
      name: `How do I enter opening balances for my firm’s trust account and individual client trust accounts in TrustEasy?`,
      text: (
        <>
          <p>The process is as follow:</p>
          <div>
            <span>Client Trust Accounts:</span>Go to Settings &gt; Enter Opening Balance. Click
            “Add”, enter the Client’s name and balance and save.
          </div>
          <img src={ClientTrustAccountImg} />
          <div>
            <span>Firm Trust Account:</span>Go to Settings &gt; General Settings &gt; Firm Settings.
            Locate the “Trust Account Details” section and enter the balance in the “Firm Opening
            Balance” field. Save your changes.
          </div>
          <img src={FirmOpeningBalanceImg} />
        </>
      )
    },
    {
      key: 44,
      type: '',
      name: `What is APIs and how do they work with TrustEasy to connect to external cloud applications?`,
      text: (
        <>
          <p>
            An Application Programming Interface(API) is a set of rules and protocols that allows
            different software applications to communicate and interact with each other. If defines
            the methods and data formats that can be used to request and exchange information
            between applications.
          </p>
          <p>
            TrustEasy leverages APIs to enable seamless integration with other cloud-based
            applications. This allows for smooth data exchange and enhanced functionality.
          </p>
          <div>To connect to an external cloud-based app via TrustEasy’s APIs:</div>
          <ol>
            <li>
              <span>Identify the target app:</span>Ensure the app provides open APIs for
              Integration.
            </li>
            <li>
              <span>Contact TrustEasy Support:</span>Express your interest in connecting with the
              specific app.
            </li>
            <li>
              <span>Provide integration details:</span>Share your requirements with the support
              team.
            </li>
            <li>
              <span>Feasibility assessment:</span>TrustEasy will evaluate the integration’s
              feasibility.
            </li>
            <li>
              <span>Implementation:</span>TrustEasy’s development team will work on connecting the
              applications.
            </li>
            <li>
              <span>Start using the integration:</span>Begin exchanging data and utilizing the
              connected app’s features within TrustEasy.
            </li>
          </ol>
          <div>
            <span>NOTE:</span>Integration availability may vary. Consult TrustEasy Support for
            specific requirements and possibilities.
          </div>
        </>
      )
    },
    {
      key: 45,
      type: '',
      name: `How do I manage MFA/2FA?`,
      text: (
        <>
          <ol>
            <li>
              <span>Log in to TrustEasy:</span>Enter your credentials and access your account.
            </li>
            <li>
              <span>Access User Profile:</span>Locate your user profile from the top right corner
              (usually an icon with your username). Click on My Profile.
            </li>
            <li>
              <span>Manage MFA Settings:</span>Find the Two-Factor Authentication within the user
              profile. By default, MFA is recommended for enhanced security.
            </li>
            <li>
              <span>Enable/Disable MFA:</span>To disable MFA, follow the on-screen instructions.{' '}
            </li>
          </ol>
          <div>
            <span>Note:</span>Disabling MFA may compromise account security.
          </div>
          <img src={MFAImg} />
          <p>
            <span>Important Note:</span>MFA adds an extra security layer by requiring an additional
            verification step (e.g. code from an authentication app or SMS) beyond your password.
          </p>
          <div>
            By following these steps, you can effectively manage MFA/2FA settings within TrustEasy
            to enhance your account security.
          </div>
        </>
      )
    },
    {
      key: 46,
      type: '',
      name: `What type of information can be found using the Global Search Function in TrustEasy?`,
      text: (
        <>
          <p>
            TrustEasy’s Global Search function, accessible from any page within the portal , allows
            you to quickly find specific information across various areas of the software.
          </p>
          <p>
            When you enter a search query, the system will display relevant results, which may
            include:
          </p>
          <ul>
            <li>
              <span>Client Records:</span>Find client information based on names, addresses, tax
              file numbers, etc.
            </li>
            <li>
              <span>Refund Details:</span>Locate information about specific refunds, including
              amounts, dates and processing status.
            </li>
            <li>
              <span>Other Relevant Information:</span>Depending on your search terms, the results
              may also include other relevant information within the TrustEasy System.
            </li>
          </ul>
          <img src={GlobalSearchImg} />
          <div>
            This powerful feature significantly enhances navigation and helps you efficiently locate
            the information you need within the TrustEasy portal.{' '}
          </div>
        </>
      )
    },
    {
      key: 47,
      type: '',
      name: `What are some other ways to enter client info?`,
      text: (
        <>
          <p>
            Aside from retrieving client data directly from your CRM, there are other ways to input
            client information into the system. One such method is encountered during the process
            refund procedure. If a client is not properly registered, you can navigate to the
            process refund page and click on the “New” tab to add the client manually within the
            TrustEasy system.
          </p>
          <img src={OtherwaysClientInfoImg} />
        </>
      )
    },
    {
      key: 48,
      type: '',
      name: `Describe the steps involved in reconciling a discrepancy between the ATO issued tax refund and the refund amount calculated using tax software.`,
      text: (
        <>
          <p>
            TrustEasy provides a simple solution to address discrepancies between the ATO refund and
            the expected refund amount calculated by your tax software.
          </p>
          <ol>
            <li>
              <span>TrustEasy Auto-Populates:</span>TrustEasy automatically populates the tax
              software box with the refund amount received from the ATO.
            </li>
            <li>
              <span>Edit Refund Amount:</span>
              <ul>
                <li>
                  Locate the three-dot menu icon within the tax software box displaying the
                  pre-filled refund amount.
                </li>
                <li>Click on the three-dot menu icon and select “Edit”.</li>
              </ul>
            </li>
            <img src={EditRefundAmountImg} />
            <li>
              <span>Update Refund Value:</span>Modify the existing refund amount with the correct
              value based on the expected refund amount calculated by your tax software.
            </li>
            <img src={UpdateRefundValueImg} />
          </ol>
          <div>
            By following these steps, you can easily adjust the refund amount within TrustEasy to
            ensure accuracy and resolve any discrepancies between the ATO refund and the expected
            refund.
          </div>
        </>
      )
    },
    {
      key: 49,
      type: '',
      name: `How can I undo or correct the mistake made in creating the .aba file?`,
      text: (
        <>
          <p>You can only correct mistakes in the .aba file before it is finalized.</p>
          <ol>
            <li>
              <span>Locate the .ABA Section:</span>Find the .ABA section within TrustEasy. This
              section contains the raw .aba files created during refund processing.
            </li>
            <li>
              <span>Identify the Error:</span>Locate the specific .aba file or line item containing
              the mistake.
            </li>
            <li>
              <span>Click “Undo”:</span>Click the “Undo” button next to the erroneous entry.
            </li>
            <li>
              <span>Return to Reconciliation:</span>This action will return the refund entry to the
              reconciliation page.
            </li>
            <li>
              <span>Correct the Error:</span>Make the necessary corrections to the refund entry on
              the reconciliation page.
            </li>
          </ol>
          <div>
            <span>Important Note:</span>Once the .aba file is finalized and moved to the history,
            you cannot make any changes.
          </div>
        </>
      )
    },
    {
      key: 50,
      type: '',
      name: `Where do I find the reports for the auditors ?`,
      text: (
        <>
          <p>In order to find the reports for the auditors you will need to follow these steps:</p>
          <ol>
            <li>
              <span>Access Reports Section:</span>
              <ul>
                <li>Log in to your TrustEasy account.</li>
                <li>Click on the “Reports” option in the top menu bar.</li>
              </ul>
            </li>
            <li>
              <span>Select Audit Reports:</span>
              <ul>
                <li>
                  Choose the specific report required by your auditors from the available options.
                </li>
                <li>Examples include “Client Statements,””Transaction History,”etc.</li>
              </ul>
            </li>
            <li>
              <span>Customize Reports:</span>
              <ul>
                <li>Specific criteria such as date range, clients, and other relevant filters.</li>
              </ul>
            </li>
            <li>
              <span>Generate Reports:</span>
              <ul>
                <li>Click on the “Export” or “Generate” button. </li>
                <li>Select the desired format (e.g., PDF, Excel)</li>
              </ul>
            </li>
            <li>
              <span>Generate Multiple Client Statements:</span>
              <ul>
                <li>
                  Select multiple clients and export their statements in PDF format for easy review.
                </li>
              </ul>
            </li>
            <img src={ReportsImg} />
          </ol>
          <div>
            By following these steps, you can easily locate and generate the necessary reports for
            your auditors within the TrustEasy system.
          </div>
        </>
      )
    },
    {
      key: 51,
      type: '',
      name: `Is it possible to know when the client record is synced with Access Account Cloud (Salesforce) or Xero Practice Manager(XPM)?`,
      text: (
        <>
          <p>
            To check when a client record was last synced with Access Account Cloud(Salesforce) or
            Xero Practice Manager (XPM), follow these steps:
          </p>
          <ol>
            <li>
              <span>Navigate to the client record:</span>Locate the specific client record within
              the system.
            </li>
            <li>
              <span>Check sync history:</span>Scroll down to the bottom of the client record page.
              You should find a timestamp indication the date and time to the last successful sync
              with the respective platform (Salesforce or XPM).
            </li>
            <img
              src={SyndataImg}
              style={{
                width: '50%'
              }}
            />
          </ol>
          <div>
            This timestamp provides a clear record of the last synchronization event for that
            particular client.
          </div>
        </>
      )
    },
    {
      key: 52,
      type: '',
      name: `How would I know if I am doing it right or if there is an error?`,
      text: (
        <>
          <p>TrustEasy provides several mechanisms to alert you of potential errors or issues:</p>
          <ol>
            <li>
              <span>Error Pop-ups:</span>When an error occurs, a prominent red pop-up notification
              will appear in the top right corner of the screen, clearly indicating the specific
              error encountered.
            </li>
            <li>
              <span>Validation Messages:</span>Throughout the application, TrustEasy incorporates
              validation checks. If you enter incorrect or incomplete information, you will see
              validation messages displayed near the relevant fields or sections, guiding you on how
              to correct the input.
            </li>
            <li>
              <span>Audit Trail:</span>TrustEasy maintains a record of all actions and transactions
              within the system’s audit trail. This can be helpful in investigating and identifying
              the source of an error if it occurs during a particular task.
            </li>
          </ol>
          <div>
            If you encounter any errors or are uncertain about the accuracy of your actions, it’s
            recommended to contact the TrustEasy support team via email or consult this Q&A section
            for assistance.
          </div>
        </>
      )
    },
    {
      key: 53,
      type: '',
      name: `How can I prepay the refund amount without waiting for the ATO refund ? `,
      text: (
        <>
          <p>
            While TrustEasy allows for entering trust payments, it’s crucial to understand the
            implications of prepaying a refund amount before receiving it from the ATO.
          </p>
          <div>Here’s the general process for entering trust payments:</div>
          <ol>
            <li>
              <span>Navigate to “I want to”:</span>In the top navigation panel, locate and click on
              the “I want to” tab.
            </li>
            <li>
              <span>Select “Enter Trust Payments”:</span>Choose the “Enter Trust Payments” option
              from the “I want to” menu.
            </li>
            <li>
              <span>Enter payment details:</span>Fill in the required information, including the
              client’s details and the amount you wish to prepay as a refund.
            </li>
            <li>
              <span>Save the entry:</span>Save the prepayment entry in the system.
            </li>
          </ol>

          <div>
            <span>Important Considetations:</span>
          </div>
          <ul>
            <li>
              <span>Prepayment Risks:</span>Prepaying a refund before receiving it from the ATO
              carries inherent risks. The actual refund amount from the ATO may differ from your
              estimated prepayment. Additionally, there might be outstanding amounts owed to
              government agencies that could impact the final refund amount.{' '}
            </li>
            <li>
              <span>Recommended:</span>It is generally recommended to wait for the ATO to process
              and issue the refund before making any prepayments. This ensures accuracy and
              minimizes potential discrepancies.
            </li>
          </ul>
          <p>
            <span>Disclaimer:</span>This information is for general guidance only and should not be
            considered financial or legal advice. Consult with a qualified professional or relevant
            authorities for specific advice tailored to your situation.
          </p>
          <div>
            <span>Note:</span>These steps provide a general overview. The specific steps and options
            within TrustEasy may vary depending on the version and your specific user permissions.
          </div>
        </>
      )
    },
    {
      key: 54,
      type: '',
      name: `What if the import of .csv is not working for client imports or ATO EFT reconciliation report?`,
      text: (
        <>
          <p>There are two approaches to resolving import issues with .csv files in TrustEasy.</p>
          <ol>
            <li>
              <span>Contact TrustEasy Support:</span>
              <ul>
                <li>This is the recommended course of action for any import problems.</li>
                <li>Email the TrustEasy support team at support@trusteasy.com.au </li>
                <li>
                  Clearly explain the issue you’re facing, specifying whether it’s related to client
                  import or the ATO EFT reconciliation report.
                </li>
                <li>
                  Attach the .csv files you’re trying to import, along with any error messages
                  encountered.
                </li>
                <li>
                  The support team will investigate the issue, potentially request further details,
                  and work towards a solution.
                </li>
                <li>Once resolved, they may provide fixed .csv files for successful import.</li>
              </ul>
            </li>
            <li>
              <span>Important Note for Xero Practice Manager (XPM) Client Data:</span>
              <ul>
                <li>
                  If you’re importing client data from XPM, be aware that TrustEasy can only see the
                  last three digits of Tax File Number(TFNs) due to XPM masking.
                </li>
                <li>
                  To address this, you will need to manually update each TFN within TrustEasy after
                  import.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            <span>Additional Tips:</span>
            <ul>
              <li>Ensure the .csv files adhere to the TrustEasy import template format.</li>
              <li>
                Verify that the data within the files is properly formatted to match TrustEasy’s
                expectations.
              </li>
            </ul>
          </p>
          <div>
            By following these steps and considering the XPM data import caveat, you should be able
            to effectively troubleshoot and resolve import issues with .csv files in TrustEasy.
          </div>
        </>
      )
    }
  ];

  const updateHelpDefaultActive = (newData) => {
    navigate('/help');
    setHelpDefaultActive(newData);
  };

  const getHelpCenterObjectByType = (type) => {
    return helpCenterArray?.filter((item) => item?.type === type);
  };

  // Define the context value object
  const contextValue = {
    helpDefaultActive,
    updateHelpDefaultActive,
    helpCenterArray,
    getHelpCenterObjectByType
  };

  return <HelpCenterContext.Provider value={contextValue}>{children}</HelpCenterContext.Provider>;
}

// Custom hook to use the HelpCenterContext
export function useHelpCenter() {
  const context = useContext(HelpCenterContext);
  if (!context) {
    throw new Error('useHelpCenter must be used within a HelpCenterProvider');
  }
  return context;
}
