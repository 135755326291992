import React, { useCallback, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import '../../App.css';
import '../../assets/css/style.css';
import './refund.module.css';
import { Button, Col, Container, Form, Modal, Row, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, fileUpload, get, post, putCall } from '../../config';
import AddTaxRefund from '../tax/AddTaxRefund';
import TaxSearch from '../tax/TaxSearch';
import { toast } from 'react-toastify';
import AddCommentReview from './AddCommentReview';
import AuthorityLetterModal from '../customer/AuthorityLetterModal';
import DialogModal from '../../components/Header/DialogModal';
import { Link, useNavigate } from 'react-router-dom';
// import { helpCenterArray } from '../HelpCenter';
import InfinityScrollComponent from '../dashboard/InfinityScrollComponent';
import EditAtoRefund from '../tax/EditAtoRefund';
import { formatNumber } from '../../APIConfig/Interceptor';
import { Spin } from 'antd';
import { useHelpCenter } from '../../shared/HelpCenterContext';

const ProcessRefund = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { getHelpCenterObjectByType } = useHelpCenter();
  const [show, setShow] = useState(false);
  const [reconcileid, setReconcileid] = useState([]);
  const [showView, setShowView] = useState(false);
  const [selectedReviewItem, setSelectedReviewItem] = useState('');
  const [showComment, setShowComment] = useState(false);
  const [accountId, setAccountId] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [matchSelectedItems, setMatchSelectedItems] = useState([]);
  const [showSearchIndexs, setShowSearchIndexs] = useState([]);
  const [sendReviewItems, setSendReviewItems] = useState([]);
  const [taxSoftInfo, setTaxSoftInfo] = useState({});
  const [descErrors, setDescErrors] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [commentValue, setCommentValue] = useState('');
  const [customerDetails, setCustomerDetails] = useState({});
  const [noteErrors, setNoteErrors] = useState({});

  const [firmData, setFirmData] = useState();

  const [hasMore, setHasMore] = useState(true);
  const [lastElement, setLastElement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [isRefundAllLoading, setIsRefundAllLoading] = useState(false);

  var matchedRecords = [];

  const getFirmData = useCallback(async () => {
    try {
      const { data } = await get(API.GET_FIRM_INFO);
      const firmObj = data.data;
      setFirmData(firmObj);
    } catch (e) {
      setFirmData({});
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }, []);

  useEffect(() => {
    getFirmData();
  }, [getFirmData]);

  const printauthority = (id) => {
    setReconcileid(id);
    setShowView(!showView);
  };
  const togglePrintModal = () => {
    setShowView(!showView);
  };
  const handleClose = () => {
    setShow(false);
    getProcessData();
  };

  const handleCommentClose = () => {
    setCommentValue('');
    setShowComment(false);
  };

  const handleSearchClose = (isUpdate = false) => {
    setShowSearchIndexs([-1]);
    if (isUpdate) {
      getProcessData();
    }
  };

  useEffect(() => {
    getProcessData();
  }, []);

  const getNextPageData = async (page) => {
    try {
      const { data } = await get(`${API.PROCESS_REFUND}?limit=10&page=${page}`);
      return data?.data;
    } catch (e) {
      return e;
    }
  };
  const getProcessData = async () => {
    setIsRefundAllLoading(true);
    await get(`${API.PROCESS_REFUND}?limit=${currentPage * 10}&page=1`)
      .then((response) => {
        setIsRefundAllLoading(false);
        setData(response.data.data);
        if (response.data.data.per_page <= 10) {
          setCurrentPage(response.data.data.current_page);
        }
        const isMoreDataExist =
          response.data.data.data.length < response.data.data.total &&
          response.data.data?.next_page_url !== '' &&
          response.data.data?.next_page_url !== null;
        setHasMore(isMoreDataExist);

        setAllChecked(true);
      })
      .catch((e) => {
        setIsRefundAllLoading(false);
        setData([]);
        const errorMessage = e.response?.data?.errors?.myna_error[0];
        if (errorMessage) {
          toast.error(errorMessage);
        }
      });
  };

  useEffect(() => {
    data?.data?.length > 0 &&
      data?.data?.forEach((element) => {
        if (element.is_matched && element?.remittance > 0) {
          if (
            element?.customer?.customer_bank?.account_name &&
            element?.customer?.customer_bank?.account_number &&
            element?.customer?.customer_bank?.bsb
          ) {
            matchedRecords.push(element.id);
          }
        }
      });
    setMatchSelectedItems(matchedRecords);
    setAllChecked(true);
    setSelectedItems(matchedRecords);
  }, [data]);

  const loadMore = async () => {
    setIsLoading(true);
    const nextPageData = await getNextPageData(currentPage + 1);

    const newObj = {
      ...nextPageData,
      data: [...data.data, ...nextPageData.data]
    };

    setData(newObj);
    setCurrentPage(newObj.current_page);
    setIsLoading(false);
    const isMoreDataExist =
      newObj.data.length < newObj.total &&
      nextPageData?.next_page_url !== '' &&
      nextPageData?.next_page_url !== null;
    setHasMore(isMoreDataExist);
  };

  const showCommentbox = (id, item) => {
    setSelectedReviewItem(item);
    setShowComment(true);
  };

  const sendReview = (comment) => {
    const id = selectedReviewItem?.id;
    const selectedATONote = data?.data?.find((item) => item?.id === id)?.ato?.note
      ? data?.data?.find((item) => item?.id === id)?.ato?.note
      : '';

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('send_for_review', '1');
    formData.append('comment', comment);
    formData.append(
      'description',
      selectedReviewItem?.description ? selectedReviewItem?.description : ''
    );
    formData.append(
      'invoice_number',
      selectedReviewItem?.invoice_number ? selectedReviewItem?.invoice_number : ''
    );
    formData.append('note', selectedATONote ? selectedATONote : '');
    fileUpload(API.MATCH_UNMATCH_REVIEW + id, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.status == '200') {
          setSendReviewItems([...sendReviewItems, id]);
          handleCommentClose();
          getProcessData();
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };

  const matchData = (id, item) => {
    post(API.MATCH_UNMATCH_REVIEW + id, {
      match: '1',
      description: item?.description ? item?.description : '',
      invoice_number: item?.invoice_number ? item?.invoice_number : '',
      _method: 'PUT'
    })
      .then((response) => {
        if (response.data.status == '200') {
          handleClose();
          getProcessData();
          toast.success(response.data.message);
        }
      })
      .catch(() => {
        var error = {};
        error['myna_error'] = 'There is some issue with request, please try after some time.';
      });
  };

  const unMatchData = (id, item) => {
    post(API.MATCH_UNMATCH_REVIEW + id, {
      match: '0',
      description: item?.description ? item?.description : '',
      invoice_number: item?.invoice_number ? item?.invoice_number : '',
      _method: 'PUT'
    })
      .then((response) => {
        if (response.data.status == '200') {
          handleClose();
          getProcessData();
          toast.success(response.data.message);
        }
      })
      .catch(() => {
        var error = {};
        error['myna_error'] = 'There is some issue with request, please try after some time.';
      });
  };

  const processRefund = (id, index) => {
    const selectedItem = data.data.find((el) => el.id == id);
    const description = selectedItem?.description;
    const invoice_number = selectedItem?.invoice_number;

    let newData = data.data;

    const note = selectedItem?.ato?.note ? selectedItem?.ato?.note : '';
    if (description && description.length >= 4 && description.length <= 18) {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('process_refund', '1');
      formData.append('description', description);
      formData.append('invoice_number', invoice_number ? invoice_number : '');
      formData.append('note', note ? note : '');
      if (index > -1) {
        newData[index] = { ...newData[index], loading: true };
      }
      setData({ ...data, data: [...data.data] });
      fileUpload(API.MATCH_UNMATCH_REVIEW + id, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(async (response) => {
          if (response.data.status == '200') {
            handleClose();
            if (index > -1) {
              newData[index] = { ...newData[index], loading: false };
            }
            setData({ ...data, data: [...data.data] });
            getProcessData();

            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          if (index > -1) {
            newData[index] = { ...newData[index], loading: false };
          }
          setData({ ...data, data: [...data.data] });

          const { data } = error.response;
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        });
    } else {
      toast.error(`Please enter valid Description`);
    }
  };

  const processRefundAll = () => {
    if (selectedItems.length == 0) {
      toast.warning('Please select atleast one process refund');
    } else {
      const selectedFinalData = selectedItems.map((item) => {
        const dataItem = data.data.find((ele) => ele.id == item);
        if (dataItem) {
          return dataItem;
        } else {
          toast.error('Something went wrong');
        }
      });
      if (selectedFinalData.length > 0) {
        let isFormDataValidate = true;
        const formData = new FormData();
        selectedFinalData.map((itemData) => {
          if (
            itemData.description &&
            itemData.description.length >= 4 &&
            itemData.description.length <= 18
          ) {
            formData.append('recon_ids[]', itemData.id);
            formData.append('recon_desc[]', itemData.description);
            formData.append('recon_note[]', itemData?.ato?.note ? itemData?.ato?.note : '');
          } else {
            handleDescriptionErrors(itemData.id);
            toast.error(
              `Please Enter Valid Description in ${itemData?.customer?.customer_bank?.account_name} account name`
            );
            isFormDataValidate = false;
          }
        });
        if ([...formData.entries()].length && isFormDataValidate) {
          setIsRefundAllLoading(true);
          fileUpload(API.MULTI_PROCESS_REFUND, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.data.status == '200') {
                setIsRefundAllLoading(false);
                toast.success(response.data.message);
                if (data.data != 0) {
                  getProcessData();
                } else {
                  navigate('/aba?page=1&tab=generate-aba');
                }
              }
            })
            .catch((error) => {
              setIsRefundAllLoading(false);
              const { data } = error.response;
              toast.error(data?.message);
              toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
            });
        }
      }
    }
    const errorInputRefFocus = window.document.getElementById(Object.keys(descErrors)[0]);

    errorInputRefFocus && errorInputRefFocus.scrollIntoView();
  };

  const handleAllCheckBox = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    if (itemName === 'checkall') {
      setAllChecked(checked);
      if (checked) {
        const allData = [];
        data.data.forEach((item) => {
          if (item.is_matched && item?.remittance > 0) {
            if (
              item?.customer?.customer_bank?.account_name &&
              item?.customer?.customer_bank?.account_number &&
              item?.customer?.customer_bank?.bsb
            ) {
              allData.push(item.id);
            }
          }
        });
        if (!allData.length) {
          setAllChecked(false);
          toast.warning('There is no entry to process refund.');
        }
        setSelectedItems(allData);
      } else {
        setSelectedItems([]);
      }
    }
  };

  const handleCheckBox = (id) => {
    const oldIndex = [...selectedItems];
    if (selectedItems.indexOf(id) === -1) {
      oldIndex.push(id);
    } else {
      oldIndex.splice(selectedItems.indexOf(id), 1);
    }
    if (oldIndex.length === matchSelectedItems.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setSelectedItems(oldIndex);
  };

  const handleDescription = (e) => {
    const { id, value } = e.target;
    let newData = [...data.data];
    let index = newData.findIndex((el) => el.id == id);

    if (index > -1) {
      newData[index] = { ...newData[index], description: value };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleCommentFinish = (values) => {
    setCommentValue(values.comment);
    sendReview(values.comment);
  };

  const handleDescriptionErrors = (id, invoice_number) => {
    let errors = descErrors;
    const item = data.data.find((el) => el.id == id);
    let regex = new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/);
    if (item) {
      const checkDescription = regex.exec(item.description) ?? false;
      if (
        item.description == '' ||
        item.description == null ||
        item.description.toString().length < 4 ||
        checkDescription === false
      ) {
        errors[id] = 'Enter Valid Description';
      } else {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('status', item?.status);
        formData.append('description', item.description ? item.description : '');
        formData.append('invoice_number', invoice_number ? invoice_number : '');
        formData.append('note', item?.ato?.note ? item?.ato?.note : '');
        fileUpload(API.MATCH_UNMATCH_REVIEW + id, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.data.status == '200') {
              handleClose();
              getProcessData();
              toast.success(response.data.message);
            }
          })
          .catch((error) => {
            const { data } = error.response;
            toast.error(data?.message);
            toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          });
        delete errors[id];
      }
    } else {
      toast.error('Something went wrong.');
    }
    setDescErrors({ ...errors });
  };

  const handleNote = (e) => {
    const { id, value } = e.target;
    let newData = [...data.data];
    let index = newData.findIndex((el) => el.id == id);
    if (index > -1) {
      newData[index].ato = { ...newData[index].ato, note: value };
      setData({ ...data, ...{ data: newData } });
    }
  };

  const handleNoteErrors = (id) => {
    let errors = noteErrors;
    const item = data.data.find((el) => el.id == id);
    let regex = new RegExp(/^[^!@#)(^%<>][a-zA-Z\s\d.,/$&-]*$/);
    if (item) {
      const checkNote = regex.exec(item?.ato?.note) ?? false;
      if (item?.ato?.note && checkNote === false) {
        errors[id] = 'Enter Valid Note';
      } else {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('status', item?.status);
        formData.append('description', item.description ? item.description : '');
        formData.append('invoice_number', item?.invoice_number ? item?.invoice_number : '');
        formData.append('note', item?.ato?.note ? item?.ato?.note : '');
        fileUpload(API.MATCH_UNMATCH_REVIEW + id, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.data.status == '200') {
              handleClose();
              getProcessData();
              toast.success(response.data.message);
            }
          })
          .catch((error) => {
            const { data } = error.response;
            toast.error(data?.message);
            toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          });
        delete errors[id];
      }
    } else {
      toast.error('Something went wrong.');
    }
    setNoteErrors({ ...errors });
  };

  const getClassName = useCallback((item) => {
    if (item?.is_matched == 1 && item?.remittance >= 0) return 'refund-process-box matched';
    if (item?.is_matched == 1 && item.remittance <= 0) return 'refund-process-box red';
    return 'refund-process-box';
  }, []);

  const [showEdit, setShowEdit] = useState(false);
  const [editAtoData, setEditAtoData] = useState(false);

  const handleCloseEdit = () => {
    setShowEdit(!showEdit);
  };

  const handleEditAto = (item) => {
    setEditAtoData(item);
    setShowEdit(!showEdit);
  };

  const handlePrice = async (id, value) => {
    let newData = [...data.data];
    let index = newData.findIndex((el) => el.id == id);
    if (index > -1) {
      newData[index].tax_software = {
        ...newData[index].tax_software,
        invoice_amount: value,
      };

      setData({ ...data, ...{ data: newData } });
    }
  };

  const updatePrice = async (value, id) => {
    let values = {
      fee: value && value > 0 ? value : 0,
      id: id
    };
    const { data } = await post(API.PROCESS_REFUND_UPDATE_FEES, values);
    if (data.status === 200) {
      getProcessData();
      toast.success(data.message);
    } else {
      toast.error(data?.message);
    }
  };

  const handleInvoiceNumber = async (id, value) => {
    let newData = [...data.data];
    let index = newData.findIndex((el) => el.id == id);
    if (index > -1) {
      newData[index] = {
        ...newData[index],
        invoice_number: value ? value : ''
      };

      setData({ ...data, ...{ data: newData } });
    }
  };

  const updateInvoiceNumber = async (value, id, description, note) => {
    let values = {
      invoice_number: value ? value : '',
      description: description ? description : '',
      note: note ? note : ''
    };
    const { data } = await putCall(`${API.PROCESS_REFUND}/${id}`, values);
    if (data.status === 200) {
      getProcessData();
      toast.success(data.message);
    } else {
      toast.error(data?.message);
    }
  };

  let helpTextArray = getHelpCenterObjectByType('Process Refund Bulk');

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          {isRefundAllLoading && (
            <div
              style={{
                position: 'absolute',
                zIndex: 3,
                background: '#dbdbdb40',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 114px)',
                width: 'calc(100vw - 120px)'
              }}
            >
              <Spin />
            </div>
          )}
          <h1 className="page-title">
            <BackButton />
            Process Refund In Bulk {/* Process Reconciliation */}
            <DialogModal title="Process Refund Bulk" helpTextArray={helpTextArray} />
          </h1>
          <InfinityScrollComponent
            hasMore={hasMore}
            isLoading={isLoading}
            loadMore={loadMore}
            loadOnMount={true}
            lastItemRef={lastElement}
          >
            <div className="content-details refund-process">
              <div className="table-top-btn">
                <h2>Total Refunds ready to be Processed: {data?.data?.length || 0}</h2>
                <div className="table-btn">
                  <Button
                    variant="primary"
                    disabled={!data?.data?.length}
                    onClick={() => {
                      processRefundAll();
                    }}
                  >
                    Process Refund
                  </Button>
                </div>
              </div>
              <div style={{ width: '100%', height: '89%' }}>
                <div id="grid-wrapper" style={{ width: '100%', height: '100%' }}>
                  <div id="myGrid" className="ag-theme-alpine ato-grid">
                    <Form>
                      {data?.data && data?.data.length > 0 && (
                        <Form.Check
                          type="checkbox"
                          label={
                            'Select All ' +
                            (selectedItems.length > 0 ? `(${selectedItems.length})` : '')
                          }
                          name="checkall"
                          id="checkall"
                          checked={allChecked}
                          onChange={(e) => handleAllCheckBox(e)}
                        ></Form.Check>
                      )}
                      <div className="form-box">
                        {data?.data &&
                          data?.data?.length > 0 &&
                          data?.data?.map((item, index) => {
                            if (sendReviewItems.indexOf(item.id) >= 0) {
                              return null;
                            }
                            const total =
                              Number(
                                item?.tax_software?.invoice_amount
                                  ? item?.tax_software?.invoice_amount
                                  : 0
                              ) + Number(item?.tax_software?.disbursement);
                            return (
                              <div
                                id={item.id}
                                key={index}
                                ref={setLastElement}
                                className={getClassName(item)}
                                style={{
                                  position: 'relative'
                                }}
                              >
                                {item?.loading && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      zIndex: 3,
                                      background: '#dbdbdb40',
                                      height: '100%',
                                      width: '100%',
                                      margin: '-15px',
                                      borderRadius: '30px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Spin />
                                  </div>
                                )}
                                <Container>
                                  <Row className="refund-top-details">
                                    <Col xs={6} md={2} className="refund-checkbox">
                                      <Form.Check
                                        type="checkbox"
                                        disabled={
                                          // eslint-disable-next-line no-nested-ternary
                                          item?.customer?.customer_bank?.account_name &&
                                          item?.customer?.customer_bank?.account_number &&
                                          item?.customer?.customer_bank?.bsb
                                            ? item?.is_matched == 0 || item?.remittance <= 0
                                              ? true
                                              : false
                                            : true
                                        }
                                        checked={selectedItems.indexOf(item.id) >= 0}
                                        onChange={() => {
                                          handleCheckBox(item.id);
                                        }}
                                      ></Form.Check>
                                    </Col>
                                    <Col xs={6} md={2}>
                                      <strong>Account Name</strong>{' '}
                                      {item?.customer?.customer_bank?.account_name}
                                    </Col>
                                    <Col xs={6} md={2}>
                                      <strong>CRN</strong>
                                      {item?.customer?.crn ? item?.customer?.crn : '-'}
                                    </Col>
                                    <Col xs={6} md={2}>
                                      <strong>BSB</strong>
                                      {item?.customer?.customer_bank?.bsb
                                        ? item?.customer?.customer_bank?.bsb?.toString()
                                        : ''}
                                    </Col>
                                    <Col xs={6} md={2}>
                                      <strong>Account Number</strong>{' '}
                                      {item?.customer?.customer_bank?.account_number
                                        ? item?.customer?.customer_bank?.account_number?.toString()
                                        : ''}
                                    </Col>
                                    {item?.is_matched == 1 ? (
                                      <Col xs={6} md={2} className="price-box">
                                        <strong>Net Remittance</strong> $
                                        {`${formatNumber((item?.ato?.refund || 0) - total) || 0}`}
                                      </Col>
                                    ) : null}
                                    {item?.is_matched == 1 ? (
                                      <Col xs={6} md={2}>
                                        <strong>Description on Statement</strong>
                                        <Form.Control
                                          type="text"
                                          id={item.id}
                                          required
                                          minLength={4}
                                          maxLength={18}
                                          value={item.description ? item.description : ''}
                                          onChange={handleDescription}
                                          rules={[
                                            {
                                              pattern: new RegExp(
                                                /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                              ),
                                              message:
                                                'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                                            }
                                          ]}
                                          onBlur={() =>
                                            handleDescriptionErrors(item.id, item?.invoice_number)
                                          }
                                          placeholder={item.description ? item.description : ''}
                                        ></Form.Control>
                                        {Object.prototype.hasOwnProperty.call(
                                          descErrors,
                                          item.id
                                        ) && (
                                          <p className="text-danger">{`${descErrors[item.id]}`}</p>
                                        )}
                                      </Col>
                                    ) : null}
                                  </Row>
                                  {item?.customer?.customer_bank?.account_name &&
                                  item?.customer?.customer_bank?.account_number &&
                                  item?.customer?.customer_bank?.bsb ? (
                                    <></>
                                  ) : (
                                    <Row style={{ marginTop: '-60px' }}>
                                      <Col style={{ width: '5%' }} xs={2}></Col>
                                      <Col>
                                        <div
                                          style={{
                                            display: 'flex',
                                            gap: '15px',
                                            margin: '15px 0px'
                                          }}
                                        >
                                          <p className="text-danger">
                                            Client does not have bank details
                                          </p>
                                          <Link
                                            to={{
                                              pathname: `/customer/edit`,
                                              search: `?id=${item?.customer?.id}`
                                            }}
                                          >
                                            Update bank details
                                          </Link>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                  <Container>
                                    <Row className="refund-card-box">
                                      <Col xs={6} md={5}>
                                        <div className="refund-card">
                                          {item && (
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <FontAwesomeIcon icon={faEllipsisV} size="1x" />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                {item?.status !== '2' && (
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      showCommentbox(item.id, item);
                                                    }}
                                                  >
                                                    Send For Review
                                                  </Dropdown.Item>
                                                )}
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    handleEditAto(item);
                                                  }}
                                                >
                                                  Edit
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          )}
                                          <label>ATO Refund </label>
                                          <div className="refund-card-content">
                                            <div className="left-block">
                                              <p className="name">
                                                <strong>Name:</strong> {item?.ato?.name}
                                              </p>
                                              <p>
                                                <strong>Year:</strong>{' '}
                                                {item?.ato?.year ? item?.ato?.year : ''}
                                              </p>
                                            </div>
                                            <div className="right-block">
                                              <p className="rightalign">
                                                <strong>ATO Refund Amount:</strong>
                                                {`$${formatNumber(item?.ato?.refund) || 0}`}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        {item.comment && item.is_matched ? (
                                          <Row className="refund-top-details">
                                            <Col>
                                              <label>Review Comment</label>{' '}
                                              <Form.Control
                                                type="text"
                                                id={item.id}
                                                value={item.comment ? item.comment : ''}
                                              ></Form.Control>{' '}
                                            </Col>
                                          </Row>
                                        ) : (
                                          ''
                                        )}

                                        <Row className="refund-top-details">
                                          <Col>
                                            <label>File Note</label>
                                            <Form.Control
                                              type="text"
                                              id={item.id}
                                              required
                                              maxLength={150}
                                              value={item?.ato?.note ? item?.ato?.note : ''}
                                              onChange={handleNote}
                                              rules={[
                                                {
                                                  pattern: new RegExp(
                                                    /^[^!@#)(^%$<>][a-zA-Z\s\d.,/$&-]*$/
                                                  ),
                                                  message:
                                                    'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/),($) & spaces.'
                                                }
                                              ]}
                                              onBlur={() => handleNoteErrors(item.id)}
                                              placeholder={item?.ato?.note ? item?.ato?.note : ''}
                                            ></Form.Control>
                                            {Object.prototype.hasOwnProperty.call(
                                              noteErrors,
                                              item.id
                                            ) && (
                                              <p className="text-danger">{`${
                                                noteErrors[item.id]
                                              }`}</p>
                                            )}
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col xs={6} md={2} className="matching-btn-section">
                                        {item?.is_matched == 1 ? (
                                          <Button
                                            variant="secondary"
                                            className="matching-btn"
                                            size="sm"
                                            onClick={() => {
                                              unMatchData(item.id, item);
                                            }}
                                          >
                                            {' '}
                                            Unmatch{' '}
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="secondary"
                                            className="matching-btn"
                                            disabled={item.tax_software == null}
                                            size="sm"
                                            onClick={() => {
                                              matchData(item.id, item);
                                            }}
                                          >
                                            {' '}
                                            Match
                                          </Button>
                                        )}
                                      </Col>
                                      <Col xs={6} md={5}>
                                        <div className="refund-card">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <FontAwesomeIcon icon={faEllipsisV} size="1x" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              {item?.tax_software == null ? (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    setAccountId(item.id);
                                                    setTaxSoftInfo({});
                                                    setShow(true);
                                                    if (
                                                      item?.customer &&
                                                      Object.keys(item?.customer).length
                                                    ) {
                                                      setCustomerDetails({
                                                        customerId: item?.customer?.id,
                                                        customerFirstName:
                                                          item?.customer?.first_name,
                                                        customerLastName: item?.customer?.last_name
                                                      });
                                                    }
                                                  }}
                                                >
                                                  Create
                                                </Dropdown.Item>
                                              ) : null}

                                              {item && item?.status !== '2' && (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    showCommentbox(item.id, item);
                                                  }}
                                                >
                                                  Send For Review
                                                </Dropdown.Item>
                                              )}

                                              {item?.tax_software ? (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    setAccountId(item.id);
                                                    setTaxSoftInfo({
                                                      ...item.tax_software,
                                                      customer_id: item.customer_id,
                                                      disbursement: item.disbursement
                                                    });
                                                    setShow(true);
                                                  }}
                                                >
                                                  Edit
                                                </Dropdown.Item>
                                              ) : null}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <label>Tax Software/Invoice Entry</label>
                                          {item?.tax_software ? (
                                            <div className="refund-card-content">
                                              <div className="left-block">
                                                <p className="name">
                                                  <strong>Name:</strong>{' '}
                                                  {item?.tax_software?.first_name}{' '}
                                                  {item?.tax_software?.last_name}
                                                </p>
                                                <p>
                                                  <strong>Year:</strong> {item?.tax_software?.year}
                                                </p>
                                              </div>
                                              <div className="right-block">
                                                <p className="rightalign">
                                                  <strong>
                                                    Refund as per{' '}
                                                    {item?.tax_software?.tax_software
                                                      ? item?.tax_software?.tax_software
                                                      : firmData?.tax_software}
                                                    :
                                                  </strong>
                                                  {`$${
                                                    formatNumber(item?.tax_software?.refund) || 0
                                                  }`}
                                                </p>
                                                {item?.is_matched == 1 ? (
                                                  <p className="rightalign">
                                                    <strong>Fee(Inc. GST):</strong>
                                                    <CurrencyInput
                                                      prefix="$"
                                                      style={{
                                                        width: '40%',
                                                        borderRadius: '15px',
                                                        textAlign: 'center',
                                                        borderWidth: '1px',
                                                        borderColor:
                                                          parseFloat(
                                                            item?.tax_software?.invoice_amount
                                                          ) >= 0
                                                            ? 'black'
                                                            : 'red'
                                                      }}
                                                      id="input-example"
                                                      name="input-name"
                                                      defaultValue={
                                                        item?.tax_software?.invoice_amount &&
                                                        item?.tax_software?.invoice_amount > 0
                                                          ? item?.tax_software?.invoice_amount
                                                          : item?.tax_software?.invoice_amount
                                                      }
                                                      value={item?.tax_software?.invoice_amount}
                                                      decimalsLimit={2}
                                                      onValueChange={(value) =>
                                                        handlePrice(item.id, value)
                                                      }
                                                      allowNegativeValue={false}
                                                      onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                          updatePrice(
                                                            item?.tax_software?.invoice_amount,
                                                            item.id
                                                          );
                                                        }
                                                      }}
                                                      onBlur={() =>
                                                        updatePrice(
                                                          item?.tax_software?.invoice_amount,
                                                          item.id
                                                        )
                                                      }
                                                    />
                                                  </p>
                                                ) : null}

                                                {item?.is_matched == 1 ? (
                                                  <p className="rightalign">
                                                    <strong>Disbursement:</strong>
                                                    {`$${
                                                      formatNumber(
                                                        item?.tax_software?.disbursement
                                                      ) || 0
                                                    }`}
                                                  </p>
                                                ) : null}

                                                {item?.is_matched == 1 ? (
                                                  <p className="rightalign">
                                                    <b>
                                                      Total Fee:
                                                      <span>{`$${formatNumber(total) || 0}`}</span>
                                                    </b>
                                                  </p>
                                                ) : null}
                                              </div>
                                              {item?.is_matched == 1 ? (
                                                <p className="rightalign invoice_number_input">
                                                  <strong>Invoice No:</strong>
                                                  <Form.Control
                                                    type="text"
                                                    id={item.id}
                                                    required
                                                    value={
                                                      item?.invoice_number
                                                        ? item?.invoice_number
                                                        : ''
                                                    }
                                                    onChange={(e) =>
                                                      handleInvoiceNumber(item.id, e?.target?.value)
                                                    }
                                                    onBlur={() =>
                                                      updateInvoiceNumber(
                                                        item?.invoice_number,
                                                        item.id,
                                                        item?.description,
                                                        item?.ato?.note
                                                      )
                                                    }
                                                    placeholder={'Enter Invoice No.'}
                                                  ></Form.Control>
                                                </p>
                                              ) : null}
                                              {item?.is_matched == 1 ? (
                                                <p className="rightalign remittance">
                                                  <strong>Remittance:</strong>
                                                  {`$${
                                                    formatNumber(
                                                      (item?.ato?.refund || 0) - total
                                                    ) || 0
                                                  }`}
                                                </p>
                                              ) : null}
                                            </div>
                                          ) : (
                                            <div className="refund-card-content">
                                              <div className="left-block">
                                                <p className="name">
                                                  <strong>Name:</strong>
                                                </p>
                                                <p>
                                                  <strong>Year:</strong>
                                                </p>
                                              </div>
                                              <div className="right-block">
                                                <p className="rightalign">
                                                  <strong>
                                                    Refund as per{' '}
                                                    {item?.tax_software?.tax_software
                                                      ? item?.tax_software?.tax_software
                                                      : firmData?.tax_software}
                                                    :
                                                  </strong>
                                                  {`$${item?.tax_software?.refund || 0}`}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                          {item?.is_matched == 1 ? (
                                            <div className="table-btn">
                                              <Button
                                                variant="link"
                                                size="sm"
                                                onClick={() => {
                                                  printauthority(item.id);
                                                }}
                                              >
                                                Print Authority Letter
                                              </Button>
                                              {item?.remittance < 0 ? (
                                                <Button
                                                  variant="primary"
                                                  size="sm"
                                                  onClick={() => {
                                                    showCommentbox(item.id, item);
                                                  }}
                                                >
                                                  Send For Review
                                                </Button>
                                              ) : (
                                                <Button
                                                  variant="primary"
                                                  size="sm"
                                                  onClick={() => {
                                                    processRefund(item.id, index, data);
                                                  }}
                                                  disabled={
                                                    item?.customer?.customer_bank?.account_name &&
                                                    item?.customer?.customer_bank?.account_number &&
                                                    item?.customer?.customer_bank?.bsb
                                                      ? false
                                                      : true
                                                  }
                                                >
                                                  Process Refund
                                                </Button>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                    {showSearchIndexs.indexOf(item.id) !== -1 ? (
                                      <TaxSearch ItemId={item.id} close={handleSearchClose} />
                                    ) : null}
                                  </Container>
                                </Container>
                              </div>
                            );
                          })}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </InfinityScrollComponent>
          <Footer />
          <Modal
            show={showEdit}
            onHide={handleCloseEdit}
            dialogClassName="modal-50w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>Edit ATO Refund</Modal.Header>
            <Modal.Body>
              <EditAtoRefund
                getProcessData={getProcessData}
                editAtoData={editAtoData}
                onClose={handleCloseEdit}
              />
              <Button variant="secondary" className="reset" onClick={handleCloseEdit}>
                Cancel
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-50w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>Tax Software</Modal.Header>
            <Modal.Body>
              <AddTaxRefund
                accountId={accountId}
                customerDetails={customerDetails}
                taxSoftInfo={taxSoftInfo}
                onClose={handleClose}
              />
              <Button variant="secondary" className="reset" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={showComment}
            onHide={handleCommentClose}
            dialogClassName="modal-50w medium-popup review-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>Send For Review</Modal.Header>
            <Modal.Body>
              <AddCommentReview
                commentValue={commentValue}
                handleCommentFinish={handleCommentFinish}
              />
              <Button variant="secondary" className="reset" onClick={handleCommentClose}>
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      {showView && (
        <AuthorityLetterModal
          id={reconcileid}
          togglePrintModal={togglePrintModal}
          screenView="processRefund"
        />
      )}
    </div>
  );
};

export default ProcessRefund;
