import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, Input, Modal, Select } from 'antd';
import Footer from '../../components/Footer/Index';
import {
  abaTransactionTableColumns,
  getAbaTransactionTableInnerColumns,
  getAbaTransactionTableInnerColumns1
} from './ABAHelper';
import useABATransactionList from './useABATransactionList';
import AbaTransactionPopup from './AbaTransactionPopup';
import { API, defaultPaginationLimit, fileUpload, get } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import ABATableComponent from './ABATableComponent';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const { RangePicker } = DatePicker;
// const { Option } = Select;
const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

const getDefaultDates = (from, to) => {
  if (from && to) {
    return { start_date: from, end_date: to };
  } else {
    const previousYear =
      new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
    const previousYearDate = `01-04-${previousYear}`;
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    const year = currentTime.getFullYear();
    const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
      Number(month),
      2
    )}-${year}`;

    return { start_date: previousYearDate, end_date: currentDate };
  }
};

const ABATransactions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPage = query.get('page') ? query.get('page') : 1;
  const selectedTab = query.get('tab');
  const search = query.get('search');
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [preview, setPreview] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const from = query.get('from');
  const to = query.get('to');
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [dateFilter, setDateFilter] = useState(() => getDefaultDates(from, to));
  const [dateFilterModal, setDateFilterModal] = useState(() => getDefaultDates(from, to));
  const [exportLoading, setExportLoading] = useState(false);

  const {
    ABATransactions,
    isDataLoading,
    onPageChange,
    pagingData,
    onChange,
    searchParam,
    fetchABATransactions,
    setIsDataLoading,
    handleSortChange
  } = useABATransactionList(currentPage, search, limit, dateFilter);

  useEffect(() => {
    setLimit(query.get('limit') ?? defaultPaginationLimit);
  }, [query.get('limit')]);

  useEffect(() => {
    if (selectedTab === 'aba-transactions') fetchABATransactions(limit ?? query.get('limit'), currentPage, searchParam, dateFilter?.start_date, dateFilter?.end_date);
  }, [selectedTab, fetchABATransactions]);

  const handleChange = async (value) => {
    setLimit(value);
    const { start_date, end_date } = dateFilter;
    navigate(`/aba?page=1&tab=aba-transactions&limit=${value}`, {
      replace: true
    });
    fetchABATransactions(value, 1, searchParam, start_date, end_date);
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilterModal({
        start_date: startDate,
        end_date: endDate
      });
      // navigate(
      //   `/aba?search=${searchParam}&tab=aba-transactions&page=1&from=${startDate}&to=${endDate}&limit=${limit}`,
      //   {
      //     replace: true
      //   }
      // );
    } else {
      setDateFilterModal({
        start_date: '',
        end_date: ''
      });
    }
  };

  const onFilterOk = () => {
    const { start_date, end_date } = dateFilterModal;
    setDateFilter({
      start_date: start_date,
      end_date: end_date
    });
    navigate(
      `/aba?search=${searchParam}&tab=aba-transactions&page=1&from=${start_date}&to=${end_date}&limit=${limit}`,
      {
        replace: true
      }
    );
    fetchABATransactions(limit, 1, searchParam, start_date, end_date);
    setDatePickerVisible(false);
  };

  const handleRefreshButton = () => {
    fetchABATransactions(limit, currentPage);
  };

  const handleUploadedStatusABA = async (id, uploaded = 0) => {
    try {
      setIsDataLoading(true);
      const formData = new FormData();
      formData.append('uploaded', uploaded == 0 ? 1 : 0);
      formData.append('_method', 'PUT');
      const { data } = await fileUpload(`${API.ABA_FILE_LIST}/${id}`, formData);
      if (data) {
        toast.success(data.message);
      }
      setIsDataLoading(false);
      const { start_date, end_date } = dateFilter;
      fetchABATransactions(limit, currentPage, searchParam, start_date, end_date);
    } catch (e) {
      setIsDataLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    if (from && to) {
      setDateFilter({
        start_date: from,
        end_date: to
      });
      setDateFilterModal({
        start_date: from,
        end_date: to
      });
    }
  }, [from, to]);

  const handleExportReport = async () => {
    const { start_date, end_date } = dateFilter;
    setExportLoading(true);
    try {
      const formData = new FormData();
      const { data } = await get(
        `${API.ABA_FILE_LIST}/export?start_date=${start_date}&end_date=${end_date}&search=${searchParam}`,
        formData,
        {}
      );

      const export_report_path = data.data.file;
      if (export_report_path) {
        var a = document.createElement('a');
        a.href = export_report_path;
        var file = export_report_path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'aba_file_list.xlsx');
        a.setAttribute('target', '_blank');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (error) {
      console.log('error :>> ', error);
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
      setExportLoading(false);
    }
  };

  return (
    <div className="cms-page aba-transaction-page">
      <div className="table-top-btn">
        <div className="search-section">
          <Input placeholder="Search" onChange={onChange} value={searchParam} />
        </div>
        <div className="table-btn">
          <div
            className="summary-filter"
            style={{ margin: '0px' }}
            onClick={() => {
              setDatePickerVisible(true);
              const { start_date, end_date } = dateFilter;
              setDateFilterModal({
                start_date: start_date,
                end_date: end_date
              });
            }}
          >
            {dateFilter.start_date &&
              dateFilter.end_date
              ? 'start: ' +
                moment(dateFilter.start_date, 'DD-MM-YYYY').format('DD-MM-YYYY') +
                ' - End: ' +
                moment(dateFilter.end_date, 'DD-MM-YYYY').format('DD-MM-YYYY') : 'Select Date'}
            <FontAwesomeIcon icon={faCalendarAlt} size="1x" />
          </div>
          <Button
            onClick={() => handleExportReport()}
            className={exportLoading ? 'disabled' : ''}
            disabled={exportLoading}
            type="print"
          >
            {exportLoading ? 'Loading…' : 'Export'}
          </Button>
        </div>
      </div>
      <div className="aba-transaction-table">
        <ABATableComponent
          columns={abaTransactionTableColumns(handleRefreshButton, handleUploadedStatusABA)}
          dataSource={ABATransactions}
          nestedTableColumns={getAbaTransactionTableInnerColumns(setShowPreview, setPreview)}
          nestedTableColumns1={getAbaTransactionTableInnerColumns1()}
          nestedRowEndpoint={API.ABA_TRANSACTION_FILES}
          isNested={true}
          onPageChange={(page) =>
            onPageChange(page, limit, dateFilter?.start_date, dateFilter?.end_date)
          }
          pagingData={pagingData}
          setPreview={setPreview}
          setShowPreview={setShowPreview}
          limit={limit}
          loading={isDataLoading}
          handleSortChange={handleSortChange}
        />
        {ABATransactions?.length > 0 && (
          <div className="mt-2">
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={limit}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          </div>
        )}
      </div>
      <Footer />
      {showPreview && (
        <AbaTransactionPopup
          preview={preview}
          setShowPreview={setShowPreview}
          showPreview={showPreview}
        />
      )}
      {datePickerVisible && (
        <Modal
          width={500}
          className="popup-without-header"
          closable
          centered
          open
          onOk={onFilterOk}
          onCancel={() => setDatePickerVisible(false)}
        >
          <h4>Select Start and End Date</h4>
          <RangePicker
            defaultValue={[
              dateFilterModal.start_date ? moment(dateFilterModal.start_date, dateFormat[0]) : '',
              dateFilterModal.end_date ? moment(dateFilterModal.end_date, dateFormat[0]) : ''
            ]}
            disabledDate={disabledDate}
            format={dateFormat}
            onChange={handleDateChange}
          />
        </Modal>
      )}
    </div>
  );
};

export default ABATransactions;
