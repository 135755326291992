import React, { useState } from 'react';
import { Select } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Table, Input } from 'antd';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import useUserList from './useUserList';
import { getTableColumns } from './UserConsoleHelper';
import '../../App.css';
import '../../assets/css/style.css';
import './users.module.css';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import DialogModal from '../../components/Header/DialogModal';
// import { helpCenterArray } from '../HelpCenter';
import { defaultPaginationLimit } from '../../config';
import { useHelpCenter } from '../../shared/HelpCenterContext';

const UserConsole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getHelpCenterObjectByType } = useHelpCenter();
  const query = new URLSearchParams(location.search);
  const search = query.get('search');
  const currentPage = query.get('page');
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const { users, fetchUsers, onChange, searchParam, isDataLoading, pagingData, onPageChange } =
    useUserList(currentPage, search);

  const handleDelete = () => {
    fetchUsers();
  };

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/user-console?page=1&tab=statement&limit=${value}`, {
      replace: true
    });
    fetchUsers(1, value);
  };

  let helpTextArray = getHelpCenterObjectByType('User Console');

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            User Console{' '}
            <DialogModal
              title="User Console"
              helpTextArray={helpTextArray}
            />
          </h1>
          <h2>User Console</h2>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Input placeholder="Search User" onChange={onChange} value={searchParam} />
              </div>
              <div className="table-btn">
                <Link to={{ pathname: '/user/add' }} className="action button">
                  New
                </Link>
              </div>
            </div>
            <div className="user-list" style={{ width: '100%', height: '85%' }}>
              <Table
                rowKey={(record) => record.id}
                columns={getTableColumns(handleDelete)}
                dataSource={users}
                loading={isDataLoading}
                pagination={{
                  hideOnSinglePage: true,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page, limit),
                  itemRender: PaginationItemRenderer,
                  ...pagingData,
                  pageSize: limit
                }}
              />
              {users?.length > 0 && (
                <div className="col-md-12">
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserConsole;
