import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import SidebarNav from '../components/Header/SidebarNav';
import Footer from '../components/Footer/Index';
import ChartAccount from './general-setting/ChartAccount/ChartAccount';
import FirmSetting from './general-setting/FirmSetting/FirmSetting';
import OfficeList from './general-setting/Office/OfficeList';
import SupplierList from './general-setting/Supplier/SupplierList';
import Beneficiaries from './general-setting/Beneficiaries/Beneficiaries';
import BackButton from '../components/Form/BackButton';
import WithdrawalAccount from './general-setting/WithdrawalAccount/WithdrawalAccount';
import '../App.css';
import '../assets/css/style.css';
import Integration from './general-setting/Integration/Integration';
import DialogModal from '../components/Header/DialogModal';
// import { helpCenterArray } from './HelpCenter';
import Subscription from './general-setting/Subscription/Subscription';
import { GetCookies } from '../APIConfig/Interceptor';
import { useHelpCenter } from '../shared/HelpCenterContext';

const tabsKey = {
  'chart-of-accounts': 3,
  integrations: 5,
  'firm-settings': 1,
  office: 2,
  supplier: 4,
  personal: 6,
  beneficiaries: 7,
  subscription: 9
};

const Setting = () => {
  let subscription = GetCookies('_subscription');
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  const defaultActiveKey = tabsKey[selectedTab];
  const { getHelpCenterObjectByType } = useHelpCenter();

  const [helpText, setHelpText] = useState(getHelpCenterObjectByType('Firm Settings'));

  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      if (activeKey == 1) {
        setHelpText(getHelpCenterObjectByType('Firm Settings'));
      } else if (activeKey == 2) {
        setHelpText(getHelpCenterObjectByType('Office'));
      } else if (activeKey == 3) {
        setHelpText(getHelpCenterObjectByType('Chart Of Accounts'));
      } else if (activeKey == 4) {
        setHelpText(getHelpCenterObjectByType('Supplier'));
      } else if (activeKey == 5) {
        setHelpText(getHelpCenterObjectByType('Integrations'));
      } else if (activeKey == 6) {
        setHelpText(getHelpCenterObjectByType('Withdrawal'));
      } else if (activeKey == 7) {
        setHelpText(getHelpCenterObjectByType('Beneficiaries'));
      } else if (activeKey == 9) {
        setHelpText(getHelpCenterObjectByType('Subscription'));
      }
      const queryParams = `tab=${tabName}`;
      navigate(`/settings?${queryParams}`, {
        replace: true
      });
    }
  };

  const settingsTabItem = [
    {
      key: '1',
      label: 'Firm Settings',
      disabled: subscription == 1 ? false : true,
      children: subscription == 1 ? <FirmSetting /> : <Subscription />
    },
    {
      key: '6',
      label: 'Withdrawal Account',
      disabled: subscription == 1 ? false : true,
      children: subscription == 1 ? <WithdrawalAccount /> : <Subscription />
    },
    {
      key: '5',
      label: 'Integrations',
      disabled: subscription == 1 ? false : true,
      children: subscription == 1 ? <Integration /> : <Subscription />
    },
    {
      key: '2',
      label: 'Office',
      disabled: subscription == 1 ? false : true,
      children: subscription == 1 ? <OfficeList /> : <Subscription />
    },
    {
      key: '3',
      label: 'Chart of Accounts',
      disabled: subscription == 1 ? false : true,
      children: subscription == 1 ? <ChartAccount /> : <Subscription />
    },
    {
      key: '4',
      label: 'Supplier',
      disabled: subscription == 1 ? false : true,
      children: subscription == 1 ? <SupplierList /> : <Subscription />
    },
    {
      key: '7',
      label: 'Beneficiaries',
      disabled: subscription == 1 ? false : true,
      children: subscription == 1 ? <Beneficiaries /> : <Subscription />
    },
    {
      key: '9',
      label: 'Subscription',
      disabled: subscription == 1 ? false : true,
      children: <Subscription />
    }
  ];
  return (
    <div className="cms-page settings-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton /> Settings
            <DialogModal
              title={helpText[0]?.type}
              helpTextArray={helpText}
              // p2={helpText?.text}
              // p2={helpCenterArray[29].text}
              // p3={helpCenterArray[38].text}
              // p4={helpCenterArray[45].text}
              // p5={helpCenterArray[48].text}
              // p6={helpCenterArray[49].text}
            />
          </h1>
          <Tabs
            defaultActiveKey={defaultActiveKey?.toString()}
            activeKey={defaultActiveKey?.toString()}
            size="large"
            style={{ marginBottom: 32 }}
            onTabClick={onTabClick}
            items={settingsTabItem}
          ></Tabs>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Setting;
