/* eslint-disable no-unsafe-optional-chaining */
import { DatePicker, Button, Table, Select, Input, Tooltip } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, fileUpload, get } from '../../config';
import { formatNumber } from '../../APIConfig/Interceptor';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faEdit } from '@fortawesome/free-solid-svg-icons';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import {
  getTableColumnsBank,
  getTableColumnsClient
} from './components/MonthlyBankReconcilationHelper';
import { Dropdown, Modal } from 'react-bootstrap';
import Form from '@component/Form';

const disabledDate = (current) => {
  if (moment().endOf('month').format('DD-MM-YYYY') == moment().format('DD-MM-YYYY')) {
    return current && current > moment().endOf('month');
  }
  return current && current >= moment().startOf('month') && current > moment().endOf('month');
};

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

const MonthlyBankReconciliationReport = ({ setBalanceInfo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const month = query.get('month');
  const today = Date.now();
  const defaultDate = moment(today).format('MM-YYYY');
  const [addClients, setAddClients] = useState([]);
  const [limitAddClient, setLimitAddClient] = useState(defaultPaginationLimit);
  const [pagingDataAddClient, setPagingDataAddClient] = useState({ current: 1 });
  const [amount, setAmount] = useState();
  const [edit, setEdit] = useState();
  const [form] = Form.useForm();

  const [deductClients, setDeductClients] = useState([]);
  const [limitDeductClient, setLimitDeductClient] = useState(defaultPaginationLimit);
  const [pagingDataDeductClient, setPagingDataDeductClient] = useState({ current: 1 });

  const [addBanks, setAddBanks] = useState([]);
  const [limitAddBank, setLimitAddBank] = useState(defaultPaginationLimit);
  const [pagingDataAddBank, setPagingDataAddBank] = useState({ current: 1 });

  const [deductBanks, setDeductBanks] = useState([]);
  const [limitDeductBank, setLimitDeductBank] = useState(defaultPaginationLimit);
  const [pagingDataDeductBank, setPagingDataDeductBank] = useState({ current: 1 });

  const [date, setDate] = useState(month || defaultDate);
  const [monthlyData, setMonthlyData] = useState();
  const [businessName, setBusinessName] = useState('');
  const [statementbalance, setStatementBalance] = useState('');
  const [exportLoading, setExportLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isExpandDataLoading, setIsExpandDataLoading] = useState(true);
  const [expandAddClient, setExpandAddClient] = useState(false);
  const [expandDeductClient, setExpandDeductClient] = useState(false);
  const [expandAddBank, setExpandAddBank] = useState(false);
  const [expandDeductBank, setExpandDeductBank] = useState(false);

  const fetchMonthlyBankReconciliationData = useCallback(async () => {
    try {
      setIsDataLoading(true);
      let selectedDate =
        moment(date, 'MM-YYYY').toDate() >= moment().startOf('month').toDate() &&
        moment().format('DD-MM-YYYY') > moment().endOf('month').format('DD-MM-YYYY')
          ? moment().subtract(1, 'month').format('MM-YYYY')
          : moment(date, 'MM-YYYY').format('MM-YYYY');
      setDate(selectedDate);
      const { data } = await get(`${API.MONTHLY_BANK_RECONCILIATION_REPORT}?month=${selectedDate}`);
      setStatementBalance(data);
      setMonthlyData(data?.data);
      setBusinessName(data?.accounting_firm?.business_name);
      const balanceDetails = {};
      balanceDetails['firm_bank'] = data?.data?.firmBank;
      balanceDetails['firm_balance'] = data?.firm_balance;
      balanceDetails['statement_balance'] = data?.statement_balance;
      balanceDetails['date'] = data?.latest_statement?.date;
      setBalanceInfo(balanceDetails);
      setAmount(data?.statement_balance);
      setExpandAddBank(false);
      setExpandAddClient(false);
      setExpandDeductBank(false);
      setExpandDeductClient(false);

      setIsDataLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
      setIsDataLoading(false);
    }
  }, [setBalanceInfo, date]);

  const fetchMonthlyBankReconciliationReportEntries = useCallback(
    async (type, page = 1, limit = defaultPaginationLimit) => {
      try {
        setIsExpandDataLoading(true);
        let selectedDate =
          moment(date, 'MM-YYYY').toDate() >= moment().startOf('month').toDate() &&
          moment().format('DD-MM-YYYY') > moment().endOf('month').format('DD-MM-YYYY')
            ? moment().subtract(1, 'month').format('MM-YYYY')
            : moment(date, 'MM-YYYY').format('MM-YYYY');
        setDate(selectedDate);
        const formData = new FormData();
        formData.append('month', selectedDate);
        formData.append('type', type);
        formData.append('page', page);
        formData.append('limit', limit);
        const { data } = await fileUpload(
          `${API.MONTHLY_BANK_RECONCILIATION_REPORT_ENTRIES}`,
          formData
        );
        const { total, current_page, per_page, from, to } = data.data;
        if (type === 'transDeposits') {
          setAddClients(data?.data.data);
          setPagingDataAddClient({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
        }

        if (type === 'transWithdrawals') {
          setDeductClients(data?.data.data);
          setPagingDataDeductClient({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
        }

        if (type === 'stmtWithdrawals') {
          setAddBanks(data?.data.data);
          setPagingDataAddBank({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
        }

        if (type === 'stmtDeposits') {
          setDeductBanks(data?.data.data);
          setPagingDataDeductBank({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
        }
        setIsExpandDataLoading(false);
      } catch (error) {
        console.log('error :>> ', error);
        setIsExpandDataLoading(false);
      }
    },
    [date]
  );
  useEffect(() => {
    fetchMonthlyBankReconciliationData();
  }, [fetchMonthlyBankReconciliationData]);

  useEffect(() => {
    navigate(
      date
        ? `/firm-bank-account?page=1&tab=monthly-bank-reconciliation&month=${date} `
        : '/firm-bank-account?page=1&tab=monthly-bank-reconciliation',
      {
        replace: true
      }
    );
  }, [date]);

  const toggleExpandAddClient = (value) => {
    if (value) {
      setExpandAddClient(!expandAddClient);
    }
  };

  const handleChangeAddClient = async (value) => {
    setAddClients();
    setLimitAddClient(value);
    fetchMonthlyBankReconciliationReportEntries('transDeposits', 1, value);
  };

  const onPageChangeAddClient = (page) => {
    setAddClients();
    setPagingDataAddClient({ ...pagingDataAddClient, current: page });
    fetchMonthlyBankReconciliationReportEntries('transDeposits', page, limitAddClient);
  };

  const toggleExpandDeductClient = (value) => {
    if (value) {
      setExpandDeductClient(!expandDeductClient);
    }
  };

  const handleChangeDeductClient = async (value) => {
    setDeductClients();
    setLimitDeductClient(value);
    fetchMonthlyBankReconciliationReportEntries('transWithdrawals', 1, value);
  };

  const onPageChangeDeductClient = (page) => {
    setDeductClients();
    setPagingDataDeductClient({ ...pagingDataDeductClient, current: page });
    fetchMonthlyBankReconciliationReportEntries('transWithdrawals', page, limitDeductClient);
  };

  const toggleExpandAddBank = (value) => {
    if (value) {
      setExpandAddBank(!expandAddBank);
    }
  };

  const handleChangeAddBank = async (value) => {
    setAddBanks();
    setLimitAddBank(value);
    fetchMonthlyBankReconciliationReportEntries('stmtWithdrawals', 1, value);
  };

  const onPageChangeAddBank = (page) => {
    setAddBanks();
    setPagingDataAddBank({ ...pagingDataAddClient, current: page });
    fetchMonthlyBankReconciliationReportEntries('stmtWithdrawals', page, limitAddBank);
  };

  const toggleExpandDeductBank = (value) => {
    if (value) {
      setExpandDeductBank(!expandDeductBank);
    }
  };

  const handleChangeDeductBank = async (value) => {
    setDeductBanks();
    setLimitDeductBank(value);
    fetchMonthlyBankReconciliationReportEntries('stmtDeposits', 1, value);
  };

  const onPageChangeDeductBank = (page) => {
    setDeductBanks();
    setPagingDataDeductBank({ ...pagingDataDeductBank, current: page });
    fetchMonthlyBankReconciliationReportEntries('stmtDeposits', page, limitDeductBank);
  };

  useEffect(() => {
    if (expandAddClient) {
      fetchMonthlyBankReconciliationReportEntries('transDeposits', 1, limitAddClient);
    }
    return () => {};
  }, [expandAddClient, fetchMonthlyBankReconciliationReportEntries]);

  useEffect(() => {
    if (expandDeductClient) {
      fetchMonthlyBankReconciliationReportEntries('transWithdrawals', 1, limitDeductClient);
    }
    return () => {};
  }, [expandDeductClient, fetchMonthlyBankReconciliationReportEntries]);

  useEffect(() => {
    if (expandAddBank) {
      fetchMonthlyBankReconciliationReportEntries('stmtWithdrawals', 1, limitAddBank);
    }
    return () => {};
  }, [expandAddBank, fetchMonthlyBankReconciliationReportEntries]);

  useEffect(() => {
    if (expandDeductBank) {
      fetchMonthlyBankReconciliationReportEntries('stmtDeposits', 1, limitDeductBank);
    }
    return () => {};
  }, [expandDeductBank, fetchMonthlyBankReconciliationReportEntries]);

  const handleExportReport = async (summary) => {
    setExportLoading(true);
    try {
      let selectedDate =
        moment(date, 'MM-YYYY').toDate() >= moment().startOf('month').toDate() &&
        moment().format('DD-MM-YYYY') > moment().endOf('month').format('DD-MM-YYYY')
          ? moment().subtract(1, 'month').format('MM-YYYY')
          : moment(date, 'MM-YYYY').format('MM-YYYY');
      const { data } = await get(
        `${API.MONTHLY_BANK_RECONCILIATION_REPORT}?month=${selectedDate}&export=1&summary=${summary}`
      );

      const { file } = data.data;

      if (file) {
        const response = await axios.get(`${file}`, {
          responseType: 'blob'
        });
        const fileData = response.data;

        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var files = file.split('/');
        a.setAttribute('download', files[files.length - 1] || 'monthly_bank_reconciliation.pdf');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (error) {
      console.log('error :>> ', error);
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
      setExportLoading(false);
    }
  };

  const updateMonthlyBalance = async (values) => {
    try {
      setIsUpdateLoading(true);
      let selectedDate =
        moment(date, 'MM-YYYY').toDate() >= moment().startOf('month').toDate() &&
        moment().format('DD-MM-YYYY') > moment().endOf('month').format('DD-MM-YYYY')
          ? moment().subtract(1, 'month').format('MM-YYYY')
          : moment(date, 'MM-YYYY').format('MM-YYYY');
      setDate(selectedDate);
      const reqObj = values;
      const formData = new FormData();
      formData.append('month', selectedDate);
      formData.append('balance', reqObj['amount']);
      const { data } = await fileUpload(
        `${API.CHANGE_MONTHLY_BANK_RECONCILIATION_BALANCE}`,
        formData
      );

      setIsUpdateLoading(false);
      if (data) {
        toast.success(data.message);
        toggleEditModal();
        fetchMonthlyBankReconciliationData();
      }
    } catch (error) {
      console.log('error :>> ', error);
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
      setIsDataLoading(false);
    }
  };

  const toggleEditModal = () => {
    setEdit(!edit);
  };

  const toggleCancelModal = () => {
    setAmount(statementbalance?.statement_balance);
    setEdit(false);
  };

  return (
    <>
      {isDataLoading ? (
        <Spin />
      ) : (
        <>
          <div className="table-top-btn trial-btn">
            <div className="monthly-bank-reconciliation-btn">
              <DatePicker
                format={['MM-YYYY', 'MM/YYYY']}
                picker="month"
                disabledDate={disabledDate}
                value={month == null ? moment() : moment(month, 'MM-YYYY')}
                onChange={(e) => {
                  setDate(moment(e._d).format('MM-YYYY'));
                }}
                allowClear={false}
              />
            </div>
            <div className="table-btn monthly-bank-reconciliation-btn">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  disabled={exportLoading} /*onClick={handleExport}*/
                >
                  {exportLoading ? 'Loading…' : 'Export'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleExportReport(1)}>
                    Summary Report
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleExportReport(0)}>
                    Detailed Report
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="monthly-bank-reconciliation" id="monthly-bank-reconciliation">
            <div className="row">
              <div className="col-md-12 center-align dark-row border">{businessName}</div>
            </div>
            <div className="row">
              <div className="col-md-2 center-align dark-row border">Account Number</div>
              <div className="col-md-3 center-align dark-row border">Account Name</div>
              <div className="col-md-2 center-align dark-row border">Date of Reconciliation</div>
              <div className="col-md-2 center-align dark-row border">Bank balance date</div>
              <div className="col-md-3 center-align dark-row border">
                End of month bank account balance ($)
              </div>
            </div>
            <div className="row">
              <div className="col-md-2 center-align white-row border">
                {' '}
                {monthlyData?.firmBank?.account_number ? monthlyData?.firmBank?.account_number : ''}
              </div>
              <div className="col-md-3 center-align white-row border">
                {monthlyData?.firmBank?.account_name ? monthlyData?.firmBank?.account_name : ''}
              </div>
              <div className="col-md-2 center-align white-row border">
                {monthlyData?.reconciliation_date ? monthlyData?.reconciliation_date : ''}
              </div>
              <div className="col-md-2 center-align white-row border">
                {' '}
                {monthlyData?.statement_date ? monthlyData?.statement_date : ''}
              </div>
              <div className="col-md-3 right-align white-row border">
                <div className="monthly-amount-edit">
                  Imported Balance:{' '}
                  {statementbalance?.statement_balance
                    ? formatNumber(statementbalance?.statement_balance)
                    : '0.00'}
                  <span className="amount-change-button">
                    {!edit && (
                      <Tooltip placement="bottomLeft" title={'Edit'}>
                        <FontAwesomeIcon icon={faEdit} onClick={() => setEdit(true)} />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 center-align dark-row border">Reconciliation</div>
              <div className="col-md-5 right-align dark-row border">Value ($)</div>
            </div>
            <div className="row">
              <div className="col-md-12 center-align dark-row border">
                Bank Statement Reconciliation
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 left-align white-row border">
                Imported account balance on {monthlyData?.statement_date ?? ''}
              </div>
              <div className="col-md-5 right-align white-row border">
                {statementbalance?.statement_balance
                  ? formatNumber(statementbalance?.statement_balance)
                  : '0.00'}
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 left-align white-row border monthly-expand-item">
                <span
                  className="monthly-expand-item-icon"
                  onClick={() => toggleExpandAddClient(monthlyData?.transDeposits)}
                >
                  {expandAddClient ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretRight} />
                  )}
                </span>
                <span className="monthly-expand-item-text">
                  Add: Deposits in Client&apos;s Transactions Report but NOT reconciled in bank
                  statement
                </span>
              </div>
              <div className="col-md-5 right-align white-row border">
                {' '}
                {monthlyData?.transDeposits ? formatNumber(monthlyData?.transDeposits) : '0.00'}
              </div>
            </div>
            {expandAddClient && (
              <div className="row">
                <div className="col-md-12 monthly-expand-table border">
                  <Table
                    rowKey={(record) => record.id}
                    columns={getTableColumnsClient()}
                    dataSource={addClients}
                    loading={isExpandDataLoading}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      onChange: (page) => {
                        onPageChangeAddClient(page, limitAddClient);
                      },
                      itemRender: PaginationItemRenderer,
                      pageSize: limitAddClient,
                      ...pagingDataAddClient
                    }}
                  />
                  {addClients?.length > 0 && (
                    <div className="mt-2">
                      <div className="col-md-12">
                        Showing&nbsp;
                        <Select
                          defaultValue={`${defaultPaginationLimit}`}
                          value={limitAddClient}
                          onChange={handleChangeAddClient}
                          options={[
                            { value: '10', label: '10' },
                            { value: '25', label: '25' },
                            { value: '50', label: '50' },
                            { value: '100', label: '100' }
                          ]}
                        />
                        &nbsp;Records per page I Showing {pagingDataAddClient?.from} to&nbsp;
                        {pagingDataAddClient?.to} from {pagingDataAddClient?.total} Records
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7 left-align white-row border monthly-expand-item">
                <span
                  className="monthly-expand-item-icon"
                  onClick={() => toggleExpandAddBank(monthlyData?.stmtWithdrawals)}
                >
                  {expandAddBank ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretRight} />
                  )}
                </span>
                <span className="monthly-expand-item-text">
                  Add: Withdrawals on the bank statement that are NOT Reconciled in Client
                  Transactions Report
                </span>
              </div>
              <div className="col-md-5 right-align white-row border">
                {' '}
                {monthlyData?.stmtWithdrawals ? formatNumber(monthlyData?.stmtWithdrawals) : '0.00'}
              </div>
            </div>
            {expandAddBank && (
              <div className="row">
                <div className="col-md-12 monthly-expand-table border">
                  <Table
                    rowKey={(record) => record.id}
                    columns={getTableColumnsBank()}
                    dataSource={addBanks}
                    loading={isExpandDataLoading}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      onChange: (page) => {
                        onPageChangeAddBank(page, limitAddBank);
                      },
                      itemRender: PaginationItemRenderer,
                      pageSize: limitAddBank,
                      ...pagingDataAddBank
                    }}
                  />
                  {addBanks?.length > 0 && (
                    <div className="mt-2">
                      <div className="col-md-12">
                        Showing&nbsp;
                        <Select
                          defaultValue={`${defaultPaginationLimit}`}
                          value={limitAddBank}
                          onChange={handleChangeAddBank}
                          options={[
                            { value: '10', label: '10' },
                            { value: '25', label: '25' },
                            { value: '50', label: '50' },
                            { value: '100', label: '100' }
                          ]}
                        />
                        &nbsp;Records per page I Showing {pagingDataAddBank?.from} to&nbsp;
                        {pagingDataAddBank?.to} from {pagingDataAddBank?.total} Records
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7 left-align gray-row border">Subtotal</div>
              <div className="col-md-5 right-align gray-row border">
                {statementbalance?.statement_balance +
                monthlyData?.transDeposits +
                monthlyData?.stmtWithdrawals
                  ? formatNumber(
                      statementbalance?.statement_balance +
                        monthlyData?.transDeposits +
                        monthlyData?.stmtWithdrawals
                    )
                  : '0.00'}
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 left-align white-row border monthly-expand-item">
                <span
                  className="monthly-expand-item-icon"
                  onClick={() => toggleExpandDeductClient(monthlyData?.transWithdrawals)}
                >
                  {expandDeductClient ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretRight} />
                  )}
                </span>
                <span className="monthly-expand-item-text">
                  Deduct: Withdrawls in the Client&apos;s Transactions Report but NOT reconciled in
                  bank statement
                </span>
              </div>
              <div className="col-md-5 right-align white-row border">
                {monthlyData?.transWithdrawals
                  ? formatNumber(monthlyData?.transWithdrawals)
                  : '0.00'}
              </div>
            </div>
            {expandDeductClient && (
              <div className="row">
                <div className="col-md-12 monthly-expand-table border">
                  <Table
                    rowKey={(record) => record.id}
                    columns={getTableColumnsClient()}
                    dataSource={deductClients}
                    loading={isExpandDataLoading}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      onChange: (page) => {
                        onPageChangeDeductClient(page, limitDeductClient);
                      },
                      itemRender: PaginationItemRenderer,
                      pageSize: limitDeductClient,
                      ...pagingDataDeductClient
                    }}
                  />
                  {deductClients?.length > 0 && (
                    <div className="mt-2">
                      <div className="col-md-12">
                        Showing&nbsp;
                        <Select
                          defaultValue={`${defaultPaginationLimit}`}
                          value={limitDeductClient}
                          onChange={handleChangeDeductClient}
                          options={[
                            { value: '10', label: '10' },
                            { value: '25', label: '25' },
                            { value: '50', label: '50' },
                            { value: '100', label: '100' }
                          ]}
                        />
                        &nbsp;Records per page I Showing {pagingDataDeductClient?.from} to&nbsp;
                        {pagingDataDeductClient?.to} from {pagingDataDeductClient?.total} Records
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7 left-align white-row border monthly-expand-item">
                <span
                  className="monthly-expand-item-icon"
                  onClick={() => toggleExpandDeductBank(monthlyData?.stmtDeposits)}
                >
                  {expandDeductBank ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretRight} />
                  )}
                </span>
                <span className="monthly-expand-item-text">
                  Deduct: Deposits on the bank statement that are NOT Reconciled in Client
                  Transactions Report
                </span>
              </div>
              <div className="col-md-5 right-align white-row border">
                {monthlyData?.stmtDeposits ? formatNumber(monthlyData?.stmtDeposits) : '0.00'}
              </div>
            </div>
            {expandDeductBank && (
              <div className="row">
                <div className="col-md-12 monthly-expand-table border">
                  <Table
                    rowKey={(record) => record.id}
                    columns={getTableColumnsBank()}
                    dataSource={deductBanks}
                    loading={isExpandDataLoading}
                    pagination={{
                      hideOnSinglePage: true,
                      defaultCurrent: 1,
                      showQuickJumper: true,
                      onChange: (page) => {
                        onPageChangeDeductBank(page, limitDeductBank);
                      },
                      itemRender: PaginationItemRenderer,
                      pageSize: limitDeductBank,
                      ...pagingDataDeductBank
                    }}
                  />
                  {deductBanks?.length > 0 && (
                    <div className="mt-2">
                      <div className="col-md-12">
                        Showing&nbsp;
                        <Select
                          defaultValue={`${defaultPaginationLimit}`}
                          value={limitDeductBank}
                          onChange={handleChangeDeductBank}
                          options={[
                            { value: '10', label: '10' },
                            { value: '25', label: '25' },
                            { value: '50', label: '50' },
                            { value: '100', label: '100' }
                          ]}
                        />
                        &nbsp;Records per page I Showing {pagingDataDeductBank?.from} to&nbsp;
                        {pagingDataDeductBank?.to} from {pagingDataDeductBank?.total} Records
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7 left-align light-theam-row border">Expected balance</div>
              <div className="col-md-5 right-align light-theam-row border">
                {monthlyData?.transDeposits +
                monthlyData?.stmtWithdrawals +
                statementbalance?.statement_balance -
                (monthlyData?.transWithdrawals + monthlyData?.stmtDeposits)
                  ? formatNumber(
                      monthlyData?.transDeposits +
                        monthlyData?.stmtWithdrawals +
                        statementbalance?.statement_balance -
                        (monthlyData?.transWithdrawals + monthlyData?.stmtDeposits)
                    )
                  : '0.00'}
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 left-align dark-row border">
                Balance as per Trial Balance
              </div>
              <div className="col-md-5 right-align dark-row border">
                {monthlyData?.balanceAsPerTrial
                  ? formatNumber(
                      Number(monthlyData?.balanceAsPerTrial ? monthlyData?.balanceAsPerTrial : 0)
                    )
                  : '0.00'}
              </div>
            </div>

            {date?.split('-')?.[0] !== padWithLeadingZeros(Number(new Date().getMonth() + 1), 2) &&
              (monthlyData?.transDeposits +
              monthlyData?.stmtWithdrawals +
              statementbalance?.statement_balance -
              (monthlyData?.transWithdrawals + monthlyData?.stmtDeposits)
                ? formatNumber(
                    monthlyData?.transDeposits +
                      monthlyData?.stmtWithdrawals +
                      statementbalance?.statement_balance -
                      (monthlyData?.transWithdrawals + monthlyData?.stmtDeposits)
                  )
                : '0.00') ===
                (monthlyData?.balanceAsPerTrial
                  ? formatNumber(
                      Number(monthlyData?.balanceAsPerTrial ? monthlyData?.balanceAsPerTrial : 0)
                    )
                  : '0.00') && (
                <div className="row">
                  <div className="col-md-12 green-row border">
                    Great job! Your monthly bank reconciliation is now completed.
                  </div>
                </div>
              )}
          </div>
        </>
      )}
      {edit && (
        <div>
          <Modal
            className="smallest-modal supplier-modal"
            id="supplier_modal"
            show={edit}
            onHide={toggleEditModal}
            dialogClassName="modal-50w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Edit Balance</Modal.Header>
            <Modal.Body>
              <div className="spin-body-relative">
                <Form
                  form={form}
                  initialValues={{
                    amount: amount
                  }}
                  name="monthlyBalanceForm"
                  onFinish={updateMonthlyBalance}
                  layout="vertical"
                >
                  <div className="input-boxes">
                    <Form.Item className="full-width" label="Balance" name="amount">
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="action-box">
                    <Button type="primary" htmlType="submit">
                      OK
                    </Button>
                    <Button
                      className="reset"
                      htmlType="reset"
                      variant="secondary"
                      onClick={() => {
                        form.resetFields();
                        toggleCancelModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
                {isUpdateLoading && (
                  <div className="top-modal-spin-center">
                    <Spin />
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default MonthlyBankReconciliationReport;
