/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import { Button, Modal, Select, Spin, Table } from 'antd';
import { API, defaultPaginationLimit, fileUpload, get } from '../../../config';
import { toast } from 'react-toastify';
import moment from 'moment';
import '../../../App.css';
import '../../../assets/css/style.css';
import '../../users/users.module.css';
import { PaginationItemRenderer } from '../../../shared/PaginationItemRenderer';
import { useEffect } from 'react';
import { faChartColumn, faEye, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';

const RegisterFirmAction = ({ id }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showUpdateTrialBalance, setShowUpdateTrialBalance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [limit, setLimit] = useState(defaultPaginationLimit);
  const [currpage, setCurrPage] = useState(1);

  const [pagingData, setPagingData] = useState({ current: 1 });

  const toggleResendModal = () => {
    setShowDelete(!showDelete);
  };

  const toggleUpdateTrialBalanceModal = () => {
    setShowUpdateTrialBalance(!showUpdateTrialBalance);
  };
  const toggleActivityModal = () => {
    fetchActivity();
    if (showActivity) {
      setLimit(defaultPaginationLimit);
    }
    setShowActivity(!showActivity);
  };

  const handleResendLink = async () => {
    const formData = new FormData();
    try {
      setLoading(true);
      formData.append('user_id', id);
      const { data } = await fileUpload(`${API.RESEND_LINK}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoading(false);
      toast.success(data.message);
      toggleResendModal();
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleUpdateTrialBalance = async () => {
    const formData = new FormData();
    try {
      setLoading(true);
      formData.append('user_id', id);
      const { data } = await fileUpload(`${API.UPDATE_TRIAL_BALANCE}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoading(false);
      toast.success(data.message);
      toggleUpdateTrialBalanceModal();
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const getTableColumns = () => {
    return [
      {
        title: 'Date',
        width: '15%',
        sorter: (a, b) => a.created_at.localeCompare(b.created_at),
        render: (obj) => {
          return obj?.created_at
            ? moment(obj.created_at, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss')
            : null;
        }
      },
      {
        title: 'Name',
        render: (obj) => {
          return obj?.staff?.first_name + ' ' + obj?.staff?.last_name;
        },
        width: '20%',
        sorter: (a, b) => {
          a.staff.first_name.localeCompare(b.staff.first_name);
        }
      },
      {
        title: 'Email',
        render: (obj) => {
          return obj?.staff?.email;
        },

        width: '10%',
        sorter: (a, b) => a.email - b.email
      },
      {
        title: 'Activity',
        width: '30%',
        render: (obj) => {
          const sanitizedHTML = DOMPurify.sanitize(String(obj?.activity || ''));
          return <span dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></span>;
        },
        sorter: (a, b) => a?.activity.localeCompare(b?.activity)
      }
    ];
  };

  const fetchActivity = useCallback(
    async (page, tempLimit) => {
      try {
        setLoading(true);
        const { data } = await get(
          `${API.ADMIN_ACTIVITY_LOGS}${id}?page=${page ? page : currpage ? currpage : 1}&limit=${
            tempLimit ? tempLimit : limit ? limit : defaultPaginationLimit
          }`
        );

        setActivityData(data?.data?.data);
        setLoading(false);
        setPagingData({
          total: data?.data?.total,
          current: data?.data?.current_page,
          defaultPageSize: data?.data?.per_page,
          from: data?.data?.from,
          to: data?.data?.to
        });
      } catch (e) {
        // setActivityData([]);
        // setLoading(false);
        // setPagingData({ current: 0 });
      }
    },
    [limit, currpage]
  );

  const handlePageChange = async (pageSize, limit) => {
    setCurrPage(pageSize);
    setLimit(limit);
    fetchActivity(pageSize, limit);
  };

  const handleChange = async (value) => {
    setCurrPage(1);
    setLimit(Number(value));
    fetchActivity(1, value);
  };

  useEffect(() => {
    // fetchActivity();
  }, [currpage, limit]);

  return (
    <span className="action-box resend-password-action">
      <FontAwesomeIcon
        onClick={toggleResendModal}
        title="Resend Password Link"
        icon={faShareSquare}
        size="1x"
      />
      <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
      <FontAwesomeIcon
        title="View Activity Logs"
        onClick={toggleActivityModal}
        icon={faEye}
        size="1x"
        // style={{ margin: '10px' }}
      />
      <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
      <FontAwesomeIcon
        title="Update Trial Balance"
        onClick={toggleUpdateTrialBalanceModal}
        icon={faChartColumn}
        size="1x"
        // style={{ margin: '10px' }}
      />
      {showDelete && (
        <Modal
          className="remove-popup"
          width={500}
          closable
          centered
          open
          footer={null}
          onCancel={toggleResendModal}
        >
          <div className="remove-content">
            <p>Are you sure you want to resend the password link?</p>
            <div className="modal-action">
              <Button className="cancel" onClick={toggleResendModal}>
                Cancel
              </Button>
              <Button onClick={handleResendLink} loading={loading}>
                OK
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showUpdateTrialBalance && (
        <Modal
          className="remove-popup"
          width={500}
          closable
          centered
          open
          footer={null}
          onCancel={toggleUpdateTrialBalanceModal}
        >
          <div className="remove-content spin-body-relative">
            <p>Are you sure you want to update the trial balance?</p>
            <div className="modal-action">
              <Button className="cancel" onClick={toggleUpdateTrialBalanceModal}>
                Cancel
              </Button>
              <Button onClick={handleUpdateTrialBalance} disabled={loading}>
                OK
              </Button>
            </div>
          </div>
          {loading && (
            <div className="modal-spin-center">
              <Spin />
            </div>
          )}
        </Modal>
      )}
      {showActivity && (
        <Modal
          className="remove-popup"
          width={1000}
          closable
          centered
          open
          footer={null}
          onCancel={toggleActivityModal}
        >
          <div className="activity-content">
            <Table
              rowKey={(record) => record.id}
              columns={getTableColumns()}
              dataSource={activityData}
              loading={loading}
              pagination={{
                hideOnSinglePage: true,
                defaultCurrent: 1,
                showQuickJumper: true,
                onChange: (page) => {
                  handlePageChange(page, limit);
                },
                itemRender: PaginationItemRenderer,
                pageSize: limit,
                ...pagingData
              }}
            />
            <div>
              {activityData?.length > 0 && (
                <div className="col-md-12">
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </span>
  );
};

export default RegisterFirmAction;
