import React, { useState, useEffect, useCallback } from 'react';
import { pickBy, identity } from 'lodash';
import SidebarNav from '../../components/Header/SidebarNav';
import BackButton from '../../components/Form/BackButton';
import Form from '@component/Form';
import moment from 'moment';
import { Button, Input, Select, Upload, Checkbox, DatePicker, Spin, InputNumber } from 'antd';
import {
  useStateList,
  useSuburbList,
  useSuburbList1,
  useManageList,
  useStaffList,
  verificationMethods
} from './CustomersHelper';
import ImgCrop from 'antd-img-crop';
import CancelButton from '../../components/Form/CancelButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { API, fileUpload, get } from '../../config.js';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
// import faqicon from '../../assets/images/bulb-icon.jpg';
import UserSelect from '../../components/User/UserSelect';
import { useHelpCenter } from '../../shared/HelpCenterContext.js';
import DialogModal from '../../components/Header/DialogModal.js';

const { TextArea } = Input;

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const CustomerAdd = () => {
  const [form] = Form.useForm();
  const { getHelpCenterObjectByType } = useHelpCenter();
  const { stateLoading, stateList = [] } = useStateList();
  const [stateId] = useState();
  const { suburbLoading, suburbList = [], handleStateSelect } = useSuburbList(stateId);
  const { suburbLoading1, suburbList1 = [], handleStateSelect1 } = useSuburbList1(stateId);
  const { managerListLoading, managerList = [] } = useManageList(stateId);
  const { staffList = [] } = useStaffList(stateId);
  const [fileList, setFileList] = useState([]);
  const [primaryFileList, setPrimaryFileList] = useState([]);
  const [secondaryfileList, setSecondaryFileList] = useState([]);
  const [authorityLetter, setAuthorityLetter] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isVerificationRequired, setIsVerificationRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reletedToSelect, setReletedToSelect] = useState();
  const [authuser, setAuthuser] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [bankOptions, setBankOptions] = useState([]);
  const [bankSearch, setBankSearch] = useState('');
  const [bankLoading, setBankLoading] = useState(false);

  const getBankList = async (searchValue) => {
    try {
      setBankLoading(true);
      const { data } = await get(`${API.BANK_ABBREVIATION}?search=${searchValue}`);
      setBankLoading(false);
      setBankOptions(data.data);
    } catch (error) {
      setBankLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleBankSearch = (value) => {
    setBankSearch(value);
    if (value) {
      getBankList(value);
    }
  };

  const ClientTitle = [
    { id: 'Mr.', name: 'Mr.' },
    { id: 'Mrs.', name: 'Mrs.' },
    { id: 'Ms.', name: 'Ms.' },
    { id: 'Dr.', name: 'Dr.' },
    { id: 'Other', name: 'Other' }
  ];

  const tagList = [
    { id: 'A', name: 'A' },
    { id: 'B', name: 'B' },
    { id: 'C', name: 'C' },
    { id: 'D', name: 'D' },
    { id: 'E', name: 'E' },
    { id: 'F', name: 'F' },
    { id: 'G', name: 'G' },
    { id: 'H', name: 'H' },
    { id: 'I', name: 'I' },
    { id: 'J', name: 'J' },
    { id: 'K', name: 'K' },
    { id: 'L', name: 'L' },
    { id: 'M', name: 'M' },
    { id: 'N', name: 'N' },
    { id: 'O', name: 'O' },
    { id: 'P', name: 'P' },
    { id: 'Q', name: 'Q' },
    { id: 'R', name: 'R' },
    { id: 'S', name: 'S' },
    { id: 'T', name: 'T' },
    { id: 'U', name: 'U' },
    { id: 'V', name: 'V' },
    { id: 'W', name: 'W' },
    { id: 'X', name: 'X' },
    { id: 'Y', name: 'Y' },
    { id: 'Z', name: 'Z' }
  ];

  useEffect(() => {
    form.setFieldsValue({ verified_by: authuser });
    if (suburbList && suburbList.length) {
      form.setFieldsValue({ suburb_id: suburbList[0].id });
    }
    if (suburbList1 && suburbList1.length) {
      form.setFieldsValue({ shipping_suburb_id: suburbList1[0].id });
    }
  }, [form, suburbList, suburbList1, state, authuser]);

  const fetchCustomerDetails = async (id) => {
    try {
      setLoading(true);
      const { data } = await get(API.TAX_EXPAND_SEARCH + id);
      const customerIntialData = data.data.find((record) => record.id === state.record_id);
      form.setFieldsValue({
        account_number: customerIntialData?.account_number?.toString(),
        account_name: customerIntialData?.account_name,
        bank_name: customerIntialData?.bank_name,
        bsb: customerIntialData?.bsb?.toString(),
        email: customerIntialData?.email,
        first_name: customerIntialData?.first_name,
        last_name: customerIntialData?.last_name,
        postal_code: customerIntialData?.postal_code?.toString(),
        shipping_postal_code: customerIntialData?.shipping_postal_code?.toString(),
        street: customerIntialData?.street,
        suburb: customerIntialData?.suburb,
        tfn: customerIntialData?.tfn?.toString()
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.errors?.myna_errpr[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    if (state?.batch_id) fetchCustomerDetails(state.batch_id);
  }, [state, form]);

  const getUsers = useCallback(async () => {
    try {
      const { data } = await get(`${API.GET_USER_LIST}?limit=-1`);
      setUserList(data?.data?.data);
    } catch (e) {
      setUserList([]);
    }
  });

  useEffect(() => {
    getUsers();
    getBankList(bankSearch);
  }, []);

  const getAtoData = async () => {
    setLoading(true);
    const { data } = await get(`${API.ATO_DETAILS}/${state.ato_id}`);
    setLoading(false);
    if (data.data) {
      form.setFieldsValue({
        account_name: data.data?.name ?? '',
        last_name: data.data?.last_name ?? '',
        business_name: data.data?.last_name ?? '',
        tfn: data.data?.tfn ?? ''
      });
    }
  };

  useEffect(() => {
    if (state?.ato_id) {
      getAtoData();
    }
  }, [state?.ato_id]);

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const reqObj = pickBy(values, identity);
      /* if (reqObj.account_name || reqObj.bsb) {
        if (reqObj.bank_name == '' || reqObj.bank_name == undefined) {
          setLoading(false);
          toast.error('Please select Bank Name');
          return false;
        }
      } */
      reqObj.abn = reqObj?.abn ? `${reqObj?.abn}`.replace(/ /g, '') : '';
      if (!('first_name' in reqObj)) {
        if (!('business_name' in reqObj)) {
          reqObj.business_name = reqObj.last_name;
        }
      }

      if (!('related_to' in reqObj)) {
        reqObj.related_to = reletedToSelect ? reletedToSelect : '';
      }
      const formData = new FormData();
      if (primaryFileList && primaryFileList.length > 0) {
        primaryFileList.forEach((primaryfile, i) => {
          formData.append(`primary_document_${i + 1}`, primaryfile.originFileObj);
        });
      }
      if (secondaryfileList && secondaryfileList.length > 0) {
        secondaryfileList.forEach((secondaryfile, i) => {
          formData.append(`secondary_document_${i + 1}`, secondaryfile.originFileObj);
        });
      }

      if (state && state.record_id) {
        formData.append('ts_id', state.record_id);
      }
      if (state && state.ato_id) {
        formData.append('ato_id', state.ato_id);
      }

      if (fileList && fileList[0]?.originFileObj)
        formData.append('profile_pic', fileList[0]?.originFileObj);
      Object.keys(reqObj).forEach((key) => {
        if (key == 'fee_from_refund') {
          formData.append([key], reqObj[key] == true ? 1 : 0);
        } else if (key == 'deceased' || key === 'date_verified') {
          formData.append([key], moment(reqObj[key]).format('YYYY-MM-DD'));
        } else if (key === 'verification_method') {
          formData.append([key], reqObj[key] - 1);
        } else {
          formData.append([key], reqObj[key]);
        }
      });
      if (authorityLetter && authorityLetter.length > 0) {
        formData.append('authority_letter', authorityLetter[0].originFileObj);
      }
      const { data } = await fileUpload(API.CREATE_CUSTOMER, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setLoading(false);
      toast.success(data?.message);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  const handleProfileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const handlePrimaryChange = ({ fileList: newFileList }) => {
    setPrimaryFileList(newFileList);
  };
  const handleSecondaryChange = ({ fileList: newFileList }) => {
    setSecondaryFileList(newFileList);
  };
  const handleAuthorityLetterChange = ({ fileList: newFileList }) => {
    if (!newFileList.length) {
      setAuthorityLetter(newFileList);
    }
    if (newFileList[0]?.type === 'application/pdf') {
      setAuthorityLetter(newFileList);
    }
  };
  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const onPrimaryPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const onSeondaryPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handleFirstName = () => {
    const values = form.getFieldsValue();
    if (values.business_name === values.last_name) {
      form.setFieldsValue({
        business_name: ''
      });
    }
  };

  const handleLastName = (event) => {
    const values = form.getFieldsValue();
    const { value } = event.target;
    if (
      !values.business_name ||
      values.business_name === null ||
      values.business_name === undefined ||
      value === values.business_name
    ) {
      if (values.first_name) {
        form.setFieldsValue({
          business_name: ''
        });
      } else {
        form.setFieldsValue({
          business_name: value
        });
      }
    }
  };

  const handleBussinessName = (event) => {
    const values = form.getFieldsValue();
    const { value } = event.target;
    if (
      !values.last_name ||
      values.last_name === null ||
      values.last_name === undefined ||
      value === values.last_name
    ) {
      if (values.first_name) {
        form.setFieldsValue({
          business_name: ''
        });
      }
      form.setFieldsValue({
        last_name: value
      });
    }
  };
  const onVerificationChange = () => {
    const date = form.getFieldValue('date_verified');
    const user = form.getFieldValue('verified_by');
    const method = form.getFieldValue('verification_method');
    const notes = form.getFieldValue('notes');
    if ((date || user || method || notes) && !isVerificationRequired) {
      setIsVerificationRequired(true);
    } else {
      if (!date && !user && !method && !notes) {
        setIsVerificationRequired(false);
      }
    }
  };

  const onReletedToSelect = (id) => {
    setReletedToSelect(id);
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList,
    listType: 'picture',
    showUploadList: {
      showRemoveIcon: false
    }
  };
  function onSearch() {}
  useEffect(async () => {
    const { data } = await get(API.GET_USER_PROFILE);
    setAuthuser(data.id);
  }, []);

  let helpTextArray = getHelpCenterObjectByType('New Client');

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
      </div>
      <div className="full-content-block">
        <h1 className="page-title">
          <BackButton />
          New Client
          <DialogModal title="New Client" helpTextArray={helpTextArray} />
        </h1>
        <div className="content-details">
          <div className="entry-section">
            {loading ? (
              <Spin />
            ) : (
              <Form
                form={form}
                initialValues={{
                  fee_from_refund: false
                }}
                name="customerForm"
                onFinish={handleFinish}
                layout="vertical"
              >
                <div className="entry-card">
                  <h3>Personal Details</h3>
                  <div className="form-box">
                    <Form.Item className="half-width title-label" label="Title" name="title">
                      <Select
                        showSearch={false}
                        optionFilterProp="children"
                        onSearch={onSearch}
                        placeholder="Please select Title"
                      >
                        {ClientTitle.map((type) => (
                          <Select.Option key={type.id} value={type.id}>
                            {type.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="tri-width fname-label"
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          max: 32,
                          message: 'Must be less than 32 character'
                        },
                        {
                          pattern: new RegExp(/^[a-zA-Z\s]*$/),
                          message: 'First name can contain letters & spaces.'
                        }
                      ]}
                    >
                      <Input onBlur={handleFirstName} placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                      className="tri-width mname-label"
                      label="Middle Name"
                      name="middle_name"
                      rules={[
                        {
                          max: 150,
                          message: 'Must be less than 150 character'
                        },
                        {
                          pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,&]*$/),
                          message:
                            'Middle name can contain letters, numbers, ampersand(&), dot(.), comma(,) & spaces.'
                        }
                      ]}
                    >
                      <Input placeholder="Middle Name" />
                    </Form.Item>
                    <Form.Item
                      className="tri-width lname-label"
                      label="Last Or Trading Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Last name'
                        },
                        {
                          max: 150,
                          message: 'Must be less than 150 character'
                        },
                        {
                          pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&'()]*$/),
                          message:
                            "Last name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')"
                        }
                      ]}
                    >
                      <Input onBlur={handleLastName} placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                      className="half-width"
                      label="TFN"
                      name="tfn"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter TFN'
                        },
                        {
                          pattern: new RegExp(/^([0-9]+$)([ .-]?)/),
                          message: 'Please enter valid TFN'
                        },
                        {
                          min: 8,
                          message: 'Minimum 8 digits are required in TFN'
                        },
                        {
                          max: 9,
                          message: 'Maximum 9 digits are allowed in TFN'
                        },
                        {
                          validator: (_, value) => {
                            if (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) {
                              return Promise.reject(
                                'Please enter valid TFN Number. All digits of same number are not allowed.'
                              );
                            } else {
                              if (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) {
                                return Promise.reject(
                                  'Please enter valid TFN Number. All digits of same number are not allowed.'
                                );
                              }
                              return Promise.resolve();
                            }
                          }
                        }
                      ]}
                    >
                      <Input placeholder="123412345" value="" />
                    </Form.Item>
                    <Form.Item
                      className="half-width"
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Email'
                        },
                        {
                          type: 'email',
                          message: 'Please enter valid Email'
                        }
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item className="half-width" label="Phone Number" name="phone">
                      <InputMask
                        mask="99 9999 9999"
                        name="phone"
                        placeholder="12 1234 1234"
                        className="ant-input"
                      />
                    </Form.Item>
                    <Form.Item className="half-width" label="Mobile Number" name="mobile">
                      <InputMask
                        mask="9999 999 999"
                        name="mobile"
                        placeholder="1234 123 123"
                        className="ant-input"
                      />
                    </Form.Item>
                    <Form.Item
                      className="half-width"
                      label="Business Name"
                      name="business_name"
                      rules={[
                        {
                          max: 150,
                          message: 'Must be less than 150 character'
                        },
                        {
                          pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-'()]*$/),
                          message: `Business name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                        }
                      ]}
                    >
                      <Input onBlur={handleBussinessName} placeholder="Business Name" />
                    </Form.Item>
                    <Form.Item className="half-width" label="ABN" name="abn">
                      <InputMask
                        mask="99 999 999 999"
                        name="abn"
                        placeholder="12 123 123 123"
                        className="ant-input"
                      />
                    </Form.Item>

                    <Form.Item className="half-width" label="Related to" name="related_to">
                      <UserSelect
                        formStyle={{ width: '100%' }}
                        customerId={reletedToSelect}
                        onCustomerSelectionChange={(id) => {
                          onReletedToSelect(id);
                        }}
                        placeholder="Please select Related to"
                        className="customer-select"
                      />
                    </Form.Item>
                    <Form.Item className="half-width" label="Deceased" name="deceased">
                      <DatePicker
                        format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                    <Form.Item
                      className="half-width"
                      label="Opening Balance"
                      name="opening_balance"
                    >
                      <InputNumber placeholder="0.00" />
                    </Form.Item>
                    <Form.Item
                      className="half-width"
                      label="CRN"
                      name="crn"
                      rules={[
                        {
                          pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                          message: 'Value should be Alphanumberic'
                        }
                      ]}
                    >
                      <Input placeholder="Client Reference Number" />
                    </Form.Item>
                    <div className="address-box">
                      <h5>Postal Address</h5>
                      <Form.Item
                        className="half-width"
                        label="Address"
                        name="street"
                        rules={[
                          {
                            pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                            message: `Address can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
                          }
                        ]}
                      >
                        <Input placeholder="Street" />
                      </Form.Item>
                      <Form.Item className="half-width" label="Select State" name="state_id">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onSearch={onSearch}
                          onChange={handleStateSelect}
                          loading={stateLoading}
                          placeholder="Please select State"
                        >
                          {stateList.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item className="half-width" label="Select Suburb" name="suburb_id">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onSearch={onSearch}
                          loading={suburbLoading}
                          placeholder="Please select Suburb"
                        >
                          {suburbList.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Post Code"
                        name="postal_code"
                        rules={[
                          {
                            pattern: new RegExp(/^\d{4}$/),
                            message: 'Must be 4 digit number'
                          }
                        ]}
                      >
                        <Input placeholder="Post Code" type="number" />
                      </Form.Item>
                    </div>
                    <div className="address-box">
                      <h5>Street Address</h5>
                      <Form.Item
                        className="half-width"
                        label="Address"
                        name="shipping_street"
                        rules={[
                          {
                            pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                            message: `Address can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphen(-), slash(/) & spaces.`
                          }
                        ]}
                      >
                        <Input placeholder="Street" />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Select State"
                        name="shipping_state_id"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onSearch={onSearch}
                          onChange={handleStateSelect1}
                          loading={stateLoading}
                          placeholder="Please select State"
                        >
                          {stateList.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Select Suburb"
                        name="shipping_suburb_id"
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          onSearch={onSearch}
                          loading={suburbLoading1}
                          placeholder="Please select Suburb"
                        >
                          {suburbList1.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Post Code"
                        name="shipping_postal_code"
                        rules={[
                          {
                            pattern: new RegExp(/^\d{4}$/),
                            message: 'Must be 4 digit number'
                          }
                        ]}
                      >
                        <Input placeholder="Post Code" type="number" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="entry-card">
                  <h3>Bank Details</h3>
                  <div className="form-box">
                    <Form.Item
                      className="half-width"
                      label="Account Name"
                      name="account_name"
                      rules={[
                        {
                          max: 200,
                          message: 'Must be less than 200 character'
                        },
                        {
                          pattern: new RegExp(/^[^!@#^%$<>][a-zA-Z\s\d.,&-'()]*$/),
                          message: `Account name can contain letters, numbers, spaces and some special characters like (& ( ) . , - ')`
                        }
                      ]}
                    >
                      <Input placeholder="Account Name" />
                    </Form.Item>
                    <Form.Item
                      label="Bank Name"
                      name="bank_name"
                      className="half-width"
                      validateTrigger={['onBlur']}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onSearch={handleBankSearch}
                        loading={bankLoading}
                        placeholder="Please select Bank"
                      >
                        {bankOptions.map((item, i) => (
                          <Select.Option key={i} value={item.abbreviation}>
                            {item.abbreviation} - {item.bank_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item className="half-width" label="BSB" name="bsb">
                      <InputMask
                        mask="999-999"
                        name="bsb"
                        placeholder="123-456"
                        className="ant-input"
                      />
                    </Form.Item>
                    <Form.Item
                      className="half-width"
                      label="Account Number"
                      name="account_number"
                      validateTrigger={['onBlur']}
                      rules={[
                        {
                          pattern: new RegExp(/^\d{0,9}$/),
                          message: 'Must be less than 10 digit number'
                        },
                        {
                          validator: (_, value) => {
                            if (
                              (/^\d{9}?$/.test(value) && /(\d)\1{8}/.test(value)) ||
                              (/^\d{8}?$/.test(value) && /(\d)\1{7}/.test(value)) ||
                              (/^\d{7}?$/.test(value) && /(\d)\1{6}/.test(value)) ||
                              (/^\d{6}?$/.test(value) && /(\d)\1{5}/.test(value)) ||
                              (/^\d{5}?$/.test(value) && /(\d)\1{4}/.test(value)) ||
                              (/^\d{4}?$/.test(value) && /(\d)\1{3}/.test(value)) ||
                              (/^\d{3}?$/.test(value) && /(\d)\1{2}/.test(value)) ||
                              (/^\d{2}?$/.test(value) && /(\d)\1{1}/.test(value))
                            ) {
                              return Promise.reject(
                                'Please enter valid Account Number. All digits of same number are not allowed.'
                              );
                            } else {
                              return Promise.resolve();
                            }
                          }
                        }
                      ]}
                    >
                      <Input placeholder="xxxxxxxx1234" />
                    </Form.Item>
                    <Form.Item
                      className="full-width"
                      name="fee_from_refund"
                      valuePropName="checked"
                      wrapperCol={{
                        span: 16
                      }}
                    >
                      <Checkbox>Pre-Payment of Refund</Checkbox>
                    </Form.Item>
                  </div>
                </div>
                <div className="entry-card">
                  <h3>Proof of Id for Client Verification (KYC)</h3>
                  <div className="form-box photos-section">
                    <div className="image-box" style={{ maxWidth: '16%' }}>
                      <ImgCrop grid>
                        <Upload
                          {...props}
                          id="profile"
                          onChange={handleProfileChange}
                          onPreview={onPreview}
                        >
                          Edit
                        </Upload>
                      </ImgCrop>
                    </div>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'wrap',
                        width: '61%',
                        gridGap: '10px',
                        justifyContent: 'center'
                      }}
                    >
                      <div className="crop-block" style={{ maxWidth: 'calc(50% - 10px)' }}>
                        Primary ID
                        <Upload
                          listType="picture-card"
                          id="primary"
                          fileList={primaryFileList}
                          onChange={handlePrimaryChange}
                          beforeUpload={() => {
                            return false;
                          }}
                          onPreview={onPrimaryPreview}
                          accept="image/*"
                        >
                          {primaryFileList.length < 2 && '+ Upload'}
                        </Upload>
                      </div>
                      <div className="crop-block" style={{ maxWidth: 'calc(50% - 10px)' }}>
                        Secondary ID
                        <Upload
                          listType="picture-card"
                          id="secondary"
                          fileList={secondaryfileList}
                          beforeUpload={() => {
                            return false;
                          }}
                          onChange={handleSecondaryChange}
                          onPreview={onSeondaryPreview}
                          accept="image/*"
                        >
                          {secondaryfileList.length < 2 && '+ Upload'}
                        </Upload>
                      </div>
                      <p style={{ fontSize: '10px' }}>
                        The ATO and the TPB do not recommend retaining id docs
                      </p>
                    </div>
                    <div className="crop-block" style={{ maxWidth: '17%' }}>
                      Authority Letter
                      <Upload
                        listType="picture-card"
                        id="authority"
                        fileList={authorityLetter}
                        beforeUpload={() => {
                          return false;
                        }}
                        onChange={handleAuthorityLetterChange}
                        accept="application/pdf"
                      >
                        {authorityLetter.length < 1 && '+ Upload'}
                      </Upload>
                    </div>
                    <div style={{ display: 'flex', gridGap: '30px', width: '100%' }}>
                      <Form.Item
                        className="half-width-div"
                        label="Verification Date"
                        name="date_verified"
                        rules={[
                          {
                            required: !!isVerificationRequired,
                            message: 'Please select date'
                          }
                        ]}
                      >
                        <DatePicker
                          format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                          disabledDate={disabledDate}
                          onChange={onVerificationChange}
                        />
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Verified By"
                        name="verified_by"
                        rules={[
                          {
                            required: !!isVerificationRequired,
                            message: 'Please select user'
                          }
                        ]}
                      >
                        <Select
                          optionFilterProp="children"
                          placeholder="Please select user"
                          onChange={onVerificationChange}
                        >
                          {userList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.first_name} {item.last_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        className="half-width"
                        label="Method"
                        name="verification_method"
                        rules={[
                          {
                            required: !!isVerificationRequired,
                            message: 'Please select method'
                          }
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Please select method"
                          onChange={onVerificationChange}
                        >
                          {verificationMethods.map((ele, idx) => (
                            <Select.Option key={idx} value={ele.value}>
                              {ele.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <Form.Item
                      label="Note"
                      name="notes"
                      style={{ width: '100%' }}
                      rules={[
                        {
                          required: !!isVerificationRequired,
                          message: 'Please add note'
                        },
                        {
                          pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                          message:
                            'Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                        },
                        {
                          min: 5,
                          message: 'The notes must be at least 5 characters.'
                        }
                      ]}
                    >
                      <TextArea
                        rows={4}
                        name="notes"
                        placeholder="You must verify 2 separate proof of identity docs.
                        How did you verify Individual's Full Name AND Either Residential Address OR Date of Birth?
                        Or Outline the reason (for eg. well-established client) Or Add Notes"
                        style={{ borderRadius: '10px' }}
                        onChange={onVerificationChange}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="entry-card">
                  <h3>Office Details</h3>
                  <div className="form-box">
                    <Form.Item className="half-width" label="Staff" name="staff_id">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onSearch={onSearch}
                        loading={stateLoading}
                        placeholder="Please select Staff"
                      >
                        {staffList.map((item, i) => (
                          <Select.Option key={i} value={item.id}>
                            {item.first_name} {item.last_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item className="half-width" label="Manager" name="manager_id">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onSearch={onSearch}
                        loading={managerListLoading}
                        placeholder="Please select Manager"
                      >
                        {managerList.map((item, i) => (
                          <Select.Option key={i} value={item.id}>
                            {item.first_name} {item.last_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item className="half-width" label="Tag" name="tag">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onSearch={onSearch}
                        placeholder="Please select Tag"
                      >
                        {tagList.map((item, i) => (
                          <Select.Option key={i} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="action-box">
                  <Button
                    disabled={loading}
                    loading={loading}
                    type="primary"
                    className="saveBtn"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <CancelButton />
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerAdd;
