import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Table, Modal, DatePicker, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, defaultPaginationLimit, get } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import { statementTableColumns } from './CustomersHelper';
import { formatNumber } from '../../APIConfig/Interceptor';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

const { RangePicker } = DatePicker;
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

const getDefaultDates = (from, to) => {
  if (from && to) {
    return { start_date: from, end_date: to };
  } else {
    const previousYear =
      new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
    const previousYearDate = `01-04-${previousYear}`;
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    const year = currentTime.getFullYear();
    const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
      Number(month),
      2
    )}-${year}`;
    return { start_date: previousYearDate, end_date: currentDate };
  }
};

const CustomerSummary = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const from = query.get('from');
  const to = query.get('to');
  const [statementData, setStatementData] = useState(null);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [pagingData, setPagingData] = useState({
    current: query.get('page') ? query.get('page') : 1
  });
  const [dateFilter, setDateFilter] = useState(() => getDefaultDates(from, to));
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState('asc');

  const getStatementData = useCallback(
    async (prevDate, currDate, value) => {
      try {
        setIsLoading(true);
        const { data } = await get(
          `${API.VIEW_STATEMENT}/${id}?page=${pagingData.current}&limit=${
            value ? value : limit
          }&start_date=${prevDate}&end_date=${currDate}&sort_column=${
            sortField ? sortField : ''
          }&sort_order=${sortOrder ? sortOrder : ''}`
        );
        setIsLoading(false);
        const { total, current_page, per_page, from, to } = data.data.statement;
        setStatementData(data.data);
        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page,
          from: from,
          to: to
        });
      } catch (e) {
        setStatementData({});
        setIsLoading(false);
        setPagingData({ current: 1 });
      }
    },
    [pagingData.current, sortField, sortOrder, id, limit]
  );

  useEffect(() => {
    const { start_date, end_date } = dateFilter;
    getStatementData(start_date, end_date);
  }, [getStatementData]);

  const onPageChange = (page, limit) => {
    const { start_date, end_date } = dateFilter;
    setPagingData({ ...pagingData, current: page });
    navigate(
      `/customer-detail/${id}?page=${page}&limit=${limit}&from=${start_date}&to=${end_date}`,
      {
        replace: true
      }
    );
  };

  useEffect(() => {
    const { start_date, end_date } = dateFilter;
    navigate(
      `/customer-detail/${id}?page=${pagingData.current}&limit=${limit}&from=${start_date}&to=${end_date}`,
      {
        replace: true
      }
    );
  }, []);

  const handleDateChange = (dates) => {
    const startDate = dates?.[0]?.format('DD-MM-YYYY');
    const endDate = dates?.[1]?.format('DD-MM-YYYY');
    setDateFilter({
      start_date: startDate,
      end_date: endDate
    });
  };

  const handleSortChange = (pagination, filters, sorter) => {
    setSortField(sorter?.field && sorter?.order != undefined ? sorter?.field : 'date');
    setSortOrder(sorter?.order === 'descend' ? 'desc' : 'asc');
  };

  const onFilterOk = () => {
    if (dateFilter?.start_date && dateFilter?.end_date) {
      const { start_date, end_date } = dateFilter;
      navigate(`/customer-detail/${id}?page=1&limit=${limit}&from=${start_date}&to=${end_date}`, {
        replace: true
      });
      getStatementData(start_date, end_date);
      setDatePickerVisible(false);
    } else {
      getStatementData('', '');
      setDatePickerVisible(false);
    }
  };

  const handleDownload = async () => {
    try {
      setIsDownloadLoading(true);
      const { start_date, end_date } = dateFilter;
      const { data } = await get(
        `${API.VIEW_STATEMENT}/${id}?start_date=${start_date}&end_date=${end_date}&option=1`
      );
      const { file } = data.data;
      if (file) {
        const response = await axios.get(`${file}`, {
          responseType: 'blob'
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var fileRes = file.split('/');
        a.setAttribute('download', fileRes[fileRes.length - 1] || 'client_statements.pdf');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      }
      setIsDownloadLoading(false);
    } catch (error) {
      setIsDownloadLoading(false);
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
    }
  };
  const handleChange = async (value) => {
    setLimit(value);
    const { start_date, end_date } = dateFilter;
    setPagingData({ ...pagingData, current: 1 });
    navigate(`/customer-detail/${id}?page=1&limit=${value}&from=${start_date}&to=${end_date}`, {
      replace: true
    });
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Statement History
          </h1>
          <div className="content-details">
            <div className="customer-summary">
              <div className="customer-name">
                <h2>{statementData?.customerDetails?.full_name}</h2>
                <div className="customer-summary-detail">
                  <h6>
                    CID:{' '}
                    {statementData?.customerDetails?.cid == '' ||
                    statementData?.customerDetails?.cid == null
                      ? 'ID' + statementData?.customerDetails?.cid
                      : 'ID' + statementData?.customerDetails?.cid}
                  </h6>
                  {statementData?.customerDetails?.crn && (
                    <h6>CRN: {statementData?.customerDetails?.crn}</h6>
                  )}
                </div>
              </div>
              <div className="balance">
                <h2>
                  Closing Balance: $
                  {formatNumber(
                    Number(statementData?.customerDetails?.previous_closing_balance) +
                      Number(statementData?.customerDetails?.transaction_balance)
                  ) || 0}
                </h2>
                <h6>
                  Opening Balance: $
                  {formatNumber(statementData?.customerDetails?.previous_closing_balance) || 0} +
                  Net Total of Transaction: $
                  {formatNumber(statementData?.customerDetails?.transaction_balance) || 0}
                </h6>
              </div>
              <div className="print-btn">
                <Button type="print" onClick={handleDownload} disabled={isDownloadLoading}>
                  {isDownloadLoading ? 'Loading...' : 'Download'}
                </Button>
              </div>
            </div>
            <div className="summary-filter" onClick={() => setDatePickerVisible(true)}>
              start: {dateFilter.start_date} - End: {dateFilter.end_date}
              <FontAwesomeIcon icon={faCalendarAlt} size="1x" />
            </div>
            <Table
              columns={statementTableColumns}
              dataSource={statementData?.statement?.data || []}
              loading={isLoading}
              pagination={{
                hideOnSinglePage: true,
                defaultPageSize: pagingData.pageSize,
                defaultCurrent: 1,
                showQuickJumper: true,
                onChange: (page) => onPageChange(page, limit),
                itemRender: PaginationItemRenderer,
                pageSize: limit,
                ...pagingData
              }}
              onChange={handleSortChange}
            />
            {statementData?.statement?.data?.length > 0 && (
              <div className="mt-2">
                <div className="col-md-12">
                  Showing&nbsp;
                  <Select
                    defaultValue={`${defaultPaginationLimit}`}
                    value={limit}
                    onChange={handleChange}
                    options={[
                      { value: '10', label: '10' },
                      { value: '25', label: '25' },
                      { value: '50', label: '50' },
                      { value: '100', label: '100' }
                    ]}
                  />
                  &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
                  {pagingData?.to} from {pagingData?.total} Records
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
        {datePickerVisible && (
          <Modal
            width={500}
            className="popup-without-header"
            closable
            centered
            open
            onOk={onFilterOk}
            onCancel={() => setDatePickerVisible(false)}
          >
            <h4>Select Start and End Date</h4>
            <RangePicker
              defaultValue={[
                dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
                dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
              ]}
              onChange={handleDateChange}
              disabledDate={disabledDate}
              format={dateFormat}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};
export default CustomerSummary;
