import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, Spin, DatePicker } from 'antd';
import { Input, Select } from 'antd';
import Form from '@component/Form';
import moment from 'moment';
import { Modal, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import UploadFile from '../../components/UploadFile';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer/Index';
import useBankStatementList from './useBankStatementList';
import { getTableColumns } from './FirmTrustHelper';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import '../../App.css';
import '../../assets/css/style.css';
import { API, deleteCall, get, post, fileUpload, defaultPaginationLimit } from '../../config';
import { fetchBasiqData, fetchYodleeData } from '../general-setting/Integration/IntegrationHelper';
import RefreshTransactionModal from './components/RefreshTransactionModal';

const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];
const { RangePicker } = DatePicker;

const { TextArea } = Input;

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const StatementPage = ({ setBalanceInfo, setDateFilter, dateFilter }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');

  const currentPage = query.get('page');

  const [showImport, setShowImport] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [basiqData, setBasiqData] = useState(null);
  const [yodleeData, setYodleeData] = useState(null);
  const [files, setFiles] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isImportFromBS, setIsImportFromBS] = useState(false);
  const [isImportFromBank, setIsImportFromBank] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [deletedItem, setDeletedItem] = useState('');
  const [bankDetails, setBankDetails] = useState();
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [localStatements, setLocalStatements] = useState([]);
  const [error, setError] = useState(null);

  const [bankAccountData, setBankAccountsData] = useState();
  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [selectedRow, setSelectedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  const [accountId, setAccountId] = useState('');
  const [showRefresh, setShowRefresh] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const sortOptions = [
    {
      label: 'Oldest First',
      value: 'ASC',
      field: 'date'
    },
    {
      label: 'Latest First',
      value: 'DESC',
      field: 'date'
    }
  ];

  const handleSorting = (item) => {
    setSortField(item?.field);
    setSortOrder(item?.value);
  };

  const {
    statements,
    isDataLoading,
    fetchStatement,
    pagingData,
    onPageChange,
    setPagingData,
    setIsDataLoading,
    bankTransactionAPIData,
    accountFetchOptions
  } = useBankStatementList(
    currentPage,
    dateFilter.start_date,
    dateFilter.end_date,
    setBalanceInfo,
    selectedTab,
    null,
    sortField,
    sortOrder
  );
  useEffect(() => {
    setLocalStatements(statements);
    setAccountId(accountFetchOptions?.[0]?.value);
    if (bankTransactionAPIData) {
      if (bankTransactionAPIData?.latest_statement?.date) {
        let lastDate = bankTransactionAPIData?.latest_statement?.date?.split(' ')?.[0]?.split('-');
        let startDate = `${padWithLeadingZeros(Number(lastDate[2]), 2)}-${padWithLeadingZeros(
          Number(lastDate[1]),
          2
        )}-${lastDate[0]}`;

        const currentTime = new Date();
        const month = currentTime.getMonth() + 1;
        const day = currentTime.getDate();
        const year = currentTime.getFullYear();
        const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
          Number(month),
          2
        )}-${year}`;
        setDateValue({ start_date: startDate, end_date: currentDate });
      } else if (bankTransactionAPIData?.accounting_firm?.transaction_start_date) {
        let lastDate = bankTransactionAPIData?.accounting_firm?.transaction_start_date?.split('-');
        let startDate = `${padWithLeadingZeros(Number(lastDate[2]), 2)}-${padWithLeadingZeros(
          Number(lastDate[1]),
          2
        )}-${lastDate[0]}`;

        const currentTime = new Date();
        const month = currentTime.getMonth() + 1;
        const day = currentTime.getDate();
        const year = currentTime.getFullYear();
        const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
          Number(month),
          2
        )}-${year}`;
        setDateValue({ start_date: startDate, end_date: currentDate });
      }
    }
  }, [statements, accountFetchOptions]);

  const getDefaultDates = () => {
    const previousYear =
      new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
    const previousYearDate = `01-04-${previousYear}`;
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    const year = currentTime.getFullYear();
    const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
      Number(month),
      2
    )}-${year}`;
    return { start_date: previousYearDate, end_date: currentDate };
  };

  const [dateValue, setDateValue] = useState(() => getDefaultDates());

  useEffect(() => {
    async function fetchData() {
      try {
        setIsDataLoading(true);
        const response = await fetchBasiqData();
        const yodlee = await fetchYodleeData();
        setYodleeData(yodlee);
        setBasiqData(response);
        setIsDataLoading(false);
      } catch (e) {
        setIsDataLoading(false);
      } finally {
        setIsDataLoading(false);
      }
    }
    fetchData();
  }, []);

  const getBankAccountsDetail = async () => {
    try {
      setIsSyncing(true);
      const { data } = !isImportFromBS && (await get(`${API.GET_BANK_ACCOUNTS}`));
      return data;
    } catch (e) {
      const { data } = e.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      }
      return null;
    } finally {
      setIsSyncing(false);
    }
  };

  const toggleDeleteModal = (id) => {
    setShowDelete(!showDelete);
    setDeletedItem(id);
  };

  const handleUserDelete = () => {
    if (deletedItem) {
      deleteCall(`${API.DELETE_BANK_STATEMENT}${deletedItem}`)
        .then((response) => {
          toast.success(response?.data?.message);
          if (response?.data?.errors?.myna_error) {
            toast.error(response?.data?.errors?.myna_error[0]);
          }
          if (response?.status === 200) {
            fetchStatement(currentPage, limit);
            setTimeout(() => {
              setDeletedItem('');
              setShowDelete(!showDelete);
            }, 700);
          }
        })
        .catch((e) => {
          const { data } = e.response;
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          toast.error(e?.data?.message);
        });
    }
  };

  const handleImportModal = async () => {
    try {
      const response = await fetchBasiqData();
      setBasiqData(response);
      const yodlee = await fetchYodleeData();
      setYodleeData(yodlee);
    } catch (e) {
      console.log('e :: ', e);
    } finally {
      setShowImport(true);
    }
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles([]);
  };

  const handleInsertTransaction = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleFileChange = (info) => {
    setFiles(info.fileList);
  };

  const handleBSImport = async (values) => {
    try {
      setIsSyncing(true);
      const response = await get(
        `${API.BASIQ_GET_TRANSACTIONS}?account_id=${values?.id}&institution_name=${values?.intitution_shortName}&account_no=${values?.accountNo}&from=${dateFilter?.start_date}&to=${dateFilter?.end_date}`
      );
      if (response?.data?.message) {
        toast.success(response.data.message);
      }
      fetchStatement(currentPage, limit);
    } catch (e) {
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    } finally {
      setIsSyncing(false);
      handleBSModal();
      setShowImport(false);
      setIsImportFromBank(false);
    }
  };

  const handleUpload = async () => {
    if (files && files.length) {
      setIsImportLoading(true);
      const formData = new FormData();
      files.forEach((file) => {
        if (file !== undefined) {
          formData.append('statement_file', file.originFileObj);
        }
      });
      const config = { Headers: { 'Content-Type': 'multipart/form-data' } };
      fileUpload(API.BANK_STATEMENT_IMPORT, formData, config)
        .then((res) => {
          if (res && res.status === 200) {
            setIsImportLoading(false);
            toast.success(res.data.message);
            fetchStatement(currentPage, limit);
            handleImportClose();
          }
        })
        .catch((err) => {
          const { data } = err.response;
          setIsImportLoading(false);
          toast.error(data?.message);
          toast.error(data?.errors && data.errors.statement_file && data.errors.statement_file[0]);
          toast.error(data?.errors && data.errors.myna_error && data.errors.myna_error[0]);
          setError(data?.errors && data.errors.myna_error && data.errors.myna_error[0]);
        });
    }
  };

  const handleBSModal = () => {
    setIsImportFromBS(!isImportFromBS);
    async function fetchData() {
      const userDetailData = await getBankAccountsDetail();
      setBankAccountsData(userDetailData);
    }
    fetchData();
  };

  const handleBankModal = (bankAccount) => {
    setIsImportFromBank(!isImportFromBank);
    setBankDetails(bankAccount);
  };

  const handlePanelChange = (val) => {
    if (val) {
      if (val && val.length) {
        const startDate = val[0].format('DD-MM-YYYY');
        const endDate = val[1].format('DD-MM-YYYY');
        setDateFilter({
          start_date: startDate,
          end_date: endDate
        });
      } else {
        setDateFilter({
          start_date: '',
          end_date: ''
        });
      }
      navigate(`/firm-bank-account?page=1&tab=statement`, {
        replace: true
      });
      setPagingData({ ...pagingData, current: 1 });
    } else {
      // setFromDate('');
      // setToDate('');
    }
  };

  const handleExport = async (format) => {
    setExportLoading(true);
    try {
      const { data } = await get(
        `${API.BANK_STATEMENT_LIST}?start_date=${dateFilter.start_date}&end_date=${
          dateFilter.end_date
        }&export=1&format=${format}&sort_column=${sortField ? sortField : ''}&sort_order=${
          sortOrder ? sortOrder : ''
        }`
      );
      const export_report_path = data.data.file;
      if (export_report_path) {
        var a = document.createElement('a');
        a.href = export_report_path;
        var file = export_report_path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'bank_transaction_report.pdf');
        a.setAttribute('target', '_blank');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const maskAccountId = (accountId) => {
    if (accountId) {
      /** Condition will only executes if accountId is *not* undefined, null, empty, false or 0*/
      const accountIdlength = accountId.length;
      const maskedLength = accountIdlength - 4; /** Modify the length as per your wish */
      let newString = accountId;
      for (let i = 0; i < accountIdlength; i++) {
        if (i < maskedLength) {
          newString = newString.replace(accountId[i], '*');
        }
      }
      return newString;
    } else return; /**Will handle if no string is passed */
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilter({
        start_date: startDate,
        end_date: endDate
      });
    } else {
      setDateFilter({
        start_date: '',
        end_date: ''
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const url = API.BANK_STATEMENT_LIST;
      values.date = values?.date ? moment(values?.date).format('DD-MM-YYYY') : '';
      const reqObj = { ...values, _method: 'POST' };
      const { data } = await post(url, reqObj);
      if (data.status === 200) {
        setShow(false);
        form.resetFields();
        fetchStatement(1, limit);
        toast.success(data.message);
      } else {
        handleError(data.errors);
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      handleError(errors);
    }
  };

  const handleError = (errors) => {
    Object.keys(errors).forEach((key) => {
      errors[key].forEach((error) => {
        toast.error(error);
      });
    });
  };

  function onSearch() {}

  const handleChange = async (value) => {
    setLimit(value);
    navigate(`/firm-bank-account?page=1&tab=statement&limit=${value}`, {
      replace: true
    });
    fetchStatement(1, value);
  };

  const handleDownload = () => {
    setIsLoadingDownload(true);
    get(API.DOWNLOAD_BANK_STATEMENT_TEMPLATE)
      .then((result) => {
        if (result.status === 200) {
          const { data } = result.data;
          var a = document.createElement('a');
          a.href = data.file;
          a.download = 'bank-statement-sample.csv';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
        }
        setIsLoadingDownload(false);
      })
      .catch((error) => {
        const { data } = error.response;
        setIsLoadingDownload(false);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };

  const toggleDeleteAllModal = () => {
    setShowDeleteAll(!showDeleteAll);
  };

  const handleDeleteAll = async () => {
    const formData = new FormData();
    if (selectedRow?.length > 0) {
      for (let i = 0; i < selectedRow?.length; i++) {
        formData.append(`ids[${i}]`, selectedRow[i]);
      }
    }
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BULK_DELETE_BANK_STATEMENT}`, formData);
      setLoading(false);
      if (data) {
        setSelectedRow([]);
        toast.success(data?.message);
        toggleDeleteAllModal();
        fetchStatement(1, limit);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRow,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.deleted_at !== null || record.bank_reconciliation?.[0]?.status !== '0' // Column configuration not to be checked
    })
  };

  const handleAccountChange = (item) => {
    setAccountId(item);
  };

  const toggleRefreshModal = () => {
    setShowRefresh(!showRefresh);
    if (!showRefresh) {
      if (accountId && accountFetchOptions) {
        if (accountFetchOptions?.find((i) => i.value === accountId)) {
          setAccountId(accountId);
        }
      }
    }
  };

  const refreshBankTransactions = async (startDate, endDate, accountId) => {
    if (basiqData?.connection_status === 0 && yodleeData?.connection_status === 0) {
      toast.warning('Please connect with bank feeds.');
    } else {
      try {
        const { data } = await get(
          `${
            API.YODLEE_GET_TRANSACTIONS
          }?start_date=${startDate}&end_date=${endDate}&yodlee_account_id=${
            accountId ? accountId : ''
          }`
        );
        return data;
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    setSelectedRow([]);
    if (dateValue?.start_date && dateValue?.end_date) {
      const refreshTransaction = await refreshBankTransactions(
        dateValue?.start_date,
        dateValue?.end_date,
        accountId
      );
      setRefreshLoading(false);
      toggleRefreshModal();
      if (refreshTransaction?.status === 200) {
        toast.success(refreshTransaction?.message);
        fetchStatement(currentPage, limit);
      }
    } else {
      toast.error('Start Date and End Date both are required');
    }
  };

  return (
    <>
      <div className="cms-page">
        {loading && <Spin />}
        <div className="table-top-btn">
          <div className="search-section">
            <RangePicker
              defaultValue={[
                dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
                dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
              ]}
              onChange={handlePanelChange}
              disabledDate={disabledDate}
              format={dateFormat}
            />
            <div className="oldest-sorting-body">
              <Select
                placeholder="Select Sort"
                defaultValue={sortOrder === 'DESC' ? sortOptions[1] : sortOptions[0]}
                onChange={(e, item) => {
                  handleSorting(item);
                }}
                style={{ minWidth: '200px', zIndex: '5', marginLeft: '15px' }}
                className={'sorting-data-select'}
                options={sortOptions}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'grey'
                  }
                })}
              />
            </div>
          </div>
          <div className="table-btn">
            <Button
              variant="primary"
              onClick={() => {
                toggleDeleteAllModal();
              }}
              disabled={selectedRow?.length === 0 ? true : false}
            >
              Archive Selected
            </Button>
            <Button variant="primary" onClick={handleInsertTransaction}>
              Insert Transaction
            </Button>
            {(basiqData?.connection_status !== 0 || yodleeData?.connection_status !== 0) && (
              <RefreshTransactionModal
                dateValue={dateValue}
                setDateValue={setDateValue}
                accountFetchOptions={accountFetchOptions}
                loading={refreshLoading}
                showRefresh={showRefresh}
                toggleRefreshModal={toggleRefreshModal}
                accountId={accountId}
                handleAccountChange={handleAccountChange}
                handleRefresh={handleRefresh}
              />
            )}
            <div className="border-btn">
              <DropdownButton className="info-icon">
                <Dropdown.Item>
                  <p>Date - Required (DD-MM-YYYY)</p>
                  <p>Narrative / Description - Required</p>
                  <p>Debit Amount - Required</p>
                  <p>Credit Amount - Required</p>
                  <p>Balance - Optional</p>
                </Dropdown.Item>
              </DropdownButton>
              <Button
                variant="link"
                disabled={isLoadingDownload}
                onClick={() => handleDownload()}
                className="download-template"
              >
                Download Template
              </Button>
              <Button variant="primary" onClick={handleImportModal}>
                Import
              </Button>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                disabled={exportLoading} /*onClick={handleExport}*/
              >
                {exportLoading ? 'Loading…' : 'Export'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport('xlsx')}>Excel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {error && <p className="text-danger">{error}</p>}
        <div className="statement-page-react-table">
          <Table
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            columns={getTableColumns(toggleDeleteModal)}
            dataSource={localStatements}
            loading={isDataLoading}
            pagination={{
              hideOnSinglePage: true,
              defaultCurrent: 1,
              showQuickJumper: true,
              onChange: (page) => {
                onPageChange(page, limit);
              },
              itemRender: PaginationItemRenderer,
              ...pagingData,
              pageSize: limit
            }}
          />
          {localStatements?.length > 0 && (
            <div className="col-md-12">
              Showing&nbsp;
              <Select
                defaultValue={`${defaultPaginationLimit}`}
                value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                onChange={handleChange}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' }
                ]}
              />
              &nbsp;Records per page I Showing {pagingData?.from} to&nbsp;
              {pagingData?.to} from {pagingData?.total} Records
            </div>
          )}
        </div>
        <Footer />
      </div>
      {showDeleteAll && (
        <Modal
          className="small-modal remove-popup"
          show={showDeleteAll}
          onHide={toggleDeleteAllModal}
          dialogClassName="modal-50w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton={toggleDeleteAllModal}></Modal.Header>
          <Modal.Body>
            <div className="remove-content">
              <p>
                Are you sure you want to archive {selectedRow?.length === 0 ? 'all' : 'selected'}{' '}
                bank transactions?
              </p>
              <div className="modal-action">
                <Button className="cancel" onClick={toggleDeleteAllModal}>
                  Cancel
                </Button>
                <Button onClick={handleDeleteAll}>OK</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        className="small-modal"
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Insert Transaction</Modal.Header>
        <Modal.Body>
          <div className="content-details">
            <Form
              form={form}
              initialValues={{
                date: '',
                description: '',
                transaction_type: null,
                amount: ''
              }}
              name="insertTransactionForm"
              onFinish={handleSubmit}
              layout="vertical"
            >
              <div className="input-boxes">
                <Form.Item
                  className="full-width"
                  label="Transaction Date"
                  name="date"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please select Transaction Date'
                    }
                  ]}
                >
                  <DatePicker
                    style={{ border: '1px solid #d9d9d9', borderRadius: '30px', width: '100%' }}
                    format={['DD-MM-YYYY', 'DD/MM/YYYY']}
                    placeholder="DD-MM-YYYY"
                    disabledDate={disabledDate}
                    defaultValue={form.getFieldValue('date') && moment()}
                  />
                </Form.Item>
                <Form.Item
                  className="full-width"
                  label="Transaction Type"
                  name="transaction_type"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please select Transaction type'
                    }
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={onSearch}
                    placeholder="Select Transaction Type"
                    allowClear
                  >
                    <Select.Option value={0} key={0}>
                      Debit
                    </Select.Option>
                    <Select.Option value={1} key={1}>
                      Credit
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  className="full-width"
                  label="Amount"
                  name="amount"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter amount'
                    },
                    {
                      pattern: new RegExp(/^\d*\.?\d*$/),
                      message: 'Amount should be number'
                    }
                  ]}
                >
                  <Input placeholder="Amount" name="amount" type="number" />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  className="full-width"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Description'
                    },
                    {
                      pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                      message:
                        'Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.'
                    }
                  ]}
                >
                  <TextArea rows={4} name="description" />
                </Form.Item>
                <div className="upload-btn-div">
                  <Button type="primary" htmlType="submit" className="upload">
                    Save
                  </Button>
                  <Button variant="secondary" className="cancel-btn" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showImport}
        onHide={handleImportClose}
        dialogClassName="modal-50w small-popup"
        arial-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Import Statement</Modal.Header>
        <Modal.Body>
          <div className="import-file-modal">
            <UploadFile
              isMultiple={true}
              isLoading={isImportLoading}
              handleFileChange={handleFileChange}
              handleUpload={handleUpload}
              handleCancel={handleImportClose}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isImportFromBS}
        onHide={handleBSModal}
        dialogClassName="modal-50w medium-popup-bank mid-popup"
        aria-labelledby="contained-modal-title-vcenter removebank-popup"
        centered
      >
        <Modal.Header>Please Select Account</Modal.Header>
        <Modal.Body>
          <div className="search-section">
            <RangePicker
              defaultValue={[
                dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
                dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
              ]}
              disabledDate={disabledDate}
              format={dateFormat}
              onChange={handleDateChange}
            />
          </div>
          <div className="remove-content bank-content">
            <Spin spinning={isSyncing} />
            {bankAccountData && bankAccountData?.data?.accounts?.length > 0
              ? bankAccountData?.data?.accounts?.map((item) => {
                  return (
                    <div
                      className="remove-card"
                      key={item?.id}
                      onClick={() => {
                        handleBankModal(item);
                      }}
                    >
                      <h5>
                        Name &amp; Bank:{' '}
                        <span>
                          <strong>{item?.name}</strong>/{item?.intitution_shortName}
                        </span>
                      </h5>
                      <p>
                        Account No. &amp; Type:{' '}
                        <span>
                          {maskAccountId(item?.accountNo)}/{item?.account_type}
                        </span>
                      </p>
                      <i>Last Updated: {item?.lastUpdated}</i>
                      <p className="status">{item?.status}</p>
                    </div>
                  );
                })
              : !isSyncing && (
                  <div
                    className="nodata"
                    style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}
                  >
                    No trust account found.
                  </div>
                )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isImportFromBank}
        onHide={handleBankModal}
        dialogClassName="modal-50w small-popup"
        aria-labelledby="contained-modal-title-vcenter removebank-popup"
        centered
      >
        <Modal.Header>Confirmation!</Modal.Header>
        <Modal.Body>
          <div className="remove-content confirmation-import">
            <p>Are your sure you want to Import Bank Feeds?</p>
            <div className="modal-action">
              <Button className="cancel" disabled={isSyncing} onClick={handleBankModal}>
                <Spin spinning={isSyncing} />
                Cancel
              </Button>
              <Button
                disabled={isSyncing}
                onClick={() => {
                  handleBSImport(bankDetails);
                }}
              >
                <Spin spinning={isSyncing} />
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {
        <Modal
          show={showDelete && deletedItem}
          onHide={() => toggleDeleteModal('')}
          dialogClassName="modal-50w small-popup bank-transaction-delete-modal"
          arial-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>Bank Transaction</Modal.Header>
          <div className="remove-content bank-transaction-delete-body">
            <p>Are your sure you want to delete this transaction?</p>
          </div>
          <div className="modal-action bank-transaction-delete-action">
            <Button className="cancel" onClick={() => toggleDeleteModal('')}>
              Cancel
            </Button>
            <Button onClick={handleUserDelete}>Delete</Button>
          </div>
        </Modal>
      }
    </>
  );
};

export default StatementPage;
