/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { API, fileUpload, get } from '../../../config';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import AddCard from './AddCard';
import { Spin } from 'antd';

const StripeCardUpdateForm = ({ subscriptionDetail }) => {
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  // const [editingCardId, setEditingCardId] = useState(null);
  const [showAddCard, setShowAddCard] = useState(null);

  const [cards, setCards] = useState([]);

  const toggleAddCard = () => {
    setShowAddCard(!showAddCard);
  };

  const fetchCardDetails = useCallback(async () => {
    try {
      setFetchLoading(true);
      const response = await get(`${API.STRIPE_CARD}`);

      if (response?.data?.data) {
        setCards(response?.data?.data);
        // toast.success(response.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setFetchLoading(false);
      } else {
        toast.error(error?.message);
        setFetchLoading(false);
      }
    } finally {
      setFetchLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCardDetails();
  }, [fetchCardDetails]);

  const handleDefault = async (event, card) => {
    event.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('payment_method_id', card?.id || '');
      formData.append('customer_id', card?.customer_id);
      formData.append('default', true);
      formData.append('type', 0);
      formData.append('_method', 'put');

      const { data } = await fileUpload(API.STRIPE_CARD, formData);
      if (data) {
        fetchCardDetails();
        toast.success(data?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  function isCardExpired(expiryStr) {
    const [expMonth, expYear] = expiryStr.split('-').map(Number);
    const expiryDate = new Date(expYear, expMonth, 0);

    const today = new Date();
    const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    return expiryDate < currentDate;
  }

  function isCardExpiringSoon(expiryStr, monthsBefore = 2) {
    const [expMonth, expYear] = expiryStr.split('-').map(Number);

    const expiryDate = new Date(expYear, expMonth, 0);

    const now = new Date();
    const warningDate = new Date(now.getFullYear(), now.getMonth() + monthsBefore, now.getDate());

    return expiryDate <= warningDate;
  }

  return (
    <div className="cms-page">
      <div className="content-details">
        {subscriptionDetail && (
          <div className="table-top-btn" style={{ alignItems: 'flex-start' }}>
            <div className="table-btn">
              <Button variant="primary" onClick={() => toggleAddCard()}>
                Add New
              </Button>
            </div>
          </div>
        )}
        {(loading || fetchLoading) && <Spin />}
        <div className="entry-card full-card">
          <div className="cards-container">
            {cards.length > 0 ? (
              cards.map((card, index) => (
                <>
                  <div key={card.card_id} className={`card-container`}>
                    <p className="card-name">
                      Card - {index + 1} ({card?.brand})
                    </p>

                    {/* Front Side - Show Card */}
                    <div
                      className={`card-wrapper ${
                        isCardExpired(`${card.exp_month}-${card.exp_year}`)
                          ? `expired ${card?.default ? 'defaultE' : ''}`
                          : isCardExpiringSoon(`${card.exp_month}-${card.exp_year}`)
                          ? `expiringSoon ${card?.default ? 'defaultES' : ''}`
                          : card?.default
                          ? 'default'
                          : ''
                      }`}
                    >
                      <div className={`credit-card2 front`}>
                        <div className="card-switch">
                          <input
                            type="checkbox"
                            id={`switch-${card.id}`}
                            className="switch-input"
                            checked={card.default}
                            disabled={card.default}
                            onChange={(e) => handleDefault(e, card)}
                          />
                          <label htmlFor={`switch-${card.id}`} className="switch-label"></label>
                        </div>
                        <div className="card-chip"></div>
                        <div className="card-number">•••• •••• •••• {card.last4}</div>
                        <div className="card-details">
                          <div className="card-brand">{card.brand}</div>
                          <div className="card-expiry">
                            Exp: {card.exp_month}/{card.exp_year}
                          </div>
                        </div>
                      </div>

                      {/* Back Side - Show Card Form ONLY if it's the active editing card */}
                      {/* {editingCardId === card.card_id && (
                      <div className="credit-card2 back">
                        <h4>Update Card</h4>
                        <form>
                          <CardElement className="card-input" />
                          <button
                            type="submit"
                            onClick={(event) => {
                              handleSubmit(event);
                            }}
                            disabled={loading}
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={() => setEditingCardId(null)}
                          >
                            Cancel
                          </button>
                        </form>
                      </div>
                    )} */}
                    </div>
                  </div>
                </>
              ))
            ) : (
              // <div className="form-box">
              //   <div className="form-group tri-width">
              //     <label>Card</label>
              //     <div className="card-input">
              //       <CardElement options={{ hidePostalCode: true }} />
              //     </div>
              //   </div>
              //   <button
              //     onClick={(event) => {
              //       handleSubmit(event);
              //     }}
              //     disabled={loading}
              //   >
              //     Update
              //   </button>
              // </div>
              <>
                <h6>No Cards are added</h6>
              </>
            )}
          </div>
          {showAddCard && (
            <AddCard
              showAddCard={showAddCard}
              toggleAddCard={toggleAddCard}
              fetchCardDetails={fetchCardDetails}
              cards={cards}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StripeCardUpdateForm;
