import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import Footer from '../../../components/Footer/Index';
import BackButton from '../../../components/Form/BackButton';
import '../../../App.css';
import '../../../assets/css/style.css';
// import DialogModal from '../../../components/Header/DialogModal';
// import { helpCenterArray } from '../../HelpCenter';
import RegisteredFirm from './RegisteredFirm';
import PartialRegisteredFirm from './PartialRegisteredFirm';

const tabsKey = {
  registeredFirm: 1,
  partialRegisteredFirm: 2
};

const { TabPane } = Tabs;
const RegisteredFirmTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  const defaultActiveKey =
    tabsKey[
      (selectedTab === 'registeredFirm?page=1' && 'registeredFirm') ||
        (selectedTab === 'partialRegisteredFirm?page=1' && 'partialRegisteredFirm') ||
        selectedTab
    ];

  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      navigate(`/admin/registered-firms?page=1&tab=${tabName}`, {
        replace: true
      });
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block admin-table admin-list-table">
          <h1 className="page-title">
            <BackButton />
            Registered Firms{' '}
            {/* <DialogModal
              title="Dashboard"
              p1={helpCenterArray[13].name}
              p2={helpCenterArray[13].text}
            /> */}
          </h1>
          <div className="content-details aba-tabs">
            <Tabs
              defaultActiveKey={defaultActiveKey.toString()}
              size="large"
              activeKey={defaultActiveKey.toString()}
              style={{ marginBottom: 32 }}
              onTabClick={onTabClick}
            >
              <TabPane tab="Registered Firms" key="1">
                <RegisteredFirm />
              </TabPane>
              <TabPane tab="Partial Registered Firms" key="2">
                <PartialRegisteredFirm />
              </TabPane>
            </Tabs>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RegisteredFirmTabs;
