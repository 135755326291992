import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../App.css';
import '../../assets/css/style.css';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import Header from '../../components/Header/Index';
import { Tabs } from 'antd';
import BackButton from '../../components/Form/BackButton';
import AuthorityLetter from './AuthorityLetter';
// import faqicon from '../../assets/images/bulb-icon.jpg';
import ABAReciept from './ABAReciept';
import { GetCookies } from '../../APIConfig/Interceptor';
import SubscriptionNotFound from '../firm/SubcriptionNotFound';
import ManualReceipt from './ManualReceipt';
import { useHelpCenter } from '../../shared/HelpCenterContext';
import DialogModal from '../../components/Header/DialogModal';

const tabsKey = {
  'authority-letter': 1,
  'aba-receipt': 2,
  'manual-receipt': 3
};

const TemplateSetting = () => {
  let subscription = GetCookies('_subscription');
  const { getHelpCenterObjectByType } = useHelpCenter();

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  const defaultActiveKey = tabsKey[selectedTab];
  const [helpText, setHelpText] = useState(
    defaultActiveKey === 1 ? getHelpCenterObjectByType('Authority Letter') : ''
  );
  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      if (activeKey == 1) {
        setHelpText(getHelpCenterObjectByType('Authority Letter'));
      } else if (activeKey == 2) {
        setHelpText();
      } else if (activeKey == 3) {
        setHelpText();
      }
      navigate(`/template-setting?tab=${tabName}`, {
        replace: true
      });
    }
  };

  const templateTabItem = [
    {
      key: '1',
      label: 'Authority Letter',
      children: <AuthorityLetter />
    },
    {
      key: '2',
      label: 'FFR Receipt',
      children: <ABAReciept />
    },
    {
      key: '3',
      label: 'Manual Receipt',
      children: <ManualReceipt />
    }
  ];

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <Header />

        {subscription ? (
          <>
            <SidebarNav />
            <div className="full-content-block">
              <h1 className="page-title">
                <BackButton />
                Template Settings
                {helpText && <DialogModal title={helpText[0]?.type} helpTextArray={helpText} />}
              </h1>
              <Tabs
                defaultActiveKey={defaultActiveKey.toString()}
                size="large"
                style={{ marginBottom: 32 }}
                onTabClick={onTabClick}
                items={templateTabItem}
              ></Tabs>
              <Footer />
            </div>
          </>
        ) : (
          <SubscriptionNotFound />
        )}
      </div>
    </div>
  );
};

export default TemplateSetting;
