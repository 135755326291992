import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import '../../App.css';
import '../../assets/css/style.css';
import './refund.module.css';
import { Button, Form, Modal } from 'react-bootstrap';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, fileUpload, get, post, putCall } from '../../config';
import AddTaxRefund from '../tax/AddTaxRefund';
import { toast } from 'react-toastify';
import ProcessRefundCard from './ProcessRefundCard';
import EmptyProcessRefundCard from './EmptyProcessRefundCard';
import { getCustomerDetail } from '../customer/CustomersHelper';
import AddATORefund from '../ato/AddATORefund';
import AddCommentReview from './AddCommentReview';
import UserSelect from '../../components/User/UserSelect';
import DialogModal from '../../components/Header/DialogModal';
// import { helpCenterArray } from '../HelpCenter';
import { Spin } from 'antd';
import { useHelpCenter } from '../../shared/HelpCenterContext';

const ProcessRefundIndividual = () => {
  const [data, setData] = useState([]);
  const { getHelpCenterObjectByType } = useHelpCenter();
  const [show, setShow] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const [accountId, setAccountId] = useState(0);
  const [showSearchIndexs, setShowSearchIndexs] = useState([]);
  const [sendReviewItems, setSendReviewItems] = useState([]);
  const [commentValue, setCommentValue] = useState('');
  const [taxSoftInfo, setTaxSoftInfo] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [, setSearchValue] = useState();
  const navigate = useNavigate();
  const [customerSearchData, setCustomerSearchData] = useState([]);
  const [selectedReviewItem, setSelectedReviewItem] = useState('');
  const [showComment, setShowComment] = useState(false);
  const [options, setOptions] = useState([]);
  const [noteErrors, setNoteErrors] = useState({});
  const [descriptionErrors, setDescriptionErrors] = useState({});
  const [isRefundsCardLoading, setIsRefundsCardLoading] = useState(false);
  const { id } = useParams();

  const [hasBankDetail, setHasBankDetail] = useState(true);
  const [customerId, setCustomerId] = useState(id ? id : null);

  const { state } = useLocation();

  const handleClose = () => {
    setShow(false);
    getProcessData(id);
  };

  useEffect(() => {
    if (id) {
      getProcessData(id);
    }
    handleSearchCustomer('');
  }, [id]);

  const handleSearchClose = (isUpdate = false) => {
    setShowSearchIndexs([-1]);
    if (isUpdate) {
      getProcessData(id);
    }
  };

  const getProcessData = async (id) => {
    setIsRefundsCardLoading(true);
    await get(`${API.SINGLE_PROCESS_REFUND}${id}`)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          setIsRefundsCardLoading(false);

          setData(response.data.data);
          setCustomerSearchData(response.data.data[0]);
          var matchedRecords = [];
          response.data.data.forEach((element) => {
            if (element.is_matched) {
              matchedRecords.push(element.id);
            }
          });
        }
      })
      .catch((e) => {
        setIsRefundsCardLoading(false);

        setData([]);
        const errorMessage = e.response?.data?.errors?.myna_error[0];
        if (errorMessage) {
          toast.error(errorMessage);
        }
      });
  };

  const showCommentbox = (id, item) => {
    setSelectedReviewItem(item);
    setShowComment(true);
  };

  const sendReview = (comment) => {
    const itemId = selectedReviewItem?.id;

    const selectedATONote = data[0]?.reconciliation?.find((item) => item?.id === itemId)?.ato?.note
      ? data[0]?.reconciliation?.find((item) => item?.id === itemId)?.ato?.note
      : '';

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('send_for_review', '1');
    formData.append('comment', comment);
    formData.append(
      'description',
      selectedReviewItem?.description ? selectedReviewItem?.description : ''
    );
    formData.append(
      'invoice_number',
      selectedReviewItem?.invoice_number ? selectedReviewItem?.invoice_number : ''
    );
    formData.append('note', selectedATONote ? selectedATONote : '');
    fileUpload(API.MATCH_UNMATCH_REVIEW + itemId, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => {
        if (response.data.status == '200') {
          setSendReviewItems([...sendReviewItems, itemId]);
          handleCommentClose();
          getProcessData(id);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };

  const matchData = (itemId, item) => {
    post(API.MATCH_UNMATCH_REVIEW + itemId, {
      match: '1',
      description: item?.description ? item?.description : '',
      invoice_number: item?.invoice_number ? item?.invoice_number : '',
      _method: 'PUT'
    })
      .then((response) => {
        if (response.data.status == '200') {
          handleClose();
          getProcessData(id);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        error['myna_error'] = 'There is some issue with request, please try after some time.';
      });
  };

  const unMatchData = (itemId, item) => {
    post(API.MATCH_UNMATCH_REVIEW + itemId, {
      match: '0',
      description: item?.description ? item?.description : '',
      invoice_number: item?.invoice_number ? item?.invoice_number : '',
      _method: 'PUT'
    })
      .then((response) => {
        if (response.data.status == '200') {
          handleClose();
          getProcessData(id);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        error['myna_error'] = 'There is some issue with request, please try after some time.';
      });
  };

  const processRefund = (itemId, index) => {
    const selectedItem = data[0]?.reconciliation?.find((el) => el?.id == itemId);
    const description = selectedItem?.description ? selectedItem?.description : '';
    const invoice_number = selectedItem?.invoice_number ? selectedItem?.invoice_number : '';

    let newData = data[0]?.reconciliation;

    const note = selectedItem?.ato?.note ? selectedItem?.ato?.note : '';
    if (description && description.length >= 4 && description.length <= 18) {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('process_refund', '1');
      formData.append('description', description);
      formData.append('invoice_number', invoice_number ? invoice_number : '');
      formData.append('note', note ? note : '');
      if (index > -1) {
        newData[index] = { ...newData[index], loading: true };
      }

      setData([{ ...data[0], reconciliation: [...data[0].reconciliation] }]);
      fileUpload(API.MATCH_UNMATCH_REVIEW + itemId, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(async (response) => {
          if (response.data.status == '200') {
            handleClose();
            if (index > -1) {
              newData[index] = { ...newData[index], loading: false };
            }
            setData([{ ...data[0], reconciliation: [...data[0].reconciliation] }]);
            await getProcessData(id);

            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          if (index > -1) {
            newData[index] = { ...newData[index], loading: false };
          }
          setData([{ ...data[0], reconciliation: [...data[0].reconciliation] }]);

          const { data } = error.response;
          toast.error(data?.message);
          toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
        });
    } else {
      toast.error(`Please enter valid Description`);
    }
  };

  const customerList = (response) => {
    return response.map((user) => {
      return {
        value: `${user.first_name} ${user.last_name}` || '',
        key: user.id,
        id: user.id,
        label: `${user.first_name} ${user.last_name}` || ''
      };
    });
  };

  const handleCommentClose = () => {
    setCommentValue('');
    setShowComment(false);
  };

  const handleSearchCustomer = (searchKeyWord) => {
    try {
      get(API.GET_CUSTOMERLIST + `?search=${searchKeyWord}`)
        .then((response) => {
          const customerData = customerList(response.data.data.data);
          setOptions(customerData);
          const searchValue = customerData && customerData.find((ele) => ele.id == id);
          if (searchValue) setSearchValue(searchValue);
          return customerData;
        })
        .catch((e) => {
          const errorMessage = e.response?.data?.errors?.myna_error[0];
          if (errorMessage) {
            toast.error(errorMessage);
          }
          setOptions([]);
          return [];
        });
    } catch (e) {
      const errorMessage = e.response?.data?.errors?.myna_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
      setOptions([]);
      return [];
    }
  };

  const handleCommentFinish = (values) => {
    setCommentValue(values.comment);
    sendReview(values.comment);
  };

  useEffect(() => {
    async function fetchData() {
      if (customerId) {
        setHasBankDetail(true);
        const customerDetailRes = await getCustomerDetail(customerId);
        const {
          customer_bank: { account_name, account_number, bsb }
        } = customerDetailRes;
        const hasDetail = !!account_name && !!account_number && !!bsb;

        setHasBankDetail(hasDetail);
        setSearchValue(options && options.find((el) => el.id == customerId));
        navigate(`/process-refund-individual/${customerId}`);
        setCustomerSearchData(customerDetailRes);
      }
    }
    fetchData();
  }, [customerId]);

  const handleSearchCustomerValue = async (customerId) => {
    setCustomerId(customerId);
  };

  const onSelect = (value) => {
    handleSearchCustomerValue(value);
    getProcessData(value);
  };

  const handleCloseTaxBox = () => {
    setShowTax(false);
    getProcessData(id);
  };

  const handleDescription = (e) => {
    const { id, value } = e.target;
    let newData = [...data];
    let index = newData && newData[0].reconciliation.findIndex((el) => el.id == id);
    if (index > -1) {
      newData[0].reconciliation[index] = {
        ...newData[0].reconciliation[index],
        description: value
      };
      setData(newData);
    }
  };

  const handleDescriptionErrors = (id, invoice_number) => {
    let errors = noteErrors;
    const item = data[0].reconciliation.find((el) => el.id == id);
    let regex = new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/);
    if (item) {
      const checkDescription = regex.exec(item?.description) ?? false;
      if (
        item.description == '' ||
        item.description == null ||
        item.description.toString().length < 4 ||
        checkDescription === false
      ) {
        errors[id] = 'Enter Valid Description';
      } else {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('status', item?.status);
        formData.append('description', item?.description ? item?.description : '');
        formData.append('invoice_number', invoice_number ? invoice_number : '');
        formData.append('note', item?.ato?.note ? item?.ato?.note : '');
        fileUpload(API.MATCH_UNMATCH_REVIEW + id, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.data.status == '200') {
              handleClose();
              getProcessData(id);
              toast.success(response.data.message);
            }
          })
          .catch((error) => {
            const { data } = error.response;
            toast.error(data?.message);
            toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          });

        delete errors[id];
      }
    } else {
      toast.error('Something went wrong.');
    }
    setDescriptionErrors({ ...errors });
  };

  const handleNote = (e) => {
    const { id, value } = e.target;
    let newData = [...data];
    let index = newData && newData[0].reconciliation.findIndex((el) => el.id == id);
    if (index > -1) {
      newData[0].reconciliation[index].ato = {
        ...newData[0]?.reconciliation[index]?.ato,
        note: value
      };
      setData(newData);
    }
  };

  const handleNoteErrors = (id) => {
    let errors = noteErrors;
    const item = data[0].reconciliation.find((el) => el.id == id);
    let regex = new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/);
    if (item) {
      const checkNote = regex.exec(item?.ato?.note) ?? false;
      if (item?.ato?.note && checkNote === false) {
        errors[id] = 'Enter Valid Note';
      } else {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('status', item?.status);
        formData.append('description', item?.description ? item?.description : '');
        formData.append('invoice_number', item?.invoice_number ? item?.invoice_number : '');
        formData.append('note', item?.ato?.note ? item?.ato?.note : '');
        fileUpload(API.MATCH_UNMATCH_REVIEW + id, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.data.status == '200') {
              handleClose();
              getProcessData(id);
              toast.success(response.data.message);
            }
          })
          .catch((error) => {
            const { data } = error.response;
            toast.error(data?.message);
            toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
          });
        delete errors[id];
      }
    } else {
      toast.error('Something went wrong.');
    }
    setNoteErrors({ ...errors });
  };

  const handlePrice = async (id, value) => {
    let newData = [...data];
    let index = newData && newData[0].reconciliation.findIndex((el) => el.id == id);
    if (index > -1) {
      newData[0].reconciliation[index].tax_software = {
        ...newData[0]?.reconciliation[index]?.tax_software,
        invoice_amount: value
      };

      setData(newData);
    }
  };

  const updatePrice = async (value, id) => {
    let values = {
      fee: value && value > 0 ? value : 0,
      id: id
    };
    const { data } = await post(API.PROCESS_REFUND_UPDATE_FEES, values);
    if (data.status === 200) {
      toast.success(data.message);
      handleCloseTaxBox();
    } else {
      toast.error(data?.message);
    }
  };

  const handleInvoiceNumber = async (id, value) => {
    let newData = [...data];
    let index = newData && newData[0].reconciliation.findIndex((el) => el.id == id);
    if (index > -1) {
      newData[0].reconciliation[index] = {
        ...newData[0]?.reconciliation[index],
        invoice_number: value ? value : ''
      };

      setData(newData);
    }
  };

  const updateInvoiceNumber = async (value, id, description, note) => {
    let values = {
      invoice_number: value ? value : '',
      description: description ? description : '',
      note: note ? note : ''
    };
    const { data } = await putCall(`${API.PROCESS_REFUND}/${id}`, values);
    if (data.status === 200) {
      toast.success(data.message);
      handleCloseTaxBox();
    } else {
      toast.error(data?.message);
    }
  };

  let helpTextArray = getHelpCenterObjectByType('Process Refund');

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          {isRefundsCardLoading && (
            <div
              style={{
                position: 'absolute',
                zIndex: 3,
                background: '#dbdbdb40',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 114px)',
                width: 'calc(100vw - 120px)'
              }}
            >
              <Spin />
            </div>
          )}
          <h1 className="page-title">
            <BackButton />
            {/* Refund Process Individually{' '} */}
            Process Refund
            <DialogModal title="Process Refund" helpTextArray={helpTextArray} />
          </h1>
          <div className="content-details refund-process refund-process-individual-page">
            <div className="table-top-btn">
              <div className="search-section process-search">
                <UserSelect
                  customerId={Number(id)}
                  formStyle={{ width: 252 }}
                  clientData={state?.clientData ? state?.clientData : ''}
                  onCustomerSelectionChange={onSelect}
                  clearable={false}
                />
                <span style={{ justifyContent: 'center', marginLeft: '4px' }}>or</span>
                <Link
                  to={{ pathname: '/new-customers' }}
                  className="action solid-btn"
                  style={{
                    padding: '0px 12px',
                    fontSize: '13px',
                    height: '28px',
                    lineHeight: '28px'
                  }}
                >
                  Add Client
                </Link>
              </div>
            </div>
            {!hasBankDetail && (
              <div style={{ display: 'flex', marginTop: '-20px' }}>
                <p className="text-danger">Selected client does not have bank details </p>{' '}
                <Link
                  to={{ pathname: `/customer/edit`, search: `?id=${customerId}` }}
                  style={{
                    color: '#005b98',
                    marginLeft: '5px',
                    fontWeight: '500'
                  }}
                >
                  Update bank details
                </Link>
              </div>
            )}
            <div style={{ width: '100%', height: '89%' }}>
              <div id="grid-wrapper" style={{ width: '100%', height: '100%' }}>
                <div id="myGrid" className="ag-theme-alpine ato-grid">
                  <Form>
                    <div className="form-box">
                      {data &&
                      data[0] &&
                      data[0].reconciliation &&
                      data[0].reconciliation.length > 0 ? (
                        data[0].reconciliation.map((item, index) => {
                          if (sendReviewItems.indexOf(item.id) >= 0) {
                            return null;
                          }
                          const total =
                            Number(item?.tax_software?.invoice_amount) +
                            Number(item?.tax_software?.disbursement);
                          return (
                            <ProcessRefundCard
                              index={index}
                              item={item}
                              showSearchIndexs={showSearchIndexs}
                              customerAccountDetails={data[0].customer_bank}
                              crn={data[0]?.crn}
                              setShow={setShow}
                              setShowTax={setShowTax}
                              setShowSearchIndexs={setShowSearchIndexs}
                              setAccountId={setAccountId}
                              setTaxSoftInfo={setTaxSoftInfo}
                              showCommentbox={showCommentbox}
                              sendReview={sendReview}
                              handleNote={handleNote}
                              handleNoteErrors={handleNoteErrors}
                              noteErrors={noteErrors}
                              unMatchData={unMatchData}
                              handleDescription={handleDescription}
                              handleDescriptionErrors={handleDescriptionErrors}
                              descriptionErrors={descriptionErrors}
                              matchData={matchData}
                              processRefund={processRefund}
                              setCustomerDetails={setCustomerDetails}
                              customerDetails={customerDetails}
                              handleSearchClose={handleSearchClose}
                              otherDetails={data[0]}
                              total={total}
                              key={item.id}
                              getProcessData={getProcessData}
                              id={id}
                              handlePrice={handlePrice}
                              updatePrice={updatePrice}
                              handleInvoiceNumber={handleInvoiceNumber}
                              updateInvoiceNumber={updateInvoiceNumber}
                              hasBankDetail={hasBankDetail}
                            />
                          );
                        })
                      ) : (
                        <EmptyProcessRefundCard
                          getProcessData={getProcessData}
                          setCustomerDetails={setCustomerDetails}
                          customerDetails={customerDetails}
                          customerData={
                            customerSearchData && Object.keys(customerSearchData).length > 0
                              ? customerSearchData
                              : state && state?.customerData.id
                          }
                        />
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-50w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>ATO Refund</Modal.Header>
            <Modal.Body>
              <AddATORefund
                fetchATORefundList={() => getProcessData(id)}
                setShow={setShow}
                customerId={id}
                onClose={handleClose}
              />
              <Button variant="secondary" className="reset" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={showComment}
            onHide={handleCommentClose}
            dialogClassName="modal-50w medium-popup review-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>Send For Review</Modal.Header>
            <Modal.Body>
              <AddCommentReview
                commentValue={commentValue}
                handleCommentFinish={handleCommentFinish}
              />
              <Button variant="secondary" className="reset" onClick={handleCommentClose}>
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
          <Modal
            show={showTax}
            onHide={handleCloseTaxBox}
            dialogClassName="modal-50w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>Tax Software</Modal.Header>
            <Modal.Body>
              <AddTaxRefund
                accountId={accountId}
                taxSoftInfo={taxSoftInfo}
                customerDetails={customerDetails}
                onClose={handleCloseTaxBox}
              />
              <Button variant="secondary" className="reset" onClick={handleCloseTaxBox}>
                Cancel
              </Button>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ProcessRefundIndividual;
