import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import ABA from './ABA';
import ABATransactions from './ABATransactions';
import { defaultPaginationLimit } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import DialogModal from '../../components/Header/DialogModal';
import { useHelpCenter } from '../../shared/HelpCenterContext';
// import { helpCenterArray } from '../HelpCenter';
// import { useEffect } from 'react';

const tabsKey = {
  'generate-aba': 1,
  'aba-transactions': 2
};

const ABATabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getHelpCenterObjectByType } = useHelpCenter();
  const query = new URLSearchParams(location.search);
  // let selectedTab = query.get('tab');
  const [selectedTab, setSelectedTab] = useState(query.get('tab'));
  const [activeKey, setActiveKey] = useState(tabsKey[selectedTab]);
  const [helpText, setHelpText] = useState(
    activeKey === 1 ? getHelpCenterObjectByType('Generate .ABA') : ''
  );

  const onTabClick = (activeKey) => {
    // selectedTab = Object.keys(tabsKey)[Object.values(tabsKey)[Number(activeKey)] - 1];
    setSelectedTab(Object.keys(tabsKey)[Object.values(tabsKey)[Number(activeKey)] - 1]);
    setActiveKey(String(activeKey));
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      if (activeKey == 1) {
        setHelpText(getHelpCenterObjectByType('Generate .ABA'));
      } else if (activeKey == 2) {
        setHelpText();
      }
      navigate(`/aba?page=1&tab=${tabName}&limit=${defaultPaginationLimit}`, {
        replace: true
      });
    }
  };

  // useEffect(async () => {
  //   const { data } = await get(`${API.GET_ABA_LIST}?page=1`);
  //   if (data.data.data?.length == 0) {
  //     const { data } = await get(`${API.ABA_FILE_LIST}?page=1`);
  //     if (data.data.data?.length > 0) {
  //       selectedTab = 'aba-transactions';
  //       setActiveKey(2);
  //       onTabClick(2);
  //     }
  //   }
  // }, []);

  const abaTabsItems = [
    {
      key: '1',
      label: 'Generate .ABA',
      children: (
        <ABA onTabClick={onTabClick} setSelectedTab={setSelectedTab} setActiveKey={setActiveKey} />
      )
    },
    {
      key: '2',
      label: 'Download .ABA file / View History',
      children: <ABATransactions />
    }
  ];

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            ABA {helpText && <DialogModal title={helpText[0]?.type} helpTextArray={helpText} />}
          </h1>
          <div className="content-details aba-tabs">
            <Tabs
              defaultActiveKey={activeKey.toString()}
              activeKey={activeKey.toString()}
              size="large"
              style={{ marginBottom: 32 }}
              onTabClick={onTabClick}
              items={abaTabsItems}
            ></Tabs>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ABATabs;
