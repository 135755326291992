import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import '../../App.css';
import '../../assets/css/style.css';
import { DatePicker, Button } from 'antd';
import { API, get } from '../../config';
import { toast } from 'react-toastify';
import TrialBalanceTable from './TrialBalanceTable';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DialogModal from '../../components/Header/DialogModal';
import { useHelpCenter } from '../../shared/HelpCenterContext';
import Workinprogrees from '../../components/Workinprogrees';

const disabledDate = (current) => {
  const currentDate = new Date(moment(moment().endOf('day')));
  const pastMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth()));
  return current && current > pastMonth;
};

const TrialBalanceReport = () => {
  const { getHelpCenterObjectByType } = useHelpCenter();
  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }
  const currentDate = new Date();
  const defaultDate = `${padWithLeadingZeros(
    Number(currentDate.getMonth() + 1),
    2
  )}-${currentDate?.getFullYear()}`;
  const query = new URLSearchParams(location.search);
  const to = query.get('to') && query.get('to') != null ? query.get('to') : defaultDate;
  const [hide, setHide] = useState(true);

  const [exportLoadingForTrialBalance, setExportLoadingForTrialBalance] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [dateFilter, setDateFilter] = useState(() => {
    return { end_date: to };
  });

  const [endMonth, setEndMonth] = useState();
  const navigate = useNavigate();

  const toggleHide = () => {
    setHide(!hide);
  };

  useEffect(() => {
    if (dateFilter?.end_date === moment(new Date()).format('MM-YYYY')) {
      setEndMonth(moment().format('DD/MM/YYYY'));
    } else {
      setEndMonth(
        moment(
          new Date(
            moment(dateFilter?.end_date, 'MM-YYYY').format('YYYY'),
            moment(dateFilter?.end_date, 'MM-YYYY').format('MM'),
            0
          )
        ).format('DD/MM/YYYY')
      );
    }
    navigate(`/trial-balance?to=${dateFilter?.end_date}`);
  }, [dateFilter]);

  const handleExportReport = async (type, format, reportType) => {
    const { end_date } = dateFilter;
    setExportLoadingForTrialBalance(true);

    setIsExport(true);
    let url;
    let EndMonth = moment(endMonth, 'DD-MM-YYYY').format('MM-YYYY');
    if (type === 0) {
      url = `${API.CA_TRIAL_BALANCE_REPORT}?end_month=${end_date}&export=1&display_zero=${
        hide ? 0 : 1
      }&report_type=${reportType}&format=${format}`;
    } else if (type === 1) {
      url = `${API.CA_LEDGER_ENTRIES_REPORT}?end_month=${end_date}&export=1&display_zero=${
        hide ? 0 : 1
      }&format=${format}`;
    } else if (type === 2) {
      url = `${API.CA_TRUST_TRIAL_BALANCE_REPORT}?end_month=${EndMonth}&export=1&display_zero=${
        hide ? 0 : 1
      }&format=${format}`;
    }
    try {
      const { data } = await get(url);
      const export_report_path = data.data.file;

      if (export_report_path) {
        const response = await axios.get(`${export_report_path}`, {
          responseType: 'blob'
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var file = export_report_path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'trial_balance_report.pdf');
        a.setAttribute('target', '_blank');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();

        setExportLoadingForTrialBalance(false);
      }
    } catch (e) {
      {
        type === 0 && setExportLoadingForTrialBalance(false);
      }
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  let helpTextArray = getHelpCenterObjectByType('Trial Balance');

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Trial Balance
            <DialogModal title="Trial Balance" helpTextArray={helpTextArray} />
          </h1>
          <div className="content-details">
            <div className="table-top-btn trial-btn">
              <div className="table-btn">
                <div className="table-btn">
                  <Button
                    disabled={exportLoadingForTrialBalance}
                    onClick={() => handleExportReport(2, 'pdf')}
                    type="print"
                  >
                    {exportLoadingForTrialBalance ? 'Loading…' : 'Export Trial Balance'}
                  </Button>
                  <Button onClick={toggleHide}>{hide ? 'Unhide $0.00' : 'Hide $0.00'}</Button>
                </div>
              </div>
            </div>

            <div className="mt-4" style={{ textAlign: 'right' }}>
              <DatePicker
                style={{ borderRadius: '15px' }}
                format={['MM-YYYY', 'MM/YYYY']}
                picker="month"
                disabledDate={disabledDate}
                value={
                  dateFilter.end_date == null ? moment() : moment(dateFilter.end_date, 'MM-YYYY')
                }
                onChange={(e) => {
                  setDateFilter({
                    end_date: moment(e._d).format('MM-YYYY')
                  });
                }}
                allowClear={false}
              />
            </div>
            <TrialBalanceTable
              isExport={isExport}
              setExportLoadingForTrialBalance={setExportLoadingForTrialBalance}
              dateFilter={dateFilter}
              endMonth={endMonth}
              hide={hide}
              setHide={setHide}
            />
          </div>
          {dateFilter && dateFilter?.end_date === "04-2025" && <Workinprogrees />}
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default TrialBalanceReport;
