/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Button } from 'antd';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faDownload, faEdit } from '@fortawesome/free-solid-svg-icons';
import { imageDefaultPrefixPath } from '../../config';
import '../users/users.module.css';

import { printComponent } from '../../helper/print';
import { download } from '../../helper/download';
import { formatNumber } from '../../APIConfig/Interceptor';
import { maskPostalCode } from '../../helper/fullNameGetter';
const AuthorityLetterModalContent = ({ data }) => {
  function createMarkup() {
    return { __html: data?.firm?.consent };
  }

  function printLetter() {
    return printComponent('print-authority');
  }

  const downloadLetter = async () => {
    try {
      download(`${imageDefaultPrefixPath}${data.file_data.path}`, 'new_authorityletter.pdf');
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  const { firm, customer } = data;

  const [accountfees, setAccountFees] = useState(
    formatNumber(customer?.fees_to_be_deducted ? customer?.fees_to_be_deducted : 0)?.replace(
      /,/g,
      ''
    )
  );

  const current = new Date();
  const currentYear = current.getFullYear();
  const currentMonth = String(current.getMonth() + 1).padStart(2, '0');
  const currentDay = String(current.getDate()).padStart(2, '0');
  const formattedDate = `${currentYear}-${currentMonth}-${currentDay}`;
  const [dateTransferred, setDateTransferred] = useState(formattedDate);

  const handleChange = (value) => {
    setAccountFees(value);
  };

  const handleDateChange = (value) => {
    setDateTransferred(value);
  };

  return (
    <>
      <div className="authority-letter" id="print-authority">
        <table style={{ width: '100%' }} cellSpacing="0" cellPadding="0" className="firm_details">
          <tr>
            <td style={{ float: 'left', width: 'auto' }}>
              {firm?.firm_logo &&
                (firm?.firm_logo == '' ? (
                  ''
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${firm.firm_logo}`}
                    style={{
                      width: 'auto',
                      height: '60px',
                      marginRight: '15px'
                    }}
                    alt="logo"
                  />
                ))}
            </td>
            {firm?.firm_logo ? (
              <></>
            ) : (
              <td style={{ height: '80px', float: 'left', width: 'auto', padding: '0' }}>
                <h2
                  style={{
                    color: '#005B98',
                    fontSize: '22px',
                    textTransform: 'uppercase',
                    position: 'relative',
                    fontWeight: '600',
                    margin: '0',
                    fontFamily: 'Poppins'
                  }}
                >
                  {firm?.firm_logo ? '' : firm?.firm_name ? firm?.firm_name : ''}
                  {firm?.firm_logo ? <> </> : <br />}
                  <span
                    style={{
                      margin: '0 0 15px',
                      width: '100%',
                      fontFamily: 'Poppins',
                      textTransform: 'none',
                      fontSize: '14px',
                      color: '#333',
                      display: 'block'
                    }}
                  >
                    {firm?.firm_email ? (
                      <a className="mailto-tel" href={`mailto:${firm?.firm_email}`}>
                        {' '}
                        {firm?.firm_email}
                      </a>
                    ) : (
                      ''
                    )}
                    <br />
                    {firm?.firm_phone ? (
                      <a className="mailto-tel" href={`tel:${firm?.firm_phone}`}>
                        {firm?.firm_phone}
                      </a>
                    ) : (
                      ''
                    )}
                    <br />
                    {firm?.firm_street ? firm.firm_street + ', ' : ''}
                    {firm?.firm_suburb ? firm.firm_suburb : ''}
                    {firm?.firm_state ? ', ' + firm.firm_state : ''}
                    {firm?.firm_postal_code &&
                    firm?.firm_postal_code != '0000' &&
                    firm?.firm_postal_code != 0
                      ? ', ' + maskPostalCode(Number(firm.firm_postal_code))
                      : ''}
                  </span>
                </h2>
              </td>
            )}
            <td
              style={{
                textAlign: 'right',
                color: '#000',
                lineHeight: '1.25',
                fontFamily: 'Poppins',
                padding: '0',
                float: 'right',
                width: '200px'
              }}
            >
              {firm?.financial_institution &&
                (firm?.financial_institution != 'Other' ? (
                  <img
                    src={`${imageDefaultPrefixPath}${firm?.financial_institution_logo}`}
                    style={{
                      width: 'auto',
                      height: '40px',
                      marginBottom: '0'
                    }}
                    alt="logo"
                  />
                ) : null)}
              {firm?.financial_institution != 'Other' ? (
                <p style={{ fontSize: '12px', marginBottom: '0' }}>
                  {firm?.firm_name} is{' '}
                  {/^[aeiouAEIOU]/.test(firm?.financial_institution) ? 'an ' : 'a '}
                  {firm?.financial_institution == 'IPA' ? 'IPA' : firm?.financial_institution}{' '}
                  practice.
                </p>
              ) : null}
            </td>
          </tr>
          {firm?.firm_logo ? (
            <tr>
              <td style={{ height: '80px', float: 'left', width: 'auto', padding: '0' }}>
                <h2
                  style={{
                    color: '#005B98',
                    fontSize: '22px',
                    textTransform: 'uppercase',
                    position: 'relative',
                    fontWeight: '600',
                    margin: '0',
                    fontFamily: 'Poppins'
                  }}
                >
                  {firm?.firm_logo ? '' : firm?.firm_name ? firm?.firm_name : ''}
                  {firm?.firm_logo ? <> </> : <br />}
                  <span
                    style={{
                      margin: '0 0 15px',
                      width: '100%',
                      fontFamily: 'Poppins',
                      textTransform: 'none',
                      fontSize: '14px',
                      color: '#333',
                      display: 'block'
                    }}
                  >
                    {firm?.firm_email ? (
                      <a className="mailto-tel" href={`mailto:${firm?.firm_email}`}>
                        {' '}
                        {firm?.firm_email}
                      </a>
                    ) : (
                      ''
                    )}
                    <br />
                    {firm?.firm_phone ? (
                      <a className="mailto-tel" href={`tel:${firm?.firm_phone}`}>
                        {firm?.firm_phone}
                      </a>
                    ) : (
                      ''
                    )}
                    <br />
                    {firm?.firm_street ? firm.firm_street + ', ' : ''}
                    <br />
                    {firm?.firm_suburb ? firm.firm_suburb : ''}
                    {firm?.firm_state ? ', ' + firm.firm_state : ''}
                    {firm?.firm_postal_code &&
                    firm?.firm_postal_code != '0000' &&
                    firm?.firm_postal_code != 0
                      ? ', ' + maskPostalCode(Number(firm.firm_postal_code))
                      : ''}
                  </span>
                </h2>
              </td>
            </tr>
          ) : (
            <></>
          )}
          <tr>
            <td
              colSpan="3"
              style={{
                fontWeight: '500',
                padding: '0 0 5px',
                width: '100%',
                fontFamily: 'Poppins',
                fontSize: '16px'
              }}
            >
              Authority to Deduct Fees From Refunds
            </td>
          </tr>
          <tr>
            <td
              colSpan="3"
              style={{
                fontWeight: '500',
                padding: '0 0 5px',
                width: '100%',
                fontFamily: 'Poppins',
                fontSize: '16px'
              }}
            >
              Firm’s Trust Bank Account Details: {firm?.firm_bank_name}, {firm?.firm_bsb},{' '}
              {firm?.firm_account_number}
            </td>
          </tr>
        </table>
        <div className="auth-client-details1" style={{ marginBottom: '0px' }}>
          <h4
            style={{
              color: '#001727',
              fontSize: '14px',
              fontWeight: '700',
              margin: '0 0 5px',
              lineHeight: '1',
              fontFamily: 'Poppins'
            }}
          >
            Clients Contact Details
          </h4>
          <table
            style={{
              border: '#333 1px solid',
              borderBottom: 'none',
              width: '100%'
            }}
            cellSpacing="0"
            cellPadding="0"
          >
            <tr>
              <td
                style={{
                  padding: '5px 10px',
                  width: '25%',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                Full Name
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
                colSpan="5"
              >
                {customer?.full_name}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: '5px 10px',
                  width: '25%',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                Postal/Street Address
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
                colSpan="5"
              >
                {customer?.street_address_1
                  ? customer?.street_address_1
                  : customer?.shipping_street}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: '5px 10px',
                  width: '25%',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                Suburb
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  width: '25%',
                  fontSize: '14px'
                }}
              >
                {customer?.street_address_1 ? customer?.suburb : customer?.shipping_suburb}
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  marginLeft: '5px',
                  borderLeft: '#333 1px solid',
                  fontWeight: '400',
                  fontFamily: 'Poppins',
                  width: '14%',
                  fontSize: '14px'
                }}
              >
                State
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  width: '20%',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                {customer?.street_address_1 ? customer?.state : customer?.shipping_state}
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  marginLeft: '5px',
                  borderLeft: '#333 1px solid',
                  fontWeight: '400',
                  fontFamily: 'Poppins',
                  width: '10%',
                  fontSize: '14px'
                }}
              >
                Postcode
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  width: '8%',
                  fontSize: '14px'
                }}
              >
                {customer?.street_address_1
                  ? customer?.postal_code &&
                    customer?.postal_code != '0000' &&
                    customer?.postal_code != 0
                    ? maskPostalCode(Number(customer?.postal_code))
                    : ''
                  : customer?.shipping_postal_code &&
                    customer?.shipping_postal_code != '0000' &&
                    customer?.shipping_postal_code != 0
                  ? maskPostalCode(Number(customer?.shipping_postal_code))
                  : ''}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: '5px 10px',
                  width: '25%',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                Email Address
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
                colSpan="1"
              >
                {customer?.email_address}
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  borderLeft: '#333 1px solid',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  textAlign: 'right',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                Phone Number
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
                colSpan="3"
              >
                {customer?.phone_number}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: '5px 10px',
                  width: '25%',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                Bank Account Name
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
                colSpan="5"
              >
                {customer?.bank_account_name}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: '5px 10px',
                  width: '25%',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                BSB
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                {customer?.bsb ? customer?.bsb : ''}
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  borderLeft: '#333 1px solid',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  textAlign: 'right',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
              >
                Account Number
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
                colSpan="3"
              >
                {customer?.account_number ? customer?.account_number : ''}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: '5px 10px',
                  width: '25%',
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  marginRight: '5px',
                  fontWeight: '400',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
                colSpan="3"
              >
                Amount to be Deducted to cover Accounting fees
              </td>
              <td
                style={{
                  padding: '5px 10px',
                  borderBottom: '#333 1px solid',
                  fontFamily: 'Poppins',
                  fontSize: '14px'
                }}
                colSpan="3"
              >
                <span className="currencyinput">
                  $
                  <CurrencyInput
                    type="text"
                    decimalsLimit={2}
                    onValueChange={(value) => handleChange(value)}
                    value={accountfees}
                    style={{
                      width: '91%',
                      border: 'none',
                      fontFamily: 'Poppins',
                      fontSize: '14px'
                    }}
                  />
                </span>
                <FontAwesomeIcon id="editIcon" icon={faEdit} style={{ marginRight: '5px' }} />
              </td>
            </tr>
          </table>
        </div>
        <h4
          style={{
            color: '#001727',
            fontSize: '14px',
            fontWeight: '700',
            margin: '10px 0 5px',
            lineHeight: '1',
            fontFamily: 'Poppins'
          }}
        >
          Client Authority and Consent:
        </h4>
        <div dangerouslySetInnerHTML={createMarkup()} style={{ fontFamily: 'Poppins' }} />
        <table
          style={{ width: '100%', padding: '10px 0 0', marginTop: '10px' }}
          cellSpacing="0"
          cellPadding="0"
        >
          <tr>
            <td style={{ width: '30%' }}>
              <p
                style={{
                  margin: '0',
                  minHeight: '23px',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              ></p>
              <i
                style={{
                  fontStyle: 'normal',
                  borderTop: '#333 1px solid',
                  display: 'block',
                  fontWeight: '600',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              >
                Signature
              </i>
            </td>
            <td style={{ width: '2%' }}></td>
            <td style={{ width: '46%' }}>
              <p
                style={{
                  margin: '0',
                  minHeight: '23px',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              >
                {customer?.full_name}
              </p>
              <i
                style={{
                  fontStyle: 'normal',
                  borderTop: '#333 1px solid',
                  display: 'block',
                  fontWeight: '600',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              >
                Name
              </i>
            </td>
            <td style={{ width: '2%' }}></td>
            <td style={{ width: '20%' }}>
              <p
                style={{
                  margin: '0',
                  minHeight: '23px',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              >
                &nbsp;
              </p>
              <i
                style={{
                  fontStyle: 'normal',
                  borderTop: '#333 1px solid',
                  display: 'block',
                  fontWeight: '600',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              >
                Date
              </i>
            </td>
          </tr>
        </table>
        <table
          style={{
            width: '100%',
            margin: '10px 0 0',
            border: '#333 1px solid',
            borderBottom: 'none'
          }}
          cellSpacing="0"
          cellPadding="0"
        >
          <tr>
            <td colSpan="3">
              <p
                style={{
                  margin: '0',
                  display: 'flex',
                  flexFlow: 'wrap',
                  borderBottom: '#333 1px solid',
                  color: '#333',
                  lineHeight: '30px',
                  padding: '0 10px',
                  fontFamily: 'Poppins',
                  fontWeight: '500',
                  fontSize: '14px'
                }}
              >
                Office Use Only
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '33.33%',
                margin: '0',
                borderBottom: '#333 1px solid',
                padding: '0',
                fontFamily: 'Poppins',
                fontSize: '12px'
              }}
            >
              <label
                style={{
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  margin: '0 10px 0 0',
                  lineHeight: '30px',
                  padding: '0 10px',
                  width: 'auto',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              >
                Transferred Amount
              </label>
              {customer?.net_remittance &&
                (customer?.net_remittance < 0 ? (
                  <span style={{ color: '#F00' }}>${customer?.net_remittance}</span>
                ) : (
                  '$' + customer?.net_remittance
                ))}
            </td>
            <td
              style={{
                width: '33.33%',
                margin: '0',
                borderBottom: '#333 1px solid',
                padding: '0',
                fontFamily: 'Poppins',
                fontSize: '12px'
              }}
            >
              <label
                style={{
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  margin: '0 10px 0 0',
                  lineHeight: '30px',
                  padding: '0 10px',
                  width: 'auto',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              >
                Date Transferred
              </label>
              <span className="dateinput">
                <input
                  type="date"
                  id="dateTransferred"
                  onChange={(e) => handleDateChange(e.target.value)}
                  value={dateTransferred}
                  style={{
                    width: '45%',
                    border: 'none',
                    fontFamily: 'Poppins',
                    fontSize: '12px'
                  }}
                />
              </span>
              <FontAwesomeIcon id="editIconDate" icon={faEdit} style={{ marginRight: '5px' }} />
            </td>
            <td
              style={{
                width: '33.33%',
                margin: '0',
                borderBottom: '#333 1px solid',
                padding: '0',
                fontFamily: 'Poppins',
                fontSize: '12px'
              }}
            >
              <label
                style={{
                  textAlign: 'right',
                  borderRight: '#333 1px solid',
                  margin: '0 10px 0 0',
                  lineHeight: '30px',
                  padding: '0 10px',
                  width: 'auto',
                  fontFamily: 'Poppins',
                  fontSize: '12px'
                }}
              >
                Staff Signature
              </label>
            </td>
          </tr>
        </table>
      </div>
      <div
        className="print-btn-div"
        style={{ padding: '0 0 20px', display: 'block', textAlign: 'center' }}
      >
        <Button onClick={() => printLetter()} type="primary" className="btn btn-primary printbtn">
          <FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
          Print
        </Button>
        {data?.file_data?.path && (
          <Button onClick={downloadLetter} type="primary" className="btn btn-primary printbtn">
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />
            Download
          </Button>
        )}
      </div>
    </>
  );
};
export default AuthorityLetterModalContent;
