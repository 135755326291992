/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import truncate from 'html-truncate';
import DOMPurify from "dompurify"

const LongText = ({ content, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const sanitizedHTML = DOMPurify?.sanitize(content);

  // Function to toggle between expanded and collapsed view
  const toggleView = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to return a truncated version of HTML content
  const getTruncatedContent = () => {

    return truncate(sanitizedHTML, maxLength);
  };

  return (
    <div className="long-text-truncate">
      <div
        dangerouslySetInnerHTML={{
          __html: isExpanded ? sanitizedHTML : getTruncatedContent()
        }}
      />
      {/* Show the toggle link */}
      {content?.length > maxLength && (
        <a href="#" onClick={toggleView} className="view-more-btn">
          {isExpanded ? 'View Less' : 'View More'}
        </a>
      )}
    </div>
  );
};
export default LongText;
