import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import { Button } from 'react-bootstrap';
import { Table, Spin, Modal, DatePicker, Select, Divider, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import { API, fileUpload, post, get, defaultPaginationLimit } from '../../config';
import '../../App.css';
import '../../assets/css/style.css';
import { PaginationItemRenderer } from '../../shared/PaginationItemRenderer';
import { statementReportTableColumns } from './reportHelper';
import { toast } from 'react-toastify';
import axios from 'axios';
import DialogModal from '../../components/Header/DialogModal';
// import { helpCenterArray } from '../HelpCenter';
import { useHelpCenter } from '../../shared/HelpCenterContext';

const { RangePicker } = DatePicker;
// const { Option } = Select;
const dateFormat = ['DD-MM-YYYY', 'DD/MM/YYYY'];
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

const getDefaultDates = (from, to) => {
  if (from && to) {
    return { start_date: from, end_date: to };
  } else {
    const previousYear =
      new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
    const previousYearDate = `01-04-${previousYear}`;
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    const year = currentTime.getFullYear();
    const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
      Number(month),
      2
    )}-${year}`;

    return { start_date: previousYearDate, end_date: currentDate };
  }
};

const CustomerStatementReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getHelpCenterObjectByType } = useHelpCenter();
  const query = new URLSearchParams(location.search);
  const from = query.get('from');
  const to = query.get('to');
  const [limit, setLimit] = useState(
    query.get('limit') ? query.get('limit') : defaultPaginationLimit
  );
  const [fetching, setFetching] = useState(false);
  const [statementReportData, setStatementReportData] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = query.get('page') ? parseInt(query.get('page')) : 1;
  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const [exportLoading, setExportLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(() => getDefaultDates(from, to));
  const [selectedMainCustomer, setSelectedMainCustomer] = useState([]);

  const compareArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const handleApply = (value) => {
    setSelectedMainCustomer(value);
    const { start_date, end_date } = dateFilter;
    navigate(`/customer-transaction?page=1&from=${start_date}&to=${end_date}`, {
      replace: true
    });
    console.log('apply');
    !compareArrays(selectedCustomer, selectedMainCustomer) &&
      getStatementReportData(dateFilter.start_date, dateFilter.end_date, value, '1', limit);
  };

  const handleCustomerSelect = (value) => {
    setSelectedCustomer(value);
  };

  const handleCustomerSearch = (value) => {
    setFetching(true);
    get(`${API.SEARCH_CUSTOMER}?search=${value}`)
      .then((result) => {
        if (result.status === 200) {
          const { data } = result.data;
          setCustomerList(data.customers);
          setFetching(false);
        }
      })
      .catch((error) => {
        const { data } = error.response;
        setFetching(false);
        toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      });
  };

  const fetchCustomers = useCallback(async (prevDate, currDate) => {
    try {
      setCustomerLoading(true);
      const { data } = await get(
        `${
          API.GET_CUSTOMER_WITH_TRANSACTION
        }?page=${1}&start_date=${prevDate}&end_date=${currDate}&limit=-1`
      );
      setCustomerLoading(false);
      const customersList = data.data;
      setCustomerList(customersList);
      return data.data;
    } catch (e) {
      const { data } = e;
      setCustomerLoading(false);
      setCustomerList([]);
      toast.error(data && data.errors && data.errors.myna_error && data.errors.myna_error[0]);
    }
  }, []);

  const getStatementReportData = useCallback(
    async (
      prevDate,
      currDate,
      selectedCustomerList,
      currentPageValue,
      limit = defaultPaginationLimit
    ) => {
      try {
        setIsLoading(true);
        const formData = new FormData();
        selectedCustomerList?.forEach((customer) => {
          if (customer) {
            formData.append('customer_ids[]', customer);
          }
        });
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const { data } = selectedCustomerList
          ? await fileUpload(
              `${API.CUSTOMER_TRANSACTION_REPORT}?page=${
                currentPageValue ? currentPageValue : pagingData.current
              }&start_date=${prevDate}&end_date=${currDate}&limit=${limit}`,
              formData,
              config
            )
          : await post(
              `${API.CUSTOMER_TRANSACTION_REPORT}?page=${
                currentPageValue ? currentPageValue : pagingData.current
              }&start_date=${prevDate}&end_date=${currDate}&limit=${limit}`
            );
        setIsLoading(false);
        const { total, current_page, per_page } = data.data.statements;
        setStatementReportData(data.data);

        setPagingData({
          total,
          current: current_page,
          defaultPageSize: per_page
        });
        // fetchCustomers(prevDate, currDate);
      } catch (e) {
        setStatementReportData({});
        setIsLoading(false);
        setPagingData({ current: currentPage || 1 });
      }
    },
    []
  );

  useEffect(() => {
    const { start_date, end_date } = dateFilter;
    getStatementReportData(
      start_date,
      end_date,
      selectedMainCustomer,
      currentPage,
      query.get('limit') ? query.get('limit') : defaultPaginationLimit
    );
    fetchCustomers(start_date, end_date);
  }, []);

  useEffect(() => {
    if (from && to) {
      setDateFilter({
        start_date: from,
        end_date: to
      });
    }
  }, [from, to]);

  const onPageChange = (page) => {
    setPagingData({ ...pagingData, current: page });
    const { start_date, end_date } = dateFilter;
    navigate(
      `/customer-transaction?page=${page}&from=${start_date}&to=${end_date}&limit=${limit}`,
      {
        replace: true
      }
    );
    getStatementReportData(start_date, end_date, selectedMainCustomer, page, limit);
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length) {
      const startDate = dates[0].format('DD-MM-YYYY');
      const endDate = dates[1].format('DD-MM-YYYY');
      setDateFilter({
        start_date: startDate,
        end_date: endDate
      });
      navigate(`/customer-transaction?page=1&from=${startDate}&to=${endDate}&limit=${limit}`, {
        replace: true
      });
    } else {
      setDateFilter({
        start_date: '',
        end_date: ''
      });
    }
  };

  const onFilterOk = () => {
    const { start_date, end_date } = dateFilter;
    getStatementReportData(start_date, end_date, selectedMainCustomer, '1', limit);
    setDatePickerVisible(false);
  };

  const handleExportReport = async () => {
    const { start_date, end_date } = dateFilter;
    setExportLoading(true);
    try {
      const formData = new FormData();
      selectedMainCustomer?.forEach((customer) => {
        if (customer) {
          formData.append('customer_ids[]', customer);
        }
      });
      const { data } = await fileUpload(
        `${API.CUSTOMER_TRANSACTION_REPORT}?page=${pagingData.current}&start_date=${start_date}&end_date=${end_date}&export=1`,
        formData,
        {}
      );
      const { export_statement_path } = data.data;
      if (export_statement_path) {
        const response = await axios.get(`${export_statement_path}`, {
          responseType: 'blob'
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement('a');
        a.href = url;
        var file = export_statement_path.split('/');
        a.setAttribute('download', file[file.length - 1] || 'client_transaction_report.pdf');
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (error) {
      console.log('error :>> ', error);
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(data.errors && data.errors.myna_error && data.errors.myna_error[0]);
      } else {
        toast.error('Something went wrong');
      }
      setExportLoading(false);
    }
  };
  const handleChange = async (value) => {
    const { start_date, end_date } = dateFilter;
    setLimit(value);
    await getStatementReportData(start_date, end_date, selectedMainCustomer, '1', value);
    navigate(`/customer-transaction?page=1&from=${start_date}&to=${end_date}&limit=${value}`, {
      replace: true
    });
  };

  const getColumnData = () => {
    return statementReportTableColumns;
  };

  useEffect(() => {}, [dateFilter]);

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      handleCustomerSearch(nextValue);
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSave(e);
  };

  const handleReset = () => {
    setSelectedCustomer([]);
    setSelectedMainCustomer([]);
    const { start_date, end_date } = dateFilter;
    navigate(`/customer-transaction?page=1&from=${start_date}&to=${end_date}`, {
      replace: true
    });
    getStatementReportData(dateFilter.start_date, dateFilter.end_date, [], '1', limit);
  };

  let helpTextArray = getHelpCenterObjectByType('Client Transaction');

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Cashbook/Client&apos;s Transactions{' '}
            <DialogModal
              title="Client Transaction"
              helpTextArray={helpTextArray}
            />
          </h1>
          <div className="content-details">
            <Spin spinning={isLoading}>
              <div className="table-top-btn">
                <div className="search-section client-multiple-selection">
                  <Select
                    labelInValue={false}
                    mode="multiple"
                    style={{ minWidth: '200px' }}
                    filterOption={false}
                    value={selectedCustomer}
                    allowClear
                    onClear={() => handleReset()}
                    notFoundContent={fetching || customerLoading ? <Spin size="small" /> : null}
                    onChange={handleCustomerSelect}
                    onSearch={(e) => handleSearchChange(e)}
                    placeholder="Please select client(s)"
                    maxTagPlaceholder={
                      selectedMainCustomer?.length > 0
                        ? `${selectedMainCustomer?.length} client(s) selected`
                        : 'Please select client(s)'
                    }
                    maxTagCount={0}
                    onBlur={() => handleApply(selectedCustomer)}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space
                          style={{
                            padding: '0 8px 4px',
                            justifyContent: 'flex-end',
                            width: '100%'
                          }}
                        >
                          <Button
                            className="multiple-selection-action-button"
                            type="text"
                            onClick={() => handleApply(selectedCustomer)}
                          >
                            Apply
                          </Button>
                          <Button
                            className="multiple-selection-action-button"
                            type="text"
                            onClick={() => handleReset()}
                          >
                            Reset
                          </Button>
                        </Space>
                      </>
                    )}
                    options={customerList.map((item) => ({
                      label: (item.first_name ?? '') + ' ' + (item.last_name ?? ''),
                      value: item.id
                    }))}
                  />
                  {/* {customerList.map(
                        (item) =>
                          (item.first_name || item.last_name) && (
                            <Option key={item.id} value={item.id}>
                              {(item.first_name ?? '') + ' ' + (item.last_name ?? '')}
                            </Option>
                          )
                      )} */}
                  {/* </Select> */}
                </div>
                <div className="table-btn">
                  <div
                    className="summary-filter"
                    style={{ margin: '0px' }}
                    onClick={() => setDatePickerVisible(true)}
                  >
                    {dateFilter.start_date &&
                      dateFilter.end_date &&
                      'start:' +
                        moment(dateFilter.start_date, 'DD-MM-YYYY').format('DD-MM-YYYY') +
                        ' - End: ' +
                        moment(dateFilter.end_date, 'DD-MM-YYYY').format('DD-MM-YYYY')}
                    <FontAwesomeIcon icon={faCalendarAlt} size="1x" />
                  </div>
                  <Button
                    onClick={() => handleExportReport()}
                    className={exportLoading ? 'disabled' : ''}
                    type="print"
                  >
                    {exportLoading ? 'Loading…' : 'Export'}
                  </Button>
                </div>
              </div>

              <Table
                columns={getColumnData()}
                dataSource={statementReportData?.statements?.data || []}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: pagingData.defaultPageSize,
                  defaultCurrent: 1,
                  showQuickJumper: true,
                  onChange: (page) => onPageChange(page),
                  itemRender: PaginationItemRenderer,
                  ...pagingData
                }}
              />

              <div>
                {statementReportData?.statements?.data?.length > 0 && (
                  <div className="col-md-12">
                    Showing&nbsp;
                    <Select
                      defaultValue={`${defaultPaginationLimit}`}
                      value={query.get('limit') ? query.get('limit') : `${defaultPaginationLimit}`}
                      onChange={handleChange}
                      options={[
                        { value: '10', label: '10' },
                        { value: '25', label: '25' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' }
                      ]}
                    />
                    &nbsp;Records per page I Showing {statementReportData?.statements?.from} to{' '}
                    {statementReportData?.statements?.to} from{' '}
                    {statementReportData?.statements?.total} Records
                  </div>
                )}
              </div>
            </Spin>
          </div>
          <Footer />
        </div>
        {datePickerVisible && (
          <Modal
            width={500}
            className="popup-without-header"
            closable
            centered
            open
            onOk={onFilterOk}
            onCancel={() => setDatePickerVisible(false)}
          >
            <h4>Select Start and End Date</h4>
            <RangePicker
              defaultValue={[
                dateFilter.start_date ? moment(dateFilter.start_date, dateFormat[0]) : '',
                dateFilter.end_date ? moment(dateFilter.end_date, dateFormat[0]) : ''
              ]}
              disabledDate={disabledDate}
              format={dateFormat}
              onChange={handleDateChange}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};
export default CustomerStatementReport;
