import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, defaultPaginationLimit, get } from '../../config.js';

const preprocessStatementList = (data) => {
  const items = [];
  for (let i = 0; i < data?.length; i++) {
    items.push({
      key: i,
      transaction_id: data[i]?.id ? data[i]?.id : '',
      date: data[i]?.date ? data[i]?.date : '',
      description: data[i]?.description ? data[i]?.description : '',
      transaction_type: data[i]?.transaction_type,
      amount: data[i]?.amount ? data[i]?.amount : '',
      balance: data[i]?.balance ? data[i]?.balance : 0,
      source: data[i]?.source ? data[i]?.source : '',
      chart_account: data[i]?.chart_account ? data[i]?.chart_account : '',
      bank_transaction: '',
      bank_statement: data[i] ? data[i] : ''
    });
  }
  return items;
};

const useReconciledStatementList = (
  currentPage,
  fromDate,
  toDate,
  setBalanceInfo,
  selectedTab,
  search,
  dateFilter
) => {
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [reconciledStatements, setReconciledStatements] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchParam, setSearchParam] = useState(search || '');

  const [pagingData, setPagingData] = useState({ current: currentPage || 1 });
  const fetchReconciledStatement = useCallback(
    async (page, limit, newFormDate, newToDate) => {
      if (selectedTab === 'reconciled-items') {
        try {
          setIsDataLoading(true);
          let tempLimit = defaultPaginationLimit;
          if (limit) {
            tempLimit = limit;
          } else {
            tempLimit = query.get('limit') ? query.get('limit') : defaultPaginationLimit;
          }
          const { data } = await get(
            `${API.BANK_RECONCILIATION_LIST}?status=1&page=${
              page || pagingData.current
            }&start_date=${newFormDate || newFormDate === '' ? newFormDate : fromDate}&end_date=${newToDate || newToDate === '' ? newToDate : toDate}&limit=${tempLimit}`
          );
          const { total, current_page, per_page, from, to } = data.data;
          const balanceDetails = {};
          balanceDetails['firm_bank'] = data.firm_bank;
          balanceDetails['firm_balance'] = data.firm_balance;
          balanceDetails['statement_balance'] = data.statement_balance;
          balanceDetails['date'] = data?.latest_statement?.date;
          setBalanceInfo(balanceDetails);
          const StatementsData = preprocessStatementList(data.data.data);
          setIsDataLoading(false);
          setPagingData({
            total,
            current: current_page,
            defaultPageSize: per_page,
            from: from,
            to: to
          });
          setReconciledStatements(StatementsData);
        } catch (e) {
          setReconciledStatements([]);
          setIsDataLoading(false);
          setPagingData({ current: 0 });
        }
      }
    },
    [searchParam, selectedTab]
  );

  useEffect(() => {
    fetchReconciledStatement();
  }, [fetchReconciledStatement]);

  return {
    reconciledStatements,
    isDataLoading,
    fetchReconciledStatement,
    pagingData,
    onPageChange: (page, limit, start_date, end_date) => {
      setPagingData({ ...pagingData, current: page });
      fetchReconciledStatement(page, limit, start_date, end_date);
      navigate(`/firm-bank-account?page=${page}&tab=reconciled-items&limit=${limit}&from=${start_date}&to=${end_date}`, {
        replace: true
      });
    },
    onChange: (e) => {
      const { start_date, end_date } = dateFilter;
      const value = e.target.value;
      setSearchParam(value);
      setPagingData({ ...pagingData, current: 1 });
      fetchReconciledStatement(1, query.get('limit'), start_date, end_date);
      navigate(`/firm-bank-account?page=1&tab=reconciled-items&from=${start_date}&to=${end_date}`, {
        replace: true
      });
    },
    searchParam
  };
};

export default useReconciledStatementList;
