/* eslint-disable no-nested-ternary */
import React, { useLayoutEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { imageDefaultPrefixPath } from '../../config';
import { printComponent } from '../../helper/print';
import { faPrint, faDownload } from '@fortawesome/free-solid-svg-icons';
import { download } from '../../helper/download';
import { getReceiptDetail } from './ReceiptsHelper';
import moment from 'moment';
import { maskPostalCode } from '../../helper/fullNameGetter';

const ReceiptViewDetailModal = ({ record, toggleShowModal, show }) => {
  const [receiptData, setReceiptData] = useState(null);
  const [loading, setLoading] = useState(false);
  useLayoutEffect(() => {
    async function fetchData() {
      setLoading(true);
      let previewData = await getReceiptDetail(record?.id);
      setReceiptData(previewData);
      setLoading(false);
    }
    fetchData();
  }, []);

  function printLetter() {
    return printComponent('print-authority');
  }

  const downloadLetter = async () => {
    try {
      if (receiptData?.receipt) {
        download(`${imageDefaultPrefixPath}${receiptData?.receipt}`, 'receipt.pdf');
      } else {
        toast.error('File is not exist');
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <>
      <Modal
        title=""
        centered
        open={show}
        footer={null}
        width={885}
        closable
        className="modal-body"
        onCancel={toggleShowModal}
      >
        {loading ? (
          <div className="centeralign spin-padding">
            <Spin />
          </div>
        ) : (
          <>
            <div className="authority-letter" id="print-authority">
              {receiptData ? (
                <table width="850" cellSpacing="0" cellPadding="0" className="firm_details">
                  <tr>
                    <td style={{ padding: '15px' }}>
                      <table
                        cellSpacing="0"
                        cellPadding="0"
                        style={{
                          width: '100%'
                        }}
                      >
                        <tr>
                          {receiptData?.accounting_firm?.logo ? (
                            <td>
                              <table
                                cellSpacing="0"
                                cellPadding="0"
                                style={{
                                  width: '100%'
                                }}
                                className="firm_details"
                              >
                                <tr>
                                  <td style={{ width: '8%' }}>
                                    {receiptData?.accounting_firm?.logo && (
                                      <img
                                        src={`${imageDefaultPrefixPath}${receiptData.accounting_firm.logo}`}
                                        alt="logo"
                                        style={{
                                          height: '100px',
                                          marginRight: '15px',
                                          float: 'left'
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {receiptData?.accounting_firm?.logo ? (
                                      <></>
                                    ) : (
                                      <h2
                                        style={{
                                          color: 'rgb(0, 91, 152)',
                                          fontSize: '21px',
                                          textTransform: 'uppercase',
                                          position: 'relative',
                                          fontWeight: '600',
                                          margin: '13px 0 0px',
                                          fontFamily: 'Poppins'
                                        }}
                                      >
                                        {receiptData?.accounting_firm?.logo
                                          ? ''
                                          : receiptData?.accounting_firm?.business_name
                                          ? receiptData.accounting_firm.business_name
                                          : ''}
                                        {receiptData?.accounting_firm?.logo ? <></> : <br />}
                                        <span
                                          style={{
                                            margin: '0px 0px 20px',
                                            width: '100%',
                                            fontFamily: 'Poppins',
                                            textTransform: 'none',
                                            fontSize: '14px',
                                            color: 'rgb(51, 51, 51)',
                                            display: 'block'
                                          }}
                                        >
                                          {receiptData?.accounting_firm?.firm_email ? (
                                            <a
                                              className="mailto-tel"
                                              href={`mailto:${receiptData?.accounting_firm?.firm_email}`}
                                              style={{ fontFamily: 'Poppins' }}
                                            >
                                              {receiptData?.accounting_firm?.firm_email}
                                            </a>
                                          ) : (
                                            ''
                                          )}
                                          <br />
                                          {receiptData?.accounting_firm?.phone ? (
                                            <a
                                              className="mailto-tel"
                                              href={`tel:${receiptData?.accounting_firm?.phone}`}
                                              style={{ fontFamily: 'Poppins' }}
                                            >
                                              {receiptData?.accounting_firm?.phone}
                                            </a>
                                          ) : (
                                            ''
                                          )}
                                          <br />
                                          {receiptData?.accounting_firm?.street
                                            ? receiptData.accounting_firm.street + ', '
                                            : ''}
                                          <br />
                                          {receiptData?.accounting_firm?.firm_suburb
                                            ? receiptData.accounting_firm.firm_suburb
                                            : ''}
                                          {receiptData?.accounting_firm?.firm_state_short_name
                                            ? ', ' +
                                              receiptData.accounting_firm.firm_state_short_name
                                            : ''}
                                          {receiptData?.accounting_firm?.postal_code &&
                                          receiptData?.accounting_firm?.postal_code != '0000' &&
                                          receiptData?.accounting_firm?.postal_code != 0
                                            ? ', ' +
                                              maskPostalCode(
                                                Number(receiptData.accounting_firm.postal_code)
                                              )
                                            : ''}
                                        </span>
                                      </h2>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: 'right',
                                      color: 'rgb(0, 0, 0)',
                                      lineHeight: '1.25',
                                      fontFamily: 'Poppins',
                                      float: 'right',
                                      width: '200px'
                                    }}
                                  >
                                    {receiptData?.accounting_firm?.accounting_body != 'Other' ? (
                                      <>
                                        <img
                                          src={`${imageDefaultPrefixPath}${receiptData?.accounting_firm?.accounting_body_logo}`}
                                          alt="logo"
                                          style={{
                                            width: 'auto',
                                            height: '40px',
                                            marginBottom: '0px'
                                          }}
                                        />
                                        <p
                                          style={{
                                            fontSize: '12px',
                                            margin: '5px 0 0',
                                            fontFamily: 'Poppins'
                                          }}
                                        >
                                          {`${
                                            receiptData?.accounting_firm?.business_name
                                              ? receiptData.accounting_firm.business_name
                                              : ''
                                          } is ` +
                                            (/^[aeiouAEIOU]/.test(
                                              receiptData?.accounting_firm?.accounting_body
                                            )
                                              ? 'an '
                                              : 'a ') +
                                            receiptData?.accounting_firm?.accounting_body +
                                            ` practice.`}
                                        </p>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          ) : (
                            <>
                              <td>
                                <h2
                                  style={{
                                    color: 'rgb(0, 91, 152)',
                                    fontSize: '21px',
                                    textTransform: 'uppercase',
                                    position: 'relative',
                                    fontWeight: '600',
                                    margin: '13px 0 0px',
                                    fontFamily: 'Poppins'
                                  }}
                                >
                                  {receiptData?.accounting_firm?.business_name
                                    ? receiptData.accounting_firm.business_name
                                    : ''}
                                  <br />
                                  <span
                                    style={{
                                      margin: '0px 0px 10px',
                                      width: '100%',
                                      fontFamily: 'Poppins',
                                      textTransform: 'none',
                                      fontSize: '14px',
                                      color: 'rgb(51, 51, 51)',
                                      display: 'block'
                                    }}
                                  >
                                    {receiptData?.accounting_firm?.firm_email ? (
                                      <a
                                        className="mailto-tel"
                                        href={`mailto:${receiptData?.accounting_firm?.firm_email}`}
                                        style={{ fontFamily: 'Poppins' }}
                                      >
                                        {receiptData?.accounting_firm?.firm_email}
                                      </a>
                                    ) : (
                                      ''
                                    )}
                                    <br />
                                    {receiptData?.accounting_firm?.phone ? (
                                      <a
                                        className="mailto-tel"
                                        href={`tel:${receiptData?.accounting_firm?.phone}`}
                                        style={{ fontFamily: 'Poppins' }}
                                      >
                                        {receiptData?.accounting_firm?.phone}
                                      </a>
                                    ) : (
                                      ''
                                    )}
                                    <br />
                                    {receiptData?.accounting_firm?.street
                                      ? receiptData.accounting_firm.street + ', '
                                      : ''}
                                    <br />
                                    {receiptData?.accounting_firm?.firm_suburb
                                      ? receiptData.accounting_firm.firm_suburb
                                      : ''}
                                    {receiptData?.accounting_firm?.firm_state_short_name
                                      ? ', ' + receiptData.accounting_firm.firm_state_short_name
                                      : ''}
                                    {receiptData?.accounting_firm?.postal_code &&
                                    receiptData?.accounting_firm?.postal_code != '0000' &&
                                    receiptData?.accounting_firm?.postal_code != 0
                                      ? ', ' +
                                        maskPostalCode(
                                          Number(receiptData.accounting_firm.postal_code)
                                        )
                                      : ''}
                                  </span>
                                </h2>
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  color: 'rgb(0, 0, 0)',
                                  lineHeight: '1.25',
                                  fontFamily: 'Poppins',
                                  float: 'right',
                                  width: '200px'
                                }}
                              >
                                {receiptData?.accounting_firm?.accounting_body != 'Other' ? (
                                  <>
                                    <img
                                      src={`${imageDefaultPrefixPath}${receiptData?.accounting_firm?.accounting_body_logo}`}
                                      alt="logo"
                                      style={{ width: 'auto', height: '40px', marginBottom: '0px' }}
                                    />
                                    <p style={{ fontSize: '12px', margin: '5px 0 0' }}>
                                      {`${
                                        receiptData?.accounting_firm?.business_name
                                          ? receiptData.accounting_firm.business_name
                                          : ''
                                      } is ` +
                                        (/^[aeiouAEIOU]/.test(
                                          receiptData?.accounting_firm?.accounting_body
                                        )
                                          ? 'an '
                                          : 'a ') +
                                        receiptData?.accounting_firm?.accounting_body +
                                        ` practice.`}
                                    </p>
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      </table>
                      <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0">
                        {receiptData?.accounting_firm?.logo && (
                          <tr>
                            <td>
                              <h2
                                style={{
                                  color: 'rgb(0, 91, 152)',
                                  fontSize: '21px',
                                  textTransform: 'uppercase',
                                  position: 'relative',
                                  fontWeight: '600',
                                  margin: '13px 0 0px',
                                  fontFamily: 'Poppins'
                                }}
                              >
                                {receiptData?.accounting_firm?.logo
                                  ? ''
                                  : receiptData?.accounting_firm?.business_name
                                  ? receiptData.accounting_firm.business_name
                                  : ''}
                                {receiptData?.accounting_firm?.logo ? <></> : <br />}
                                <span
                                  style={{
                                    margin: '0 0 15px',
                                    width: '100%',
                                    fontFamily: 'Poppins',
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: 'rgb(51, 51, 51)',
                                    display: 'block'
                                  }}
                                >
                                  {receiptData?.accounting_firm?.firm_email ? (
                                    <a
                                      className="mailto-tel"
                                      href={`mailto:${receiptData?.accounting_firm?.firm_email}`}
                                      style={{ fontFamily: 'Poppins' }}
                                    >
                                      {receiptData?.accounting_firm?.firm_email}
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                  <br />
                                  {receiptData?.accounting_firm?.phone ? (
                                    <a
                                      className="mailto-tel"
                                      href={`tel:${receiptData?.accounting_firm?.phone}`}
                                      style={{ fontFamily: 'Poppins' }}
                                    >
                                      {receiptData?.accounting_firm?.phone}
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                  <br />
                                  {receiptData?.accounting_firm?.street
                                    ? receiptData.accounting_firm.street + ', '
                                    : ''}
                                  <br />
                                  {receiptData?.accounting_firm?.firm_suburb
                                    ? receiptData.accounting_firm.firm_suburb
                                    : ''}
                                  {receiptData?.accounting_firm?.firm_state_short_name
                                    ? ', ' + receiptData.accounting_firm.firm_state_short_name
                                    : ''}
                                  {receiptData?.accounting_firm?.postal_code &&
                                  receiptData?.accounting_firm?.postal_code != '0000' &&
                                  receiptData?.accounting_firm?.postal_code != 0
                                    ? ', ' +
                                      maskPostalCode(
                                        Number(receiptData.accounting_firm.postal_code)
                                      )
                                    : ''}
                                </span>
                              </h2>
                            </td>
                          </tr>
                        )}
                        {receiptData?.customer?.crn && (
                          <tr>
                            <td>
                              <p style={{ margin: '5px 0 0', fontFamily: 'Poppins' }}>
                                CRN: {receiptData?.customer?.crn ? receiptData?.customer?.crn : ''}
                              </p>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td style={{ padding: '15px 0 0', fontFamily: 'Poppins' }}>
                            {' '}
                            {receiptData?.transaction_date
                              ? receiptData?.transaction_date?.split('-')[2] +
                                ' ' +
                                moment(receiptData?.transaction_date, 'DD-MM-YYYY')
                                  .format('MMMM')
                                  .substring(0, 3) +
                                ', ' +
                                receiptData?.transaction_date?.split('-')[0]
                              : ''}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: '15px 0 15px',
                              fontStyle: 'italic',
                              fontFamily: 'Poppins'
                            }}
                          >
                            {' '}
                            Receipt Number:{' '}
                            <span style={{ fontFamily: 'Poppins' }} id="print-receipt-number">
                              {receiptData?.receipt_number
                                ? receiptData?.receipt_number
                                : receiptData.id}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'Poppins', padding: '0px 0 10px 0px' }}>
                            <span style={{ fontFamily: 'Poppins' }} id="print-receipt-file-name">
                              {receiptData?.customer
                                ? receiptData?.customer?.first_name
                                  ? receiptData?.customer?.first_name +
                                    ' ' +
                                    receiptData?.customer?.last_name
                                  : receiptData?.customer?.last_name
                                : receiptData?.supplier
                                ? receiptData?.supplier?.name
                                : '-'}
                            </span>
                            {/* eslint-disable */}
                            <br />
                            {receiptData?.customer
                              ? receiptData?.customer?.customer_street
                                ? receiptData.customer?.customer_street + ', '
                                : receiptData?.customer?.shipping_street
                                ? receiptData.customer?.shipping_street + ', '
                                : ''
                              : receiptData?.supplier
                              ? receiptData?.supplier?.supplier_street
                                ? receiptData.supplier?.supplier_street + ', '
                                : receiptData?.supplier?.shipping_street
                                ? receiptData.supplier?.shipping_street + ', '
                                : ''
                              : ''}
                            <br />
                            {receiptData?.customer
                              ? receiptData?.customer?.customer_suburb
                                ? receiptData.customer?.customer_suburb
                                : receiptData?.customer?.shipping_suburb
                                ? receiptData.customer?.shipping_suburb
                                : ''
                              : receiptData?.supplier
                              ? receiptData?.supplier?.supplier_suburb
                                ? receiptData.supplier?.supplier_suburb
                                : receiptData?.supplier?.shipping_suburb
                                ? receiptData.supplier?.shipping_suburb
                                : ''
                              : ''}
                            {receiptData?.customer
                              ? receiptData?.customer?.customer_state_short_name
                                ? ', ' + receiptData.customer?.customer_state_short_name
                                : receiptData?.customer?.shipping_state_short_name
                                ? ', ' + receiptData.customer?.shipping_state_short_name
                                : ''
                              : receiptData?.supplier
                              ? receiptData?.supplier?.supplier_state_short_name
                                ? ', ' + receiptData.supplier?.supplier_state_short_name
                                : receiptData?.supplier?.shipping_state_short_name
                                ? ', ' + receiptData.supplier?.shipping_state_short_name
                                : ''
                              : ''}
                            {receiptData?.customer
                              ? receiptData?.customer?.postal_code &&
                                receiptData?.customer?.postal_code != '0000' &&
                                receiptData?.customer?.postal_code != 0
                                ? ', ' + maskPostalCode(Number(receiptData.customer?.postal_code))
                                : receiptData?.customer?.shipping_postal_code &&
                                  receiptData?.customer?.shipping_postal_code != '0000' &&
                                  receiptData?.customer?.shipping_postal_code != 0
                                ? ', ' +
                                  maskPostalCode(Number(receiptData.customer?.shipping_postal_code))
                                : ''
                              : receiptData?.supplier
                              ? receiptData?.supplier?.postal_code &&
                                receiptData?.supplier?.postal_code != '0000' &&
                                receiptData?.supplier?.postal_code != 0
                                ? maskPostalCode(Number(receiptData.supplier?.postal_code))
                                : receiptData?.supplier?.shipping_postal_code &&
                                  receiptData?.supplier?.shipping_postal_code != '0000' &&
                                  receiptData?.supplier?.shipping_postal_code != 0
                                ? maskPostalCode(Number(receiptData.supplier?.shipping_postal_code))
                                : ''
                              : ''}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px 0 0', fontFamily: 'Poppins' }}>
                            {' '}
                            Dear{' '}
                            {receiptData?.customer
                              ? receiptData?.customer?.first_name
                                ? receiptData?.customer?.first_name +
                                  ' ' +
                                  receiptData?.customer?.last_name
                                : receiptData?.customer?.business_name
                                ? receiptData?.customer?.business_name
                                : receiptData?.customer?.last_name
                              : receiptData?.supplier
                              ? receiptData?.supplier?.business_name
                                ? receiptData?.supplier?.business_name
                                : receiptData?.supplier?.name
                              : ''}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ fontFamily: 'Poppins', margin: '20px 0' }}>
                              <b style={{ fontFamily: 'Poppins' }}>
                                Re: Receipt of Monies into Trust Account
                              </b>
                            </p>
                            <p style={{ fontFamily: 'Poppins', margin: '0 0 15px' }}>
                              Please be advised that in accordance with your earlier instructions,
                              the following money has been received by the firm and has been
                              deposited into our Trust Accounts where it will be held in trust for
                              you. Details of the transaction are as follows:
                            </p>
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <span style={{ fontFamily: 'Poppins', marginRight: '40px' }}>
                              Electronic Fund Transfer
                            </span>
                            <span style={{ fontFamily: 'Poppins' }}>
                              {receiptData?.amount
                                ? `$${receiptData?.amount.toLocaleString('en-US')}`
                                : ''}
                            </span>
                          </td>
                        </tr>
                      </table>{' '}
                      <table style={{ width: '100%' }} cellPadding="0" cellSpacing="0">
                        <tr style={{ fontFamily: 'Poppins' }}>
                          {/* <td>
                            <p style={{ fontFamily: 'Poppins', marginTop: '15px' }}>
                              Please note that a Handling Fee of $0.00 has been deducted from the
                              amount received.
                            </p>
                          </td> */}
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <p style={{ fontFamily: 'Poppins', margin: '0 0 35px' }}>
                              The transaction was conducted in our Trust Account{' '}
                              {receiptData?.accounting_firm?.firm_bank?.account_number
                                ? 'xxxx xxxx ' +
                                  receiptData?.accounting_firm?.firm_bank?.account_number
                                    ?.toString()
                                    .slice(-4)
                                : ''}
                              {', '}
                              account name{' '}
                              {receiptData?.accounting_firm?.firm_bank?.account_name
                                ? receiptData.accounting_firm.firm_bank?.account_name
                                : ''}
                              . Should you have any queries, please do not hesitate to contact our
                              office.
                            </p>
                            <p style={{ fontFamily: 'Poppins', margin: '0 0 15px' }}>
                              Yours sincerely,
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ fontFamily: 'Poppins', margin: '0px' }}>
                              {receiptData?.created_by
                                ? receiptData?.created_by?.first_name
                                  ? receiptData?.created_by?.first_name +
                                    ' ' +
                                    receiptData?.created_by?.last_name
                                  : receiptData?.created_by?.last_name
                                : localStorage.getItem('FullName')
                                ? localStorage.getItem('FullName')
                                : ''}
                            </p>
                            <p style={{ fontFamily: 'Poppins', margin: '0px' }}>
                              {receiptData?.accounting_firm?.business_name
                                ? receiptData.accounting_firm.business_name
                                : ''}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              ) : (
                <></>
              )}
            </div>
            <div
              className="print-btn-div"
              style={{ padding: '0 0 20px', display: 'block', textAlign: 'center' }}
            >
              {receiptData && (
                <Button
                  onClick={() => printLetter()}
                  type="primary"
                  className="btn btn-primary printbtn"
                >
                  <FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
                  Print
                </Button>
              )}
              {receiptData?.receipt && (
                <Button
                  onClick={downloadLetter}
                  type="primary"
                  className="btn btn-primary printbtn"
                >
                  <FontAwesomeIcon icon={faDownload} style={{ marginRight: '5px' }} />
                  Download
                </Button>
              )}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ReceiptViewDetailModal;
