import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { API, fileUpload } from "../../../config";
import { Spin } from "antd";

const AddCard = ({ showAddCard, toggleAddCard, fetchCardDetails, cards }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [defaultCard, setDefaultCard] = useState(false);
  const params = useParams();
  const projectId = params?.project_id;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      toast.error("Stripe Elements not loaded.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      toast.error("Card details are missing.");
      setLoading(false);
      return;
    }

    try {
      const { paymentMethod, error: pmError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

      if (pmError) throw new Error(pmError.message || "Payment method failed");

      const formData = new FormData();
      formData.append("builder_project_id", projectId || "");
      formData.append("payment_method_id", paymentMethod?.id || "");
      formData.append("customer_id", cards?.[0]?.customer_id);
      formData.append("default", defaultCard);
      formData.append("type", 1);
      formData.append("_method", "put");

      const { data } = await fileUpload(API.STRIPE_CARD, formData);
      if (data) {
        toast.success(data?.message);
        fetchCardDetails();
        toggleAddCard();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      size="lg"
      show={showAddCard}
      onHide={toggleAddCard}
      dialogClassName="modal-50w small-popup review-popup sub-card-modal small-review"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        New Card
      </Modal.Header>
      <Modal.Body>
        <div>
          {loading && <Spin />}

          <form
            onSubmit={handleSubmit}
            style={{
              padding: "0px 15px",
            }}
          >
            <CardElement
              className="card-input"
              options={{
                hidePostalCode: true,
              }}
            />
            <div className="active-check-body">
              <input
                type="checkbox"
                name="is_admin_user"
                value={defaultCard}
                checked={defaultCard}
                onClick={() => {
                  setDefaultCard(!defaultCard);
                }}
                onChange={() => {
                  setDefaultCard(!defaultCard);
                }}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />{" "}
              <label
                onClick={() => {
                  setDefaultCard(!defaultCard);
                }}
              >
                Make Default
              </label>
            </div>
            <div className="modal-footer">
              <Button type="submit" disabled={loading}>
                Save
              </Button>
              <Button
                className="btn btn-secondary"
                data-dismiss="modal"
                aria-label="Close"
                type="Button"
                onClick={() => toggleAddCard()}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCard;
