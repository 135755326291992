import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Input } from 'antd';
import Form from '../../components/Form';
//import Loginimg from '../../assets/images/Loginimg.js';
import { API, post } from '../../config.js';
import Sitelogo from '../../components/Header/Logo.js';
import Footer from '../../components/Footer/Index.js';
import abstractImage from "../../assets/images/abstract_image.jpeg";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [message, setMessage] = useState({ success: '', error: '' });

  const handleFinish = async (values) => {
    try {
      setMessage({});
      const { data } = await post(API.FORGOT_PASSWORD, values);
      if (data.status === 200) {
        setMessage({ success: data.message });
      } else {
        setMessage({ error: data.errors?.myna_error[0] });
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="login-pages">
      <Row>
        <Col>
          <div className="login-img white-site">
            <Sitelogo />
          </div>
          <div
            className="footer-link"
            style={{ maxWidth: '100%', color: '#CCC', padding: '0 30px' }}
          >
            <p style={{ fontSize: '12px' }}>
              TrustEasy acknowledges the Traditional Custodians of
              country throughout Australia and their connections to land, sea and community. We pay
              our respect to their Elders past and present and extend that respect to all Aboriginal
              and Torres Strait Islander peoples today.
              <br />
            </p>
            <div className="abstract-image-body">
              <img src={abstractImage} alt="test" />
            </div>
          </div>
        </Col>
        <Col className="login-form-block trans-login">
          <div className="login-form">
            <Form form={form} name="forgotPasswordForm" onFinish={handleFinish}>
              <div className="auth-text auth-image">
                Enter email address to receive an email to reset your password
              </div>
              <Form.Item
                name="email"
                validateTrigger={['submit', 'onBlur']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Email'
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  }
                ]}
              >
                <Input placeholder="Email Address" bordered={false} />
              </Form.Item>
              {message.error && <p className="text-danger">{message.error}</p>}
              {message.success && <p className="text-success">{message.success}</p>}
              <div className="form-group ant-form-item confirmButton">
                <Button type="primary" htmlType="submit" shape="round" size="large">
                  Confirm
                </Button>
              </div>
            </Form>
            <p>
              <Button
                type="button"
                onClick={() => navigate('/', { replace: true })}
                className="linkButton"
              >
                Back to Login
              </Button>
            </p>
            <Footer />
          </div>
          <div className="footer-link">
            <p style={{ fontSize: '12px', marginBottom: '2px' }}>
              Trouble Logging In?{' '}
              <a
                href="https://trusteasy.com.au/#contact"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Contact Us
              </a>
            </p>
            <p style={{ fontSize: '12px' }}>
              By selecting Login, you agree to our{' '}
              <a
                href="https://trusteasy.com.au/terms-conditions/"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Terms of Use
              </a>{' '}
              and have read and acknowledge our{' '}
              <a
                href="https://trusteasy.com.au/privacy-policy/"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
