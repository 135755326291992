import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/auth/Login';
import Dashboard from './pages/dashboard/Dashboard';
import { API, post } from './config';
import ForgotPassword from './pages/auth/ForgotPassword';
import TermsOfUse from './pages/TermsOfUse';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/js/custom.js';
import 'antd/dist/antd.min.css';
import Privacy from './pages/Privacy';
import HelpCenter from './pages/HelpCenter';
import Clients from './pages/Clients';
import Settings from './pages/Settings';
import Reports from './pages/Reports';
import TwoFactor from './pages/auth/TwoFactor';
import TwoFactorRegister from './pages/auth/TwoFactorRegister';
import TwoFactorChange from './pages/auth/TwoFactorChange';
import TwoFactorDisable from './pages/auth/TwoFactorDisable';
import ProtectedRoute from './ProtectedRoute';
import AuthRoute from './AuthRoute';
import ResetPassword from './pages/auth/ResetPassword';
import UserConsole from './pages/users/UserConsole';
import ActivityLogs from './pages/users/ActivityLogs';
import CustomerList from './pages/customer/CustomerList';
import CustomerAdd from './pages/customer/CustomerAdd';
import CustomerSummary from './pages/customer/CustomerSummary';
import UserAdd from './pages/users/UserAdd';
import FirmSignup from './pages/firm/FirmSignup';
import Congratulation from './pages/firm/Congratulation';
import ATORefund from './pages/ato/ATORefund';
import UserEdit from './pages/users/UserEdit';
import CustomerEdit from './pages/customer/CustomerEdit';
import AddATORefund from './pages/ato/AddATORefund';
import ProcessRefund from './pages/process-refund/ProcessRefund';
import UserRole from './pages/setting/UserRole';
import ProcessRefundIndividual from './pages/process-refund/ProcessRefundIndividual';
import ABATabs from './pages/aba/ABATabs';
import FirmBank from './pages/firm-trust/FirmTrustTabs';
import UserSettings from './pages/user-setting/UserSettings';
import TemplateSetting from './pages/template-setting/TemplateSetting';
import Subscription from './pages/firm/Subscription';
import CustomerTransactionReport from './pages/reports/CustomerTransactionReport';
import DeletedClientReport from './pages/reports/DeletedClientReport';
import BankReconciliation from './pages/bank-reconciliation/BankReconciliation';
import ClientWithoutBankDetailsReport from './pages/reports/ClientWithoutBankDetailsReport';
import ClientRequiredUpdate from './pages/reports/ClientRequiredUpdate';
import ClientsListReport from './pages/reports/ClientReports';
import TrialBalanceReport from './pages/reports/TrialBalanceReport';
import AdminDashboard from './pages/Admin/dashboard';
import RegisteredFirmTabs from './pages/Admin/registeredFirm';
import ImportLogs from './pages/import-logs/ImportLogs';
import NotImportedLogEdit from './pages/import-logs/NotImportedLogEdit';
import AddJournalEntry from './pages/journal-entry/AddJournalEntry';
import OpeningBalance from './pages/Accounting/OpeningBalance';
import RecordPayment from './pages/record-payment/RecordPayment';
import Payment from './pages/payment/Payment';
import MonthlyBankReconciliationReport from './pages/firm-trust/MonthlyBankReconciliationReport';
import Receipt from './pages/receipt/Receipt';
import RecordReceipt from './pages/receipt/RecordReceipt';
import GeneralLedgerReport from './pages/reports/GeneralLedgerReport';
import SubscriptionNotFound from './pages/firm/SubcriptionNotFound';
import { GetCookies } from './APIConfig/Interceptor';
import XeroSignup from './XeroSignup.js';
import DuplicateCustomerList from './pages/customer/DuplicateCustomerList.js';
import AdminRoute from './AdminRoute.js';
import SupportTicket from './pages/Admin/support-ticket/SupportTicket.js';
import RoleList from './pages/Admin/role/RoleList.js';
import AdminUserConsole from './pages/Admin/admin-users/AdminUserConsole.js';
import AdminUserAdd from './pages/Admin/admin-users/AdminUserAdd.js';
import AdminUserEdit from './pages/Admin/admin-users/AdminUserEdit.js';
import { HelpCenterProvider } from './shared/HelpCenterContext.js';

const App = () => {
  let subscription = GetCookies('_subscription');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tfa, setTfa] = useState(0);
  const halfHour = 25 * 60000;

  const authenticate = useCallback(
    (token, email, tfaCheck = 0, refreshToken) => {
      if (tfaCheck) {
        localStorage.setItem('myna-auth-tfa', tfaCheck);
      }
      setIsAuthenticated(true);
      setTfa(tfa);

      localStorage.setItem('myna-auth-token', token);
      localStorage.setItem('myna-refresh-token', refreshToken);
      setInterval(() => {
        refresh();
      }, halfHour);
      localStorage.setItem('myna-auth-email', email);
    },
    [tfa]
  );

  useEffect(() => {
    const lsToken = localStorage.getItem('myna-auth-token');
    const email = localStorage.getItem('myna-auth-email');
    const tfaCheck = localStorage.getItem('myna-auth-tfa');
    const reToken = localStorage.getItem('myna-refresh-token');

    if (lsToken || reToken) {
      authenticate(lsToken, email, tfaCheck, reToken);
    }
  }, [authenticate]);

  const refresh = async () => {
    var currentdate = new Date();
    const authLoginTime = localStorage.getItem('myna-auth-login-time');

    function diff_minutes(dt2, dt1) {
      var diff = (dt2.getTime() - dt1) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    }

    diff_minutes(currentdate, authLoginTime);
    const diff_time = diff_minutes(currentdate, authLoginTime);
    if (diff_time >= 45.0) {
      try {
        const response = await post(API.REFRESH, '');
        if (response?.data?.status === 200) {
          localStorage.setItem('myna-auth-token', response?.data?.access_token);
          localStorage.setItem('myna-refresh-token', response?.data?.refresh_token);
          localStorage.setItem('myna-auth-login-time', currentdate.getTime());
        }
      } catch (error) {
        const { data } = error.response;
        if (
          data.status === 500 &&
          data.message.split(': ')[0] === 'Unable to create lockable file'
        ) {
          refresh();
        } else {
          toast.error(data.message);
          setTimeout(() => {
            localStorage.clear();
          }, 3000);
        }
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <Router>
        <HelpCenterProvider>
          <div className="content">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Login authenticate={authenticate} isAuthenticated={isAuthenticated} tfa={tfa} />
                }
              />
              <Route exact path="/xero-ssu" element={<XeroSignup />} />
              <Route exact path="/2fa" element={<AuthRoute component={TwoFactor}></AuthRoute>} />
              <Route
                exact
                path="/2fa/register"
                element={<AuthRoute component={TwoFactorRegister}></AuthRoute>}
              />
              <Route
                exact
                path="/2fa/change"
                element={<AuthRoute component={TwoFactorChange}></AuthRoute>}
              />
              <Route
                exact
                path="/2fa/disable"
                element={<AuthRoute component={TwoFactorDisable}></AuthRoute>}
              />
              <Route exact path="/firm/subscription/:id" element={<Subscription />} />
              <Route exact path="/forgot-password" element={<ForgotPassword />} />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/set-password" element={<ResetPassword />} />

              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute
                    component={subscription == 1 ? Dashboard : SubscriptionNotFound}
                  ></ProtectedRoute>
                }
              />
              <Route exact path="/firm/signup" element={<FirmSignup />} />
              <Route exact path="/congratulations" element={<Congratulation />} />
              <Route exact path="/terms-of-use" element={<TermsOfUse />} />
              <Route exact path="/user-role" element={<UserRole />} />
              <Route exact path="/privacy" element={<Privacy />} />
              <Route exact path="/help" element={<HelpCenter />} />
              <Route
                exact
                path="/clients"
                element={
                  <ProtectedRoute component={subscription ? Clients : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/reports"
                element={
                  <ProtectedRoute component={subscription ? Reports : SubscriptionNotFound} />
                }
              />
              <Route exact path="/settings" element={<ProtectedRoute component={Settings} />} />

              <Route
                exact
                path="/customer"
                element={
                  <ProtectedRoute component={subscription ? CustomerList : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/duplicate-customer"
                element={
                  <ProtectedRoute
                    component={subscription ? DuplicateCustomerList : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/import-logs"
                element={
                  <ProtectedRoute component={subscription ? ImportLogs : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/customer-import-logs/edit"
                element={
                  <ProtectedRoute
                    component={subscription ? NotImportedLogEdit : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/new-customers"
                element={
                  <ProtectedRoute component={subscription ? CustomerAdd : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/customer/edit"
                element={
                  <ProtectedRoute component={subscription ? CustomerEdit : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/customer-detail/:id"
                element={
                  <ProtectedRoute
                    component={subscription ? CustomerSummary : SubscriptionNotFound}
                  />
                }
              />

              <Route
                exact
                path="/user-console"
                element={
                  <ProtectedRoute component={subscription ? UserConsole : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/support-tickets"
                element={
                  <ProtectedRoute component={subscription ? SupportTicket : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/activity-logs"
                element={
                  <ProtectedRoute component={subscription ? ActivityLogs : SubscriptionNotFound} />
                }
              />

              <Route
                exact
                path="/user/add"
                element={
                  <ProtectedRoute component={subscription ? UserAdd : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/user/edit"
                element={
                  <ProtectedRoute component={subscription ? UserEdit : SubscriptionNotFound} />
                }
              />

              <Route
                exact
                path="/ato-refund"
                element={
                  <ProtectedRoute component={subscription ? ATORefund : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/ato/add"
                element={
                  <ProtectedRoute component={subscription ? AddATORefund : SubscriptionNotFound} />
                }
              />

              <Route
                exact
                path="/process-refund"
                element={
                  <ProtectedRoute component={subscription ? ProcessRefund : SubscriptionNotFound} />
                }
              />

              <Route
                exact
                path="/process-refund-individual/:id"
                element={
                  <ProtectedRoute
                    component={subscription ? ProcessRefundIndividual : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/process-refund-individual"
                element={
                  <ProtectedRoute
                    component={subscription ? ProcessRefundIndividual : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/record-payment"
                element={
                  <ProtectedRoute component={subscription ? RecordPayment : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/payments"
                element={
                  <ProtectedRoute component={subscription ? Payment : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/record-receipt"
                element={
                  <ProtectedRoute component={subscription ? RecordReceipt : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/receipts"
                element={
                  <ProtectedRoute component={subscription ? Receipt : SubscriptionNotFound} />
                }
              />

              <Route
                exact
                path="/user-role"
                element={
                  <ProtectedRoute component={subscription ? UserRole : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/aba"
                element={
                  <ProtectedRoute component={subscription ? ABATabs : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/firm-bank-account"
                element={
                  <ProtectedRoute component={subscription ? FirmBank : SubscriptionNotFound} />
                }
              />
              <Route
                exact
                path="/bank-reconciliation"
                element={
                  <ProtectedRoute
                    component={subscription ? BankReconciliation : SubscriptionNotFound}
                  />
                }
              />
              <Route exact path="/user-settings" element={<UserSettings />} />
              <Route exact path="/template-setting" element={<TemplateSetting />} />
              <Route
                exact
                path="/customer-transaction"
                element={
                  <ProtectedRoute
                    component={subscription ? CustomerTransactionReport : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/deleted-client"
                element={
                  <ProtectedRoute
                    component={subscription ? DeletedClientReport : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/nobank-client"
                element={
                  <ProtectedRoute
                    component={subscription ? ClientWithoutBankDetailsReport : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/client-required-update"
                element={
                  <ProtectedRoute
                    component={subscription ? ClientRequiredUpdate : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/clients-report"
                element={
                  <ProtectedRoute
                    component={subscription ? ClientsListReport : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/general-ledger-report"
                element={
                  <ProtectedRoute
                    component={subscription ? GeneralLedgerReport : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/trial-balance"
                element={
                  <ProtectedRoute
                    component={subscription ? TrialBalanceReport : SubscriptionNotFound}
                  />
                }
              />
              <Route
                exact
                path="/monthly-bank-reconciliation"
                element={
                  <ProtectedRoute
                    component={
                      subscription ? MonthlyBankReconciliationReport : SubscriptionNotFound
                    }
                  />
                }
              />

              {/* Admin Routes */}

              <Route
                path="/admin/dashboard"
                element={<AdminRoute component={AdminDashboard}></AdminRoute>}
              />
              <Route
                path="/admin/registered-firms"
                element={<AdminRoute component={RegisteredFirmTabs}></AdminRoute>}
              />

              <Route
                path="/admin/support-tickets"
                element={<AdminRoute component={SupportTicket}></AdminRoute>}
              />

              <Route path="/admin/roles" element={<AdminRoute component={RoleList}></AdminRoute>} />

              <Route
                path="/admin/users"
                element={<AdminRoute component={AdminUserConsole}></AdminRoute>}
              />

              <Route
                exact
                path="/admin/users/add"
                element={<AdminRoute component={AdminUserAdd}></AdminRoute>}
              />
              <Route
                exact
                path="/admin/users/edit"
                element={<AdminRoute component={AdminUserEdit}></AdminRoute>}
              />

              <Route
                exact
                path="/add-journal-entry"
                element={
                  <ProtectedRoute
                    component={subscription ? AddJournalEntry : SubscriptionNotFound}
                  />
                }
              />

              <Route
                exact
                path="/opening-balance"
                element={
                  <ProtectedRoute
                    component={subscription ? OpeningBalance : SubscriptionNotFound}
                  />
                }
              />
            </Routes>
          </div>
        </HelpCenterProvider>
      </Router>
    </>
  );
};

export default App;
