import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Row, Col, Input } from 'antd';
import Form from '@component/Form';
import { API, IV, post, SECRETKEY } from '../../config.js';
import Sitelogo from '@component/Header/Logo.js';
import Footer from '@component/Footer/Index.js';
import abstractImage from "../../assets/images/abstract_image.jpeg";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const [message, setMessage] = useState({ error: '' });
  const navigate = useNavigate();
  const [eye, seteye] = useState(true);
  const [eye1, seteye1] = useState(true);
  const [password, setpassword] = useState('password');
  const [password1, setpassword1] = useState('password');
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const emailStr = location.search.split('&').pop();
  const email = emailStr.split('=').pop();
  const initialValues = { email };

  var CryptoJS = require('crypto-js');
  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);

  const handleFinish = async (values) => {
    try {
      setMessage({});

      const encPassword = CryptoJS.AES.encrypt(values?.password, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      const finalEncryptedPassword = encPassword.ciphertext.toString(CryptoJS.enc.Base64);

      const encConfirmPassword = CryptoJS.AES.encrypt(values?.password_confirmation, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      const finalEncryptedConfirmPassword = encConfirmPassword.ciphertext.toString(
        CryptoJS.enc.Base64
      );

      const finalValue = {
        ...values,
        password: finalEncryptedPassword,
        password_confirmation: finalEncryptedConfirmPassword
      };
      const { data } = await post(API.RESET_PASSWORD, {
        email: email,
        token: query.get('token'),
        ...finalValue
      });
      if (data.status === 200) {
        navigate('/', { replace: true });
      } else {
        setMessage({ error: data.errors?.myna_error[0] });
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const Eye = () => {
    if (password == 'password') {
      setpassword('text');
      seteye(false);
    } else {
      setpassword('password');
      seteye(true);
    }
  };

  const Eye1 = () => {
    if (password1 == 'password') {
      setpassword1('text');
      seteye1(false);
    } else if (password1 == 'text') {
      setpassword1('password');
      seteye1(true);
    }
  };

  return (
    <div className="login-pages">
      <Row>
        <Col>
          <div className="login-img white-site">
            <Sitelogo />
          </div>
          <div
            className="footer-link"
            style={{ maxWidth: '100%', color: '#CCC', padding: '0 30px' }}
          >
            <p style={{ fontSize: '12px' }}>
              TrustEasy acknowledges the Traditional Custodians of
              country throughout Australia and their connections to land, sea and community. We pay
              our respect to their Elders past and present and extend that respect to all Aboriginal
              and Torres Strait Islander peoples today.
              <br />
            </p>
            <div className="abstract-image-body">
              <img src={abstractImage} alt="test" />
            </div>
          </div>
        </Col>
        <Col className="login-form-block trans-login">
          <div className="login-form">
            {message.error && <p className="text-danger">{message.error}</p>}
            <Form
              form={form}
              initialValues={initialValues}
              name="resetPasswordForm"
              onFinish={handleFinish}
            >
              <Form.Item name="email">
                <Input bordered={false} disabled />
              </Form.Item>
              <div className="password">
                <Form.Item
                  validateTrigger={['submit', 'onBlur']}
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please enter New Password'
                    },
                    {
                      min: 8,
                      message: 'Password must have a minimum length of 8'
                    },
                    {
                      pattern: new RegExp(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
                      ),
                      message:
                        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character(!@#$%^&*).'
                    }
                  ]}
                >
                  <Input
                    bordered={false}
                    type={password}
                    placeholder="New Password"
                    name="password"
                  />
                </Form.Item>
                <i onClick={Eye} className={`icon ${eye ? 'icon-eye-close' : 'icon-eye-open'}`}></i>
              </div>
              <div className="password">
                <Form.Item
                  name="password_confirmation"
                  dependencies={['password']}
                  validateTrigger={['submit', 'onBlur']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Confirm Password'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Password and confirm password does not match')
                        );
                      }
                    })
                  ]}
                >
                  <Input
                    bordered={false}
                    type={password1}
                    placeholder="Confirm Password"
                    name="c_password"
                  />
                </Form.Item>
                <i
                  onClick={Eye1}
                  className={`icon ${eye1 ? 'icon-eye-close' : 'icon-eye-open'}`}
                ></i>
              </div>
              <div className="form-group ant-form-item login-btn">
                <Button type="primary" htmlType="submit" shape="round" size="large">
                  Submit
                </Button>
              </div>
            </Form>
            <p>
              <Button
                type="button"
                onClick={() => navigate('/', { replace: true })}
                className="linkButton"
              >
                Back to Login
              </Button>
            </p>
            <Footer />
          </div>
          <div className="footer-link">
            <p style={{ fontSize: '12px', marginBottom: '2px' }}>
              Trouble Logging In?{' '}
              <a
                href="https://trusteasy.com.au/#contact"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Contact Us
              </a>
            </p>
            <p style={{ fontSize: '12px' }}>
              By selecting Login, you agree to our{' '}
              <a
                href="https://trusteasy.com.au/terms-conditions/"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Terms of Use
              </a>{' '}
              and have read and acknowledge our{' '}
              <a
                href="https://trusteasy.com.au/privacy-policy/"
                rel="noreferrer"
                style={{ fontSize: '12px', textDecoration: 'underline' }}
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
