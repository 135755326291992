/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import SidebarNav from '../../components/Header/SidebarNav';
import Footer from '../../components/Footer/Index';
import BackButton from '../../components/Form/BackButton';
import '../../App.css';
import '../../assets/css/style.css';
import StatementPage from './StatementPage';
import BankReconciliation from '../bank-reconciliation/BankReconciliation';
import ReconciledStatement from './ReconciledStatement';
import AuditMainPage from './AuditMainPage';
import moment from 'moment';
import DialogModal from '../../components/Header/DialogModal';
// import { helpCenterArray } from '../HelpCenter';
import { NegativeAmountConverter } from '../../helper/Conversions';
import MonthlyBankReconciliationReport from './MonthlyBankReconciliationReport';
import { useHelpCenter } from '../../shared/HelpCenterContext';

const tabsKey = {
  reconcile: 1,
  'reconciled-items': 2,
  'under-review': 3,
  statement: 4,
  'audit-statement': 5,
  'monthly-bank-reconciliation': 6
};

const FirmTrust = () => {
  const location = useLocation();
  const [balanceInfo, setBalanceInfo] = useState({});
  const navigate = useNavigate();
  const { getHelpCenterObjectByType } = useHelpCenter();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get('tab');
  const from = query.get('from');
  const to = query.get('to');
  const month = query.get('month');
  const today = Date.now();
  const defaultDate = moment(today).format('MM-YYYY');
  const defaultActiveKey = tabsKey[selectedTab];
  const [helpTextArray, setHelpTextArray] = useState(
    defaultActiveKey === 1
      ? getHelpCenterObjectByType('Reconcile Transaction')
      : defaultActiveKey === 2
      ? getHelpCenterObjectByType('Reconciled Items')
      : defaultActiveKey === 3
      ? getHelpCenterObjectByType('Under Review')
      : defaultActiveKey == 4
      ? getHelpCenterObjectByType('Bank Transaction')
      : defaultActiveKey == 6
      ? getHelpCenterObjectByType('Monthly Bank Reconciliation')
      : ''
  );

  const onTabClick = (activeKey) => {
    const tabName = Object.keys(tabsKey).find((key) => tabsKey[key] === parseInt(activeKey));
    if (tabName) {
      if (tabName === 'monthly-bank-reconciliation') {
        navigate(`/firm-bank-account?page=1&tab=${tabName}&month=${month ? month : defaultDate}`, {
          replace: true
        });
      } else {
        navigate(
          `/firm-bank-account?page=1&tab=${tabName}&from=${dateFilter?.start_date}&to=${dateFilter?.end_date}`,
          {
            replace: true
          }
        );
      }
    }
    if (tabName) {
      if (activeKey == 1) {
        setHelpTextArray(getHelpCenterObjectByType('Reconcile Transaction'));
      } else if (activeKey == 2) {
        setHelpTextArray(getHelpCenterObjectByType('Reconciled Items'));
      } else if (activeKey == 3) {
        setHelpTextArray(getHelpCenterObjectByType('Under Review'));
      } else if (activeKey == 4) {
        setHelpTextArray(getHelpCenterObjectByType('Bank Transaction'));
      } else if (activeKey == 5) {
        setHelpTextArray(getHelpCenterObjectByType('Audit Trail'));
      } else if (activeKey == 6) {
        setHelpTextArray(getHelpCenterObjectByType('Monthly Bank Reconciliation'));
      }
    }
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const [scroll, setScroll] = useState(false);
  const getDefaultDates = (from, to) => {
    if (from && to) {
      return { start_date: from, end_date: to };
    } else {
      const previousYear =
        new Date().getMonth() - 1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1;
      const previousYearDate = `01-04-${previousYear}`;
      const currentTime = new Date();
      const month = currentTime.getMonth() + 1;
      const day = currentTime.getDate();
      const year = currentTime.getFullYear();
      const currentDate = `${padWithLeadingZeros(Number(day), 2)}-${padWithLeadingZeros(
        Number(month),
        2
      )}-${year}`;
      return { start_date: previousYearDate, end_date: currentDate };
    }
  };
  const [dateFilter, setDateFilter] = useState(() => getDefaultDates(from, to));

  useEffect(() => {
    if (dateFilter && selectedTab) {
      if (selectedTab === 'monthly-bank-reconciliation') {
        navigate(
          `/firm-bank-account?page=1&tab=${selectedTab}&month=${month ? month : defaultDate}`,
          {
            replace: true
          }
        );
      } else {
        navigate(
          `/firm-bank-account?page=1&tab=${selectedTab}&from=${dateFilter?.start_date}&to=${dateFilter?.end_date}`,
          {
            replace: true
          }
        );
      }
    }
  }, [dateFilter]);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 96);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const firmTabItems = [
    {
      key: '1',
      label: 'Reconcile Transactions',
      children: (
        <BankReconciliation
          setBalanceInfo={setBalanceInfo}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )
    },
    {
      key: '2',
      label: 'Reconciled Items',
      children: (
        <ReconciledStatement
          setBalanceInfo={setBalanceInfo}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )
    },
    {
      key: '3',
      label: 'Under Review',
      children: (
        <BankReconciliation
          isUnderReviewed={true}
          setBalanceInfo={setBalanceInfo}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )
    },
    {
      key: '4',
      label: 'Bank Transactions',
      children: (
        <StatementPage
          setBalanceInfo={setBalanceInfo}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
        />
      )
    },
    {
      key: '5',
      label: 'Audit Trail',
      children: <AuditMainPage setBalanceInfo={setBalanceInfo} />
    },
    {
      key: '6',
      label: 'Monthly Bank Reconciliation',
      children: <MonthlyBankReconciliationReport setBalanceInfo={setBalanceInfo} />
    }
  ];

  return (
    <div className={scroll ? 'cms-page firm-bank-account scrolled' : 'cms-page firm-bank-account'}>
      <div className="page-content-block">
        <SidebarNav />
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Firm Bank Account{' '}
            <DialogModal title={helpTextArray[0]?.type} helpTextArray={helpTextArray} />
          </h1>

          <div
            className="content-details aba-tabs tab-div firm-trust"
            style={{ flexFlow: 'wrap', overflow: 'hidden' }}
          >
            {selectedTab == 'reconcile' ? (
              <>
                <div className="firm-details">
                  <div className="firm-summary">
                    {balanceInfo?.firm_bank && (
                      <div className="customer-name">
                        <h4 style={{ margin: 0 }}>
                          {balanceInfo?.firm_bank?.account_name}
                          <br />
                          {balanceInfo?.firm_bank?.bank_name}:{' '}
                          {balanceInfo?.firm_bank?.account_number}
                        </h4>
                      </div>
                    )}
                    <div className="reconciliation-block">
                      <div className="balance">
                        <h6>Statement Balance</h6>
                        <h5>
                          {balanceInfo?.statement_balance
                            ? NegativeAmountConverter(balanceInfo?.statement_balance)
                            : '$0.00'}
                        </h5>
                        <p style={{ margin: 0, fontSize: '12px', fontWeight: '700' }}>
                          Balance till date {moment(balanceInfo?.date).format('DD-MM-YYYY')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <Tabs
              defaultActiveKey={defaultActiveKey.toString()}
              size="large"
              style={{ marginBottom: 32 }}
              onTabClick={onTabClick}
              destroyInactiveTabPane={true}
              items={firmTabItems}
            ></Tabs>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default FirmTrust;
